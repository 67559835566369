const React = require('react');
const PropTypes = require('prop-types');
const Layout = require('../admin/layout');
const Pagination = require('./pagination');

const OffersIndex = ({
  offers,
  offersPath,
  newOfferPath,
  currentPage,
  totalPages,
  totalCount,
}) => {
  const offersRows = offers.map((offer) => {
    const {
      id,
      inquiry_id,
      artist_name,
      artist_service,
      when,
      where,
      gross_amount_basa,
      type,
      status_name,
      view_path,
      is_full_payment,
    } = offer;

    return (
      <tr key={offer.id} id={`offer${offer.id}`}>
        <td>{id}</td>
        <td>{inquiry_id}</td>
        <td>{artist_name}</td>
        <td>{artist_service}</td>
        <td>{when}</td>
        <td>{where}</td>
        <td>{gross_amount_basa}</td>
        <td>{type}</td>
        <td>{status_name}</td>
        <td>{is_full_payment ? 'Yes' : 'No'}</td>
        <td className="b-admin__actions">
          <a href={view_path}>See more</a>
        </td>
      </tr>
    );
  });

  return (
    <Layout>
      <h1>Offers dashboard</h1>
      <div className="b-admin__submenu">
        <a href={newOfferPath} className="b-button b-button--narrow">Create offer</a>
        <h5>{totalCount} offers</h5>
      </div>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>ID</th>
            <th>Inquiry ID</th>
            <th>Who</th>
            <th>What</th>
            <th>When</th>
            <th>Where</th>
            <th>Price (EUR)</th>
            <th>Type</th>
            <th>Status</th>
            <th>Full pymt?</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {offersRows}
        </tbody>
      </table>
      <Pagination
        rootPath={offersPath}
        currentPage={currentPage}
        pagesCount={totalPages}
      />
    </Layout>
  );
};

OffersIndex.propTypes = {
  offers: PropTypes.array.isRequired,
  offersPath: PropTypes.string.isRequired,
  newOfferPath: PropTypes.string.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
};

module.exports = OffersIndex;
