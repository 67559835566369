var React = require('react')
var PropTypes = require('prop-types')

class SoundcloudWidget extends React.Component {
  constructor(props) {
    super(props)
    this.handleRemoveAudio = this.handleRemoveAudio.bind(this)
  }

  handleRemoveAudio(event) {
    event.preventDefault();
    this.props.removeAudio();
  }

  render() {
    var embed = null
    if (this.props.soundcloudUrl) {
      embed = <iframe className="embed-reponsive-item" scrolling="no" frameBorder="no" src={"https://w.soundcloud.com/player/?url=" + this.props.soundcloudUrl + "&amp;color=ff6600&amp;auto_play=false&amp;show_artwork=true"}></iframe>
    }
    return (
      <div className="embed-responsive embed-responsive-16by9" style={{ paddingBottom: 165 }}>
        {!this.props.show && <a href="#" onClick={this.handleRemoveAudio} className="remove-video-icon" />}
        {embed}
      </div>
    );
  }
}

module.exports = SoundcloudWidget
