/* eslint-disable react/jsx-props-no-spreading */
const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');
const OfferShowField = require('../../offers/show_field');
const BookingInfo = require('../../offers/booking_info');
const BookingAmounts = require('../../offers/booking_amounts');
const BookingAmountsFull = require('../../offers/booking_amounts_full');
const CancellationPolicy = require('../../CancellationPolicy');

const Review = ({
  client,
  offer,
  inquiry,
  onNext,
  onBack,
}) => {
  const {
    company_name,
    cancellation_policy,
    cancellation_policy_option,
    is_full_payment,
  } = offer;

  return (
    <div className="container basa-offersReview">
      <div className="row basa-offersReview-infoReview">
        <div className="col-sm-offset-3 col-sm-6">
          <div>
            <h1 className="basa-offersReview-h1">
              {I18n.t('payment_process.review.title')}
            </h1>
          </div>
          <div>
            <BookingInfo {...offer} isVisual={inquiry['visual?']} />
            {is_full_payment === true ? (
              <BookingAmountsFull {...offer} />
            ) : (
              <BookingAmounts {...offer} />
            )}
          </div>
          <div style={{ marginTop: 30 }}>
            <CancellationPolicy
              content={cancellation_policy}
              option={cancellation_policy_option}
              pov="client"
              showNotes={!is_full_payment}
              amountDue={offer.gross_amount_basa}
            />
          </div>
        </div>
      </div>
      <div className="row basa-offersReview-personalDataReview">
        <div className="col-sm-offset-3 col-sm-6">
          <div>
            <h2 className="basa-offersReview-h2">{I18n.t('payment_process.review.personal_data')}</h2>
          </div>
          <div className="basa-offersReview-personalDataReview-fields">
            <OfferShowField
              fieldName={I18n.t('payment_process.review.client_full_name')}
              fieldValue={client.full_name}
              className="value-light"
            />
            <OfferShowField
              fieldName={I18n.t('payment_process.review.client_company_name')}
              fieldValue={company_name}
              className="value-light"
              isEnabled={!!company_name}
            />
            <OfferShowField
              fieldName={I18n.t('payment_process.review.client_email')}
              fieldValue={client.email}
              className="value-light"
            />
            <OfferShowField
              fieldName={I18n.t('payment_process.review.client_phone')}
              fieldValue={client.phone}
              className="value-light"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-offset-3 col-sm-6" style={{ paddingTop: 30, display: 'flex' }}>
          <button className="b-button b-button--inverse" onClick={onBack} type="button">
            {I18n.t('payment_process.review.back')}
          </button>
          <button className="b-button b-button--primary" onClick={onNext} type="button">
            {I18n.t('payment_process.review.next')}
          </button>
        </div>
      </div>
    </div>
  );
};

Review.propTypes = {
  client: PropTypes.object.isRequired,
  offer: PropTypes.object.isRequired,
  inquiry: PropTypes.object.isRequired,
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

module.exports = Review;
