import { Controller } from 'stimulus';

export default class extends Controller {
  static get targets() {
    return ['menuIcon'];
  }

  connect() {
    this.sentinel = document.querySelector('#navbar-sentinel');

    if (this.element && this.sentinel) {
      this.navbarObserver = new IntersectionObserver(
        (entries) => {
          if (!entries[0].isIntersecting) {
            this.element.classList.add('b2b__nav--enabled');
            if (this.hasMenuIconTarget) {
              this.menuIconTarget.classList.add('b2b__navbar-menu-icon--enabled');
            }
          } else {
            this.element.classList.remove('b2b__nav--enabled');
            if (this.hasMenuIconTarget) {
              this.menuIconTarget.classList.remove('b2b__navbar-menu-icon--enabled');
            }
          }
        },
      );
      this.navbarObserver.observe(this.sentinel);
    }
  }

  disconnect() {
    if (this.navbarObserver && this.sentinel) {
      this.navbarObserver.unobserve(this.sentinel);
    }
  }
}
