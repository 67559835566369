const React = require('react');

const Icon = ({ done, number, checkmark }) => {
  let classes = 'b-step__header__number'

  if (done) {
    classes += ' b-step__header__done'
  }

  return (
    <div className={classes}>{
      checkmark && done
        ? <i className="b-step__header__number__checkmark" />
        : <span>{number}</span>
    }
    </div>
  );
};

module.exports = Icon;
