const PropTypes = require('prop-types');

module.exports = PropTypes.shape({
  draftOffer: PropTypes.object.isRequired,
  offer: PropTypes.object,
  errors: PropTypes.object,
  error: PropTypes.string,
  client: PropTypes.object,
  computedPricing: PropTypes.object,
  isOfferSent: PropTypes.bool.isRequired,
  isFoldedByStep: PropTypes.object,
  isSending: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,

  messages: PropTypes.arrayOf(PropTypes.object),
  inquiry: PropTypes.object.isRequired,
  forwardedInquiry: PropTypes.object.isRequired,
  artisticService: PropTypes.object,

  calculatePricingPath: PropTypes.string.isRequired,
  messagingPath: PropTypes.string.isRequired,

  updateDraftOffer: PropTypes.func.isRequired,
  validateOffer: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  toggleFold: PropTypes.func.isRequired,
  unfoldChat: PropTypes.func.isRequired,
});
