const { resourcePath } = require('./BaseResource');

const basePath = '/tips_groups';

export function tipsGroupsPath(action, params) {
  return resourcePath(basePath, action, params);
}

function update(id, params) {
  return $.ajax({
    type: 'PUT',
    url: tipsGroupsPath('show', { id }),
    data: params,
  });
}

export function saveTipsGroup(id, params) {
  return update(id, params);
}
