const React = require('react');

class BookingTotalsField extends React.Component {
  renderSmallHint() {
    const {
      smallHint,
    } = this.props;

    if (!smallHint) return null;

    return <small>{smallHint}</small>;
  }

  render() {
    const {
      fieldName,
      className,
      fieldValue,
    } = this.props;

    return (
      <div className="row basa-offerField">
        <div className={`col-sm-5 basa-offerField-name ${className}`}>
          {fieldName}
        </div>
        <div className={`col-sm-7 basa-offerField-value ${className}`}>
          {fieldValue}
          {this.renderSmallHint()}
        </div>
      </div>
    );
  }
}

module.exports = BookingTotalsField;
