import { Controller } from 'stimulus';

require('slick-carousel');

export default class extends Controller {
  connect() {
    const breakpoint = 768;

    const sliderSettings = {
      mobileFirst: true,
      infinite: true,
      slidesToShow: 1,
      variableWidth: false,
      dots: true,
      arrows: false,
      responsive: [
        {
          breakpoint,
          settings: 'unslick',
        },
      ],
    };

    $(this.element).slick(sliderSettings);

    $(window).on('resize', () => {
      const slider = $(this.element);
      if ($(window).width() <= breakpoint && !slider.hasClass('slick-initialized')) {
        slider.slick(sliderSettings);
      }
    });
  }

  disconnect() {
    $(this.element).slick('unslick');
  }
}
