const React = require('react');
const PropTypes = require('prop-types');

const ProgressBar = ({ progress = 0 }) => {
  const percentage = `${progress}%`;

  return (
    <div className="b-progress-bar">
      <div className="b-progress-bar__total">
        <div
          className="b-progress-bar__completed"
          style={{ width: percentage }}
        />
        <div
          className="b-progress-bar__ball"
          style={{ left: percentage }}
        />
        <div
          className="b-progress-bar__percent"
          style={{ left: percentage }}
        >
          {percentage}
        </div>
      </div>
    </div>
  );
};

ProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
};

module.exports = ProgressBar;
