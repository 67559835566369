const React = require('react');

module.exports = React.createContext({
  form: {},
  billing_detail: {},
  client: {},
  offer: {},
  errors: {
    form: {},
    billing_detail: {},
    client: {},
  },
  updateFormState: () => {},
  updateBillingDetailState: () => {},
  updateClientState: () => {},
});
