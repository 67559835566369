const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');
const Header = require('./header');
const Tips = require('./tips');

class Step extends React.Component {
  stepClassName() {
    const { folded, blocked } = this.props;

    return classNames('b-step', {
      'b-step--blocked': blocked,
      'b-step--folded': folded,
    });
  }

  renderTips() {
    const { tips, faqPath } = this.props;

    if (!tips || !tips.length) return null;

    return (
      <Tips tips={tips} faqPath={faqPath} />
    );
  }

  renderSidebar() {
    const { renderSidebar } = this.props;
    return (
      <div className="b-step__sidebar">
        {renderSidebar()}
        {this.renderTips()}
      </div>
    );
  }

  render() {
    const {
      number,
      done,
      title,
      children,
      checkmark,
      onClickHeader,
      foldingLocator,
      dangerousTitle,
      headerTip,
    } = this.props;

    return (
      <div className={this.stepClassName()}>
        {this.renderSidebar()}
        <Header
          title={title}
          number={number}
          done={done}
          checkmark={checkmark}
          onClickHeader={onClickHeader}
          foldingLocator={foldingLocator}
          dangerousTitle={dangerousTitle}
          headerTip={headerTip}
        />
        <div className="b-step__body">{children}</div>
      </div>
    );
  }
}

Step.propTypes = {
  title: PropTypes.string.isRequired,
  onClickHeader: PropTypes.func,
  children: PropTypes.any,
  faqPath: PropTypes.string,
  renderSidebar: PropTypes.func,
  folded: PropTypes.bool,
  number: PropTypes.number,
  done: PropTypes.bool,
  checkmark: PropTypes.bool,
  foldingLocator: PropTypes.string,
  dangerousTitle: PropTypes.bool,
  headerTip: PropTypes.string,
  blocked: PropTypes.bool,
  tips: PropTypes.array,
};

Step.defaultProps = {
  renderSidebar: () => null,
  onClickHeader: () => null,
  folded: false,
  number: null,
  done: false,
  checkmark: false,
  foldingLocator: null,
  dangerousTitle: false,
  headerTip: null,
  blocked: false,
  tips: [],
  faqPath: null,
  children: undefined,
};

module.exports = Step;
