import { Controller } from 'stimulus';

const { dataLayer } = require('lib/analytics');

export default class extends Controller {
  static get targets() {
    return [];
  }

  fetchNewMessages = (channel) => new Promise(
    (resolve, reject) => {
      internet.get(
        `/channel_messages?channel=${channel}`,
      ).then((response) => {
        const {
          mode,
        } = response.data;
        const messages = JSON.parse(response.data.messages);

        resolve({
          mode,
          messages,
        });
      }).catch((err) => {
        reject(err);
      });
    },
  );

  getExperimentVariant = () => new Promise((resolve) => {
    dataLayer.push({ event: this.experimentTriggerName });

    this.intervalRunTimes = 0;
    this.intervalId = setInterval(() => {
      this.intervalRunTimes += 1;
      if (window.google_optimize !== undefined) {
        const variant = window.google_optimize.get(this.experimentId);
        clearInterval(this.intervalId);
        resolve(variant);
      } else if (this.intervalRunTimes >= this.maxRetries) {
        clearInterval(this.intervalId);
      }
    }, 100);
  });

  connect() {
    this.aTargetsQuery = this.data.get('aTargetsQuery');
    this.bTargetsQuery = this.data.get('bTargetsQuery');
    this.experimentTriggerName = this.data.get('experimentTriggerName');
    this.experimentId = this.data.get('experimentId');
    this.maxRetries = parseInt(this.data.get('maxRetries') || '50', 10);

    if (this.experimentId && this.aTargetsQuery && this.bTargetsQuery) {
      this.getExperimentVariant()
        .then((variant) => {
          if (variant !== '1') {
            // A test, hide B test elements
            document.querySelectorAll(this.bTargetsQuery).forEach((target) => {
              // eslint-disable-next-line no-param-reassign
              target.style.display = 'none';
            });
          } else {
            // B test, hide A test elements, show B test elements
            document.querySelectorAll(this.aTargetsQuery).forEach((target) => {
              // eslint-disable-next-line no-param-reassign
              target.style.display = 'none';
            });

            document.querySelectorAll(this.bTargetsQuery).forEach((target) => {
              // eslint-disable-next-line no-param-reassign
              target.style.display = 'flex';
            });

            const slideoutContentB = document.querySelector('.slideout-content-b').cloneNode(true);
            slideoutContentB.style.display = 'block';
            document.querySelector('#menu-content').innerHTML = '';
            document.querySelector('#menu-content').appendChild(slideoutContentB);
          }
        });
    }
  }

  disconnect() {
  }
}
