const React = require('react');
const PropTypes = require('prop-types');

const fancyboxOptions = JSON.stringify({ buttons: ['close', 'slideShow', 'fullScreen'] });

function GalleryWidget({ alt, imagesIds }) {
  const images = imagesIds.map(picture => (
    <div key={picture.id} className="col-sm-3 col-xs-6 col-margin">
      <a className="square-image" href={picture.original_url} data-fancybox="gallery" data-options={fancyboxOptions}>
        <img src={picture.thumbnail_url} className="img-responsive" alt={alt} />
      </a>
    </div>
  ));

  return (
    <div className="gallery-widget">
      {images}
    </div>
  );
}

GalleryWidget.propTypes = {
  alt: PropTypes.string.isRequired,
  imagesIds: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    original_url: PropTypes.string.isRequired,
    thumbnail_url: PropTypes.string.isRequired,
  })).isRequired,
};

module.exports = GalleryWidget;
