function baseURL(id) {
  const base = '/invitation_requests';

  return `${base}/${id}/admin_response`;
}

// eslint-disable-next-line import/prefer-default-export
export function saveAdminResponse(id, params) {
  return $.ajax({
    type: 'POST',
    url: baseURL(id),
    data: params,
  });
}
