const React = require('react');
const PropTypes = require('prop-types');
const Select = require('../../components/ui/Select');

const mapValues = array => array.map(({ value }) => value);

const Filters = ({
  occasions,
  categories,
  filteredMasterTags,
  onOccasionChange,
  onCategoryChange,
  onMasterTagChange,
  currentOccasions,
  currentCategory,
  currentMasterTag,
  onClear,
}) => (
  <div className="b-search-page__filters">
    <Select
      options={categories}
      value={currentCategory}
      placeholder={I18n.t('search.filters.category_label')}
      className="b-select b-select--rounded b-category-filter"
      onChange={({ value }) => onCategoryChange(value)}
    />
    {currentCategory && currentCategory.value && currentCategory.value !== 'all' &&
      <Select
        options={filteredMasterTags}
        value={currentMasterTag}
        placeholder={I18n.t('search.filters.sub_category_label')}
        className="b-select b-select--rounded b-master-tag-filter"
        onChange={({ value }) => onMasterTagChange(value)}
      />
    }
    <Select
      options={occasions}
      value={currentOccasions}
      placeholder={I18n.t('search.filters.occasion_label')}
      className="b-select b-select--rounded b-occasions-filter"
      onChange={values => onOccasionChange(mapValues(values))}
      closeMenuOnSelect={false}
      isMulti
    />
    <button
      className="b-search-page__filters__clear-button"
      onClick={onClear}
    >
      <i className="glyphicon glyphicon-remove-circle" />
      Clear filters
    </button>
  </div>
);

Filters.propTypes = {
  occasions: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  filteredMasterTags: PropTypes.array.isRequired,
  onOccasionChange: PropTypes.func.isRequired,
  onCategoryChange: PropTypes.func.isRequired,
  onMasterTagChange: PropTypes.func.isRequired,
  currentOccasions: PropTypes.array.isRequired,
  currentCategory: PropTypes.object.isRequired,
  currentMasterTag: PropTypes.any.isRequired,
  onClear: PropTypes.func.isRequired,
};

module.exports = Filters;
