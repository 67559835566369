/* eslint-disable react/jsx-fragments */
const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');

const InputField = ({
  value,
  onChange,
  error,
  type,
  id,
  placeholder,
  name,
  disabled,
}) => (
  <React.Fragment>
    <input
      className={classNames('b-artist-pricing__input', { 'b-artist-pricing__input--error': error })}
      value={value.toString()}
      onChange={onChange}
      type={type}
      id={id}
      placeholder={placeholder}
      name={name}
      disabled={disabled}
    />
    <div className="b-artist-pricing__error">{error}</div>
  </React.Fragment>
);

InputField.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  error: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
};

InputField.defaultProps = {
  error: undefined,
  type: undefined,
  name: undefined,
  disabled: false,
};

module.exports = InputField;
