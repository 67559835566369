const React = require('react');
const PropTypes = require('prop-types');

const fancyboxOptions = JSON.stringify({ buttons: ['close', 'slideShow', 'fullScreen'] });

function Gallery({ alt, pictures }) {
  const images = pictures.map(picture => (
    <a
      key={picture.id}
      className="b-service__gallery-section__picture"
      href={picture.original_url}
      data-fancybox="gallery"
      data-options={fancyboxOptions}
    >
      <img src={picture.thumbnail_url} alt={alt} />
    </a>
  ));

  return (
    <div className="b-service__gallery-section">
      {images}
    </div>
  );
}

Gallery.propTypes = {
  alt: PropTypes.string.isRequired,
  pictures: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    original_url: PropTypes.string.isRequired,
    thumbnail_url: PropTypes.string.isRequired,
  })).isRequired,
};

module.exports = Gallery;
