const React = require('react');
const PropTypes = require('prop-types');

const SmartForm = React.lazy(() => import(/* webpackChunkName: "lc-smart-inquiry" */ '../../lazy_components/smart_inquiry'));
const ModalLoader = require('./ModalLoader');

class Loader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
    };
  }

  componentDidMount() {
    document.addEventListener('loadSmartInquiry', this.loadAndRedirect);
  }

  loadAndRedirect = (event) => {
    const { loaded } = this.state;

    if (loaded) {
      event.target.dispatchEvent(new CustomEvent('openSmartInquiry', { bubbles: true }));
    } else {
      this.setState({
        loaded: true,
        initialTarget: event.target,
      });
    }
  }

  render() {
    const { loaded, initialTarget } = this.state;

    if (loaded) {
      return (
        <React.Suspense fallback={<ModalLoader />}>
          <SmartForm
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...this.props}
            initialTarget={initialTarget}
          />
        </React.Suspense>
      );
    }

    return (
      <div />
    );
  }
}

Loader.propTypes = {
  initialTarget: PropTypes.any,
};

Loader.defaultProps = {
  initialTarget: null,
};

module.exports = Loader;
