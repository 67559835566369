const React = require('react');
const PropTypes = require('prop-types');
const Actions = require('./actions');
const Layout = require('../../admin/layout');
const BookingInfo = require('../booking_info');
const BookingAmountsAdmin = require('./booking_amounts_admin');
const OfferShowField = require('../show_field');
const ClientInfo = require('../client_info');

const localeNames = {
  en: 'English',
  de: 'Deutsch',
  pt: 'Portuguese',
};

class OffersShow extends React.Component {
  title() {
    if (this.props.offer.status === 'created') {
      return 'This is the information you are about to send to the client. Please review it carefully before sending.';
    }

    return null;
  }

  render() {
    const {
      offer,
      extraCosts,
      client,
      booking_confirmation_id,
      booking_confirmation,
      invoices,
      client_path,
      payment_url,
      confirm_offer_path,
      send_offer_path,
      edit_path,
      send_confirmation_to_client_path,
      send_confirmation_to_artist_path,
      artistic_profile_url,
      artistic_service_url,
    } = this.props;

    const {
      id,
      cancellation_policy,
      status_name,
      company_name,
      locale,
      artist_name,
      artist_service,
      when,
      where,
      specific,
      show_beginning,
      show_duration,
      surface_size,
      isVisual,
    } = offer;

    return (
      <Layout>
        <h1>
          Client Offer
          <small>{` (ID#${id})`}</small>
          <small>{` (${status_name})`}</small>
        </h1>
        <div className="b-admin__submenu">
          {this.title()}
        </div>
        <div>
          <div className="basa-offersShow-info1">
            <OfferShowField
              className="value-light"
              fieldName="Language"
              fieldValue={locale ? localeNames[locale] : 'Unknown'}
            />
          </div>
          <BookingInfo
            artist_name={artist_name}
            artist_service={artist_service}
            when={when}
            where={where}
            specific={specific}
            show_beginning={show_beginning}
            show_duration={show_duration}
            surface_size={surface_size}
            isVisual={isVisual}
            artistic_profile_url={artistic_profile_url}
            artistic_service_url={artistic_service_url}
          />
          <BookingAmountsAdmin offer={offer} extraCosts={extraCosts} />
          <ClientInfo
            id={client.id}
            email={client.email}
            name={client.full_name}
            phone={client.phone}
            companyName={company_name}
            client_path={client_path}
          />
          <div className="basa-offersShow-info1">
            <OfferShowField
              className="value-light"
              fieldName={I18n.t('offer.cancellation_policy')}
            >
              <span dangerouslySetInnerHTML={{ __html: cancellation_policy }} />
            </OfferShowField>
          </div>
          <Actions
            offer={offer}
            client={client}
            booking_confirmation_id={booking_confirmation_id}
            booking_confirmation={booking_confirmation}
            invoices={invoices}
            payment_url={payment_url}
            confirm_offer_path={confirm_offer_path}
            send_offer_path={send_offer_path}
            edit_path={edit_path}
            send_confirmation_to_client_path={send_confirmation_to_client_path}
            send_confirmation_to_artist_path={send_confirmation_to_artist_path}
          />
        </div>
      </Layout>
    );
  }
}

OffersShow.propTypes = {
  artistic_profile_url: PropTypes.string,
  artistic_service_url: PropTypes.string,
  send_confirmation_to_client_path: PropTypes.string.isRequired,
  send_confirmation_to_artist_path: PropTypes.string.isRequired,
  offer: PropTypes.object.isRequired,
};

OffersShow.defaultProps = {
  artistic_profile_url: null,
  artistic_service_url: null,
};

module.exports = OffersShow;
