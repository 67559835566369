const React = require('react');
const Menu = require('./menu');

const Layout = (props) => {
  return (
    <div className="container b-admin">
      <div className="row">
        <div className="col-sm-10 col-sm-offset-1"><Menu /></div>
      </div>
      <div className="row">
        <div className="col-sm-10 col-sm-offset-1">
          {props.children}
        </div>
      </div>
    </div>
  );
}

module.exports = Layout;