const React = require('react');
const PropTypes = require('prop-types');

function TextAreaField({
  name,
  fieldName,
  fieldValue,
  placeholder,
  onChange,
  fieldError,
  maxLength,
}) {
  const hasError = fieldError && fieldError.length > 0;

  return (
    <div className="row b-text-area-field__container">
      <div className="col-xs-12">
        <div className="b-text-area-field__name">
          {fieldName}
        </div>
      </div>
      <div className="col-xs-12 b-text-area-field__value-container">
        <textarea
          name={name}
          placeholder={placeholder}
          className={`b-text-area-field__value ${hasError ? 'b-text-area-field__value--error' : ''}`}
          onChange={onChange}
          value={fieldValue || ''}
          maxLength={maxLength}
        />
      </div>
      <div className="col-xs-12 b-text-area-field__error">
        {hasError && fieldError[0]}
      </div>
    </div>
  );
}

TextAreaField.propTypes = {
  name: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  fieldValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  fieldError: PropTypes.array,
  maxLength: PropTypes.number,
};

TextAreaField.defaultProps = {
  maxLength: undefined,
  fieldError: [],
  fieldValue: '',
};

module.exports = TextAreaField;
