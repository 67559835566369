function baseURL(id) {
  const base = '/extra_costs';

  if (id) {
    return `${base}/${id}`;
  }

  return base;
}

function create(params) {
  return $.ajax({
    type: 'POST',
    url: baseURL(),
    data: params,
  });
}

function update(id, params) {
  return $.ajax({
    type: 'PUT',
    url: baseURL(id),
    data: params,
  });
}

export function saveExtraCost(id, params) {
  if (id) {
    return update(id, params);
  }

  return create(params);
}

export function deleteExtraCost(id, params = {}) {
  return $.ajax({
    type: 'DELETE',
    url: baseURL(id),
    data: params,
  });
}
