const React = require('react');
const PropTypes = require('prop-types');
const Select = require('../Select');

const hours = Array(24).fill(0).map((_v, i) => (
  { value: i, label: i }
));

const minutes = ['00', '15', '30', '45'].map(v => (
  { value: v, label: v }
));

const emptyValue = { value: '-', label: '-' };

const TimeField = ({
  onChange,
  selectedHours,
  selectedMinutes,
  allowEmpty,
  reactModal,
}) => {
  const hoursOptions = allowEmpty ? [emptyValue].concat(hours) : hours;
  const minutesOptions = allowEmpty ? [emptyValue].concat(minutes) : minutes;

  return (
    <div className="b-time-field">
      <Select
        value={{ value: selectedHours, label: selectedHours }}
        options={hoursOptions}
        className="b-time-field__select b-select"
        onChange={({ value }) => onChange([value, selectedMinutes])}
        isClearable={false}
        isSearchable={false}
        isRtl={false}
        reactModal={reactModal}
      />
      <span className="b-time-field__separator">:</span>
      <Select
        value={{ value: selectedMinutes, label: selectedMinutes }}
        options={minutesOptions}
        className="b-time-field__select b-select"
        onChange={({ value }) => onChange([selectedHours, value])}
        isClearable={false}
        isSearchable={false}
        isRtl={false}
        reactModal={reactModal}
      />
    </div>
  );
};

TimeField.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedHours: PropTypes.string,
  selectedMinutes: PropTypes.string,
  allowEmpty: PropTypes.bool,
};

TimeField.defaultProps = {
  selectedHours: undefined,
  selectedMinutes: undefined,
  allowEmpty: false,
};

module.exports = TimeField;
