const React = require("react");
const PropTypes = require("prop-types");
const ReactModal = require("react-modal");

class Modal extends React.Component {
  componentWillMount() {
    ReactModal.setAppElement("body");
  }

  render() {
    const {
      isOpen,
      onClose,
      children,
      extraClass,
      overlayExtraClass,
      showCloseButton,
    } = this.props;

    return (
      <ReactModal
        isOpen={isOpen}
        onRequestClose={onClose}
        closeTimeoutMS={10}
        contentLabel="Modal"
        className={{
          base: `b-react-modal ${extraClass}`,
          afterOpen: "b-react-modal--open",
          beforeClose: "b-react-modal--before-close",
        }}
        overlayClassName={{
          base: `b-react-modal__overlay ${overlayExtraClass}`,
          afterOpen: "b-react-modal__overlay--open",
          beforeClose: "b-react-modal__overlay--before-close",
        }}
      >
        {showCloseButton && (
          <button
            className="b-react-modal__close-button"
            type="button"
            onClick={onClose}
          >
            x
          </button>
        )}
        <div className="b-react-modal__content">{children}</div>
      </ReactModal>
    );
  }
}

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  showCloseButton: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
    .isRequired,
  extraClass: PropTypes.string,
  overlayExtraClass: PropTypes.string,
};

Modal.defaultProps = {
  extraClass: "",
  overlayExtraClass: "",
  onClose: null,
  showCloseButton: true,
};

module.exports = Modal;
