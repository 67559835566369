const React = require('react');

const withFoldableStep = step => Component => class WrappedComponent extends React.Component {
    onClickFold = () => this.props.context.toggleFold(step)

    render() {
      return (<Component {...{
        ...this.props,
        isFolded: this.props.context.isFoldedByStep[step],
        onClickFold: this.onClickFold,
      }}
      />);
    }
};

module.exports = withFoldableStep;
