const React = require('react');
const PropTypes = require('prop-types');
const HeaderImage = require('../layout/header');
const Heading = require('../ui/Heading');
const Layout = require('../ui/Layout');
const ShareLink = require('./ShareLink');
const { HUNTERS_EMAIL_SUPPORT } = require('../../domain/hunter');

const HuntersNew = ({ referralLink, faqPath }) => (
  <div>
    <HeaderImage />
    <Layout.Container padding={5}>
      <div className="row">
        <div className="col-sm-8 col-sm-offset-2">
          <Heading text={I18n.t('hunters.new.title')} />
          <div style={{ padding: '30px 0 0' }}>
            <Layout.List>
              <p>{I18n.t('hunters.new.how_to_grow')}</p>
              <p>{I18n.t('hunters.new.by_referring')}</p>
              <ul>
                <li>{I18n.t('hunters.new.by_referring_item_1')}</li>
                <li>{I18n.t('hunters.new.by_referring_item_2')}</li>
                <li>{I18n.t('hunters.new.by_referring_item_3')}</li>
                <li>{I18n.t('hunters.new.by_referring_item_4')}</li>
                <li>{I18n.t('hunters.new.by_referring_item_5')}</li>
              </ul>
              <p dangerouslySetInnerHTML={{ __html: I18n.t('hunters.new.we_are_looking_html', { faq_path: faqPath }) }} />
              <p>{I18n.t('hunters.new.to_invite_fellows')}</p>
            </Layout.List>
            <ShareLink link={referralLink} />
            <p style={{ padding: '20px 0 0' }}>{I18n.t('hunters.new.any_doubts', { email: HUNTERS_EMAIL_SUPPORT })}</p>
          </div>
        </div>
      </div>
    </Layout.Container>
  </div>
);

HuntersNew.propTypes = {
  referralLink: PropTypes.string.isRequired,
  faqPath: PropTypes.string.isRequired,
};

module.exports = HuntersNew;
