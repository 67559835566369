/* eslint-disable react/jsx-fragments */
const React = require('react');
const { ampEvent } = require('lib/amplitude');
const ArtisticServiceItemContainer = require('./containers/artistic_service_item_container');
const pricingData = require('../pricing_modal/pricing.json');

const hasPricingData = (category, masterTag, subtype) => {
  let line = pricingData.filter((option) => option.category === category)
    .filter((option) => option.master_tag === masterTag)
  if (subtype) {
    line = line.filter((option) => option.subtype === subtype);
  }
  if (line) {
    line = line[0];
  }

  return Boolean(line);
};

class ArtisticServiceItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isExpanded: false,
      variant: null,
    };

    this.onExpandChange = this.onExpandChange.bind(this);
  }

  componentDidMount() {
    if (this.props.experimentId) {
      if (window.dataLayer) {
        window.dataLayer.push({ event: 'optimize.activateServicePricing' });
      }
      this.intervalId = setInterval(() => {
        if (window.google_optimize !== undefined) {
          const variant = window.google_optimize.get(this.props.experimentId);
          this.setState({ variant });
          clearInterval(this.intervalId);
        }
      }, 100);
    }
  }

  onExpandChange(isExpanded) {
    this.setState({
      isExpanded,
    });
  }

  render() {
    const {
      slug,
      id,
      profile_picture,
      category,
      master_tag,
      artistic_name,
      subtype,
      country_code,
    } = this.props;
    const anchor = `#${slug}`;
    const {
      isExpanded,
      variant,
    } = this.state;

    return (
      <ArtisticServiceItemContainer
        onExpandChange={this.onExpandChange}
        anchor={anchor}
        {...this.props}
      >
        { variant === '1' && hasPricingData(category, master_tag, subtype) ? (
          <React.Fragment>
            <button
              onClick={(e) => {
                e.preventDefault();
                hj('trigger', 'get_price_info');
                hj('tagRecording', ['get_price_info_click']);
                ampEvent('service_cta: click', {
                  type: 'pricing',
                  serviceId: id,
                  category,
                  master_tag,
                  subtype,
                  artistic_name,
                });
                e.target.dispatchEvent(new CustomEvent('openPricingModal', { bubbles: true }));
              }}
              type="button"
              className={`service-cta b-button ${isExpanded ? 'b-button--primary' : 'b-button--inverse b-button--transparent'}`}
              data-anchor={anchor}
              data-artistic-service-id={id}
              data-category={category}
              data-master-tag={master_tag}
              data-subtype={subtype}
              data-logo-url={profile_picture.src}
              data-artist-name={artistic_name}
              data-country-code={country_code}
            >
              { I18n.t('artistic_service_item.get_pricing') }
            </button>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <a
              href={anchor}
              className={`service-cta b-button b-button--big-screen inquiry-form-button ${isExpanded ? 'b-button--primary' : 'b-button--inverse b-button--transparent'}`}
              data-artistic-service-id={id}
              data-category={category}
              data-master-tag={master_tag}
              data-subtype={subtype}
              data-logo-url={profile_picture.src}
              data-artist-name={artistic_name}
              onClick={(e) => {
                hj('tagRecording', ['get_a_free_quote_click']);
                ampEvent('service_cta: click', {
                  type: 'direct',
                  serviceId: id,
                  category,
                  master_tag,
                  subtype,
                  artistic_name,
                });
              }}
            >
              { I18n.t('artistic_service_item.get_free_quote') }
            </a>
            <a
              href={anchor}
              className={`service-cta b-button b-button--small-screen inquiry-form-button ${isExpanded ? 'b-button--primary' : 'b-button--inverse b-button--transparent'}`}
              data-artistic-service-id={id}
              data-category={category}
              data-master-tag={master_tag}
              data-subtype={subtype}
              data-logo-url={profile_picture.src}
              data-artist-name={artistic_name}
              onClick={(e) => {
                hj('tagRecording', ['get_a_free_quote_click']);
                ampEvent('service_cta: click', {
                  type: 'direct',
                  serviceId: id,
                  category,
                  master_tag,
                  subtype,
                  artistic_name,
                });
              }}
            >
              { I18n.t('artistic_service_item.free_quote') }
            </a>
          </React.Fragment>
        )}
      </ArtisticServiceItemContainer>
    );
  }
}

module.exports = ArtisticServiceItem;
