const React = require('react');
const PropTypes = require('prop-types');

const PADDING_UNIT = 10;

const Container = ({ children, padding }) => (
  <div
    className="container"
    style={{
      paddingTop: padding * PADDING_UNIT,
      paddingBottom: padding * PADDING_UNIT,
    }}
  >
    {children}
  </div>
);

Container.propTypes = {
  padding: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

Container.defaultProps = {
  padding: 1,
};

module.exports = Container;
