const React = require('react');
const classNames = require('classnames');
const Modal = require('../../components/ui/Modal');
const Select = require('../ui/Select');

const getRecommendationId = () => {
  return document.getElementById('more-recommendations-data').dataset.recommendationId;
};

const successNotification = () => {
  document.getElementById('recommend-me-more-input-area').classList.add('b-recommend-me-more-modal--hidden');
  const successMessage = document.createElement('p');
  successMessage.innerHTML = I18n.t('recommend_me_more.success_message');
  successMessage.className = 'b-recommend-me-more-modal__success-message';
  document.querySelectorAll('form.b-recommend-me-more-modal__form')[0].appendChild(successMessage);
};
class RecommendMeMoreModal extends React.Component {
  constructor(props) {
    super(props);

    this.onOpen = this.onOpen.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      isOpen: false,
      reason: '',
    };
  }

  componentDidMount() {
    document.addEventListener('openRecommendMeMoreModal', this.onOpen);
  }

  onOpen() {
    this.setState({
      isOpen: true,
    });
  }

  handleChange(event) {
    this.setState({
      reason: event.label.props.label,
    });
  }

  handleSubmit() {
    const { reason } = this.state;

    $.ajax({
      type: 'POST',
      url: '/more_recommendations',
      data: {
        recommendation_id: getRecommendationId(),
        reason,
      },
      success: () => { successNotification(); },
      error: () => { },
    });
  }

  render() {
    const { isOpen } = this.state;
    const reason_options = [
      { value: 1, label: I18n.t('recommend_me_more.option_1') },
      { value: 2, label: I18n.t('recommend_me_more.option_2') },
      { value: 3, label: I18n.t('recommend_me_more.option_3') },
      { value: 4, label: I18n.t('recommend_me_more.option_4') },
      { value: 5, label: I18n.t('recommend_me_more.option_5') },
      { value: 6, label: I18n.t('recommend_me_more.other') },
    ];

    return (
      <Modal
        onClose={() => this.setState({ isOpen: false })}
        isOpen={isOpen}
        extraClass="b-inquiry-modal"
        overlayExtraClass="b-inquiry-modal-overlay"
      >
        <form
          onSubmit={(e) => { e.preventDefault(); this.handleSubmit(); }}
          className="b-recommend-me-more-modal__form"
        >
          <h1 className="b-recommend-me-more-modal__title">
            {I18n.t('recommend_me_more.title')}
          </h1>

          <span className="b-recommend-me-more-modal__svg" />
          <h4 className="b-recommend-me-more-modal__more-recommendations">
            {I18n.t('recommend_me_more.more_recommendations')}
          </h4>
          <span className="b-recommend-me-more-modal__divider" />
          <div id="recommend-me-more-input-area">
            <p className="b-recommend-me-more-modal__description">{I18n.t('recommend_me_more.description')}</p>
            <div className="b-input__label b-recommend-me-more-modal__input-label">
              {I18n.t('recommend_me_more.label')}
            </div>
            <div className="b-recommend-me-more-modal__select-container">
              <Select
                placeholder={I18n.t('recommend_me_more.placeholder')}
                options={reason_options}
                id="recommend-me-more-select"
                onChange={this.handleChange}
              />
            </div>

            <div className="b-react-modal__footer b-recommend-me-more-modal__footer">

              <button
                className="b-button b-button--inverse b-button--transparent
                           qa-smart-inquiry-back b-recommend-me-more-modal__close-btn"
                type="button"
                onClick={() => this.setState({ isOpen: false })}
              >
                {I18n.t('recommend_me_more.close')}
              </button>

              <input
                className={classNames('b-button', 'qa-smart-inquiry-next b-recommend-me-more-modal__submit-btn')}
                style={{ marginLeft: 'auto', order: '2' }}
                type="submit"
                value={I18n.t('smart_form.shared.submit')}
              />
            </div>
          </div>

        </form>


      </Modal>
    );
  }
}

module.exports = RecommendMeMoreModal;
