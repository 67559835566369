const NUMBER_WITH_TWO_DECIMALS_REGEX = /\d+\.?(\d{1,2})?/;

// eslint-disable-next-line import/prefer-default-export
export const sanitizeNumericalInput = (value) => {
  const newValue = value.replace(',', '.');

  const matches = newValue.match(NUMBER_WITH_TWO_DECIMALS_REGEX);

  if (matches) {
    return matches[0];
  }

  return '';
};
