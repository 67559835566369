var React = require('react')
var PropTypes = require('prop-types')
var ArtisticServiceWidget = require('./artistic_service_widget')
var ServiceTagsInput = require('./service_tags_input')

class ArtisticServiceWidgets extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      widgets: [],
      selectedWidget: 'GallerySection',
      artisticService: this.props.artistic_service,
      errors: {},
      adminMode: this.props.admin_mode
    }

    this.handleSelectedWidget = this.handleSelectedWidget.bind(this)
    this.handleClickAddWidget = this.handleClickAddWidget.bind(this)
    this.handleSaveButton = this.handleSaveButton.bind(this)
    this.handleUpdateWidget = this.handleUpdateWidget.bind(this)
    this.save = this.save.bind(this)
    this.saveAndPreviewSuccess = this.saveAndPreviewSuccess.bind(this)
    this.saveSuccess = this.saveSuccess.bind(this)
    this.saveError = this.saveError.bind(this)
    this.handleBackClick = this.handleBackClick.bind(this)
    this.validState = this.validState.bind(this)
    this.handlePressEnter = this.handlePressEnter.bind(this)
    this.handleTagChange = this.handleTagChange.bind(this)
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this)
  }

  handleSelectedWidget(event) {
    var newState = Object.assign({}, this.state, {
      selectedWidget: event.target.value
    })
    this.setState(newState)
  }

  handleClickAddWidget() {
    var copySections = this.state.artisticService.sections.slice()
    var newArtisticService = Object.assign({}, this.state.artisticService, { sections: copySections })
    var newSection = { type: this.state.selectedWidget }
    if (this.state.selectedWidget == "YoutubeSection") {
      newSection["video_urls"] = "";
    } else if (this.state.selectedWidget == "AudioSection") {
      newSection["audio_urls"] = "";
    }
    copySections.push(newSection)
    var newState = Object.assign({}, this.state, {
      artisticService: newArtisticService
    })
    this.setState(newState)
  }

  handleUpdateWidget(index, updateKeyValue) {
    var copySections = this.state.artisticService.sections.slice()
    var updatedSection = Object.assign({}, copySections[index], updateKeyValue)
    copySections[index] = updatedSection
    var newArtisticService = Object.assign({}, this.state.artisticService, { sections: copySections })
    var newState = Object.assign({}, this.state, { artisticService: newArtisticService })
    this.setState(newState)
  }

  handleSaveButton(saveSuccessCallback, saveErrorCallback) {
    var serviceName = this.refs.artistic_service_name.value;
    var serviceDescription = this.refs.artistic_service_description.value;
    var newArtisticService = Object.assign(
      {},
      this.state.artisticService,
      {
        original_name: serviceName,
        original_description: serviceDescription,
        name: null,
        description: null
      }
    )

    var newState = Object.assign({}, this.state, {
      artisticService: newArtisticService
    })

    this.setState(newState, () => {
      if (this.validState()) {
        this.save(saveSuccessCallback, saveErrorCallback)
      }
    });
  }

  handleSaveForLaterButton(saveSuccessCallback, saveErrorCallback) {
    var serviceName = this.refs.artistic_service_name.value;
    var serviceDescription = this.refs.artistic_service_description.value;
    var newArtisticService = Object.assign(
      {},
      this.state.artisticService,
      {
        original_name: (serviceName || "Draft"),
        original_description: (serviceDescription || 'Draft'),
        name: null,
        description: null
      }
    )

    var newState = Object.assign(
      {},
      this.state,
      {
        artisticService: newArtisticService
      }
    )

    this.setState(newState, () => {
      this.save(saveSuccessCallback, saveErrorCallback)
    });
  }

  handlePressEnter(e) {
    e.preventDefault();
    this.handleSaveButton();
  }

  validState() {
    var newErrors = {}
    var name = this.state.artisticService.original_name
    if (name.length == 0) {
      newErrors.name = I18n.t('artistic_services.errors.blank')
    }
    if (name.length > 50) {
      newErrors.name = I18n.t('artistic_services.errors.more_50')
    }
    var description = this.state.artisticService.original_description
    if (description.length == 0) {
      newErrors.description = I18n.t('artistic_services.errors.blank')
    }
    if (description.length > 500) {
      newErrors.description = I18n.t('artistic_services.errors.more_500')
    }
    var pictures = this.state.artisticService.sections[0].pictures
    if (pictures.length % 4 !== 0 || pictures.length > 12 || pictures.length == 0) {
      newErrors.pictures = I18n.t('artistic_services.errors.photos')
    }
    var newState = Object.assign({}, this.state, {
      errors: newErrors
    })
    if (Object.keys(newErrors).length > 0) {
      this.setState(newState, this.scrollToFirstError);
    }
    return Object.keys(newErrors).length == 0
  }

  save(successCallback, errorCallback) {
    var copySections = this.state.artisticService.sections.slice()
    copySections.forEach((section) => {
      section.pictures_attributes = section.pictures
    })

    var artisticServiceData = Object.assign({}, this.state.artisticService,
      {
        sections_attributes: copySections,
        user_keywords: this.state.artisticService.user_tags.map(
          function (tag) { return tag.name; }
        ).join(),
        admin_keywords: this.state.artisticService.admin_tags.map(
          function (tag) { return tag.name; }
        ).join()
      }
    );
    var url = `/${this.state.artisticService.artistic_profile_id}`
    var typeRequest = "POST"
    if (this.state.artisticService.id) {
      var url = `/${this.state.artisticService.artistic_profile_id}/${this.state.artisticService.id}`
      var typeRequest = "PUT"
    }
    var locale = I18n.locale != "en" ? `/${I18n.locale}` : ""
    url = locale + url
    $.ajax({
      url: url,
      type: typeRequest,
      data: { "artistic_service": artisticServiceData },
      success: successCallback,
      error: errorCallback
    })
  }

  saveAndPreviewSuccess(response) {
    var newState = Object.assign({}, this.state, {
      name: response.name,
      description: response.description
    })
    this.setState(newState)
    var locale = I18n.locale != "en" ? `/${I18n.locale}` : ""
    window.location = `${locale}/${this.state.artisticService.artistic_profile_id}/${response.id}/preview`;
  }

  saveSuccess(response) {
    var newState = Object.assign({}, this.state, {
      name: response.name,
      description: response.description
    })
    this.setState(newState)
    var locale = I18n.locale != "en" ? `/${I18n.locale}` : ""
    window.location = `${locale}/${this.state.artisticService.artistic_profile_id}/dashboard?filter=draft`
  }

  saveError(response) {
    var newState = Object.assign({}, this.state, {
      errors: response.responseJSON
    })
    this.setState(newState, this.scrollToFirstError)
  }

  handleBackClick(e) {
    e.preventDefault();
    window.history.back();
  }

  handleTagChange(tags) {
    var tagObjects = tags.map(function (tag) {
      return { name: tag }
    })
    var newArtisticService = Object.assign({}, this.state.artisticService, { user_tags: tagObjects })
    var newState = Object.assign({}, this.state, { artisticService: newArtisticService })
    this.setState(newState)
  }

  handleAdminTagChange(tags) {
    var tagObjects = tags.map(function (tag) {
      return { name: tag }
    })
    var newArtisticService = Object.assign({}, this.state.artisticService, { admin_tags: tagObjects })
    var newState = Object.assign({}, this.state, { artisticService: newArtisticService })
    this.setState(newState)
  }

  handleDescriptionChange() {
    var newArtisticService = Object.assign(
      {},
      this.state.artisticService,
      {
        original_description: this.refs.artistic_service_description.value
      }
    )
    var newState = Object.assign({}, this.state, { artisticService: newArtisticService })
    this.setState(newState)
  }

  scrollToFirstError() {
    $("html, body").animate({
      scrollTop: $($(".has-error")[0]).offset().top - 20
    }, "slow");
  }

  render() {
    var widgetElements = [];
    var errors = this.state.errors
    var widgetErrors = [errors.pictures, errors.videos, errors.audios]
    widgetElements = this.state.artisticService.sections.map(function (section, index) {
      return (<div key={index} className="row">
        <ArtisticServiceWidget section={section} errors={widgetErrors[index]} onUpdate={(updateKeyValue) => { this.handleUpdateWidget(index, updateKeyValue) }} />
      </div>)
    }, this);

    var userTags = this.state.artisticService.user_tags.map(function (tag) {
      return tag.name
    });
    var adminTags = this.state.artisticService.admin_tags.map(function (tag) {
      return tag.name
    });
    return (
      <div className="artistic-profile-widgets">
        <div className="row">
          <div className="col-sm-8 col-sm-offset-2">
            <h1>{I18n.t('artistic_services.title')}</h1>
            <h2>{I18n.t('artistic_services.basic_info')}</h2>
          </div>
          <form onSubmit={this.handlePressEnter}>
            <div className="col-sm-4 col-sm-offset-2">
              <div className={"form-group" + (errors.name ? " has-error" : "")}>
                <label htmlFor="artistic_service_name" className="control-label">{I18n.t('artistic_services.name')}</label>
                <input id="artistic-service-name" type="text" ref="artistic_service_name" className="form-control" defaultValue={this.state.artisticService.original_name} />
                {errors.name ? <span id="nameHelp" className="help-block">{errors.name}</span> : null}
              </div>
            </div>
            <div className="col-sm-8 col-sm-offset-2">
              <div className={"form-group" + (errors.description ? " has-error" : "")}>
                <label htmlFor="artistic_service_description" className="control-label">{I18n.t('artistic_services.description')}</label>
                <textarea id="artistic-service-description" ref="artistic_service_description" className="form-control booksa-textarea" rows="4" onChange={this.handleDescriptionChange} defaultValue={this.state.artisticService.original_description} />
                {errors.description ? <span id="descriptionHelp" className="help-block"  >{errors.description}</span> : null}
                <span className="help-block">{500 - (this.state.artisticService.original_description ? this.state.artisticService.original_description.length : 0)} {I18n.t('artistic_services.description_characters')}</span>

              </div>
            </div>
          </form>
          <div className="col-sm-8 col-sm-offset-2">
            <div className="form-group">
              <label className="control-label">{I18n.t('artistic_services.keywords')}</label>
              <ServiceTagsInput value={userTags} handleChange={this.handleTagChange.bind(this)} />
              <span className="help-block">{I18n.t('artistic_services.keywords_info')}</span>
            </div>
          </div>
          <div className={"col-sm-8 col-sm-offset-2" + (this.state.adminMode ? '' : ' hidden')}>
            <h2>Admin Tags</h2>
            <p>Tags above are already included</p>
            <div className="form-group">
              <ServiceTagsInput value={adminTags} handleChange={this.handleAdminTagChange.bind(this)} />
              <span className="help-block">These keywords will not be published on the user page. With those keywords, clients will find this service when they search for artistic services!</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-8 col-sm-offset-2">
            <h2>{I18n.t('artistic_services.add_photos')}</h2>
          </div>
        </div>
        {widgetElements}
        <div className="row action-buttons">
          <div className="col-xs-12">
            <a type="button" onClick={() => { this.handleSaveForLaterButton(this.saveSuccess, this.saveError) }} className="grey-round-button pull-right save-for-later">
              {I18n.t('artistic_services.save_for_later')}
            </a>
          </div>
          <div className="col-xs-6">
            <a type="button" onClick={this.handleBackClick} className="light-grey-round-button pull-left">
              {I18n.t('artistic_services.cancel')}
            </a>
          </div>
          <div className="col-xs-6">
            <button type="button" onClick={() => { this.handleSaveButton(this.saveAndPreviewSuccess, this.saveError) }} className="green-round-button pull-right onboard-target save-and-preview">
              {I18n.t('artistic_services.save_and_preview')}
            </button>
          </div>
        </div>
      </div>)
  }
}

module.exports = ArtisticServiceWidgets
