const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');

const PricingField = ({
  name,
  label,
  value,
  highlight,
}) => {
  const fieldClassName = classNames('b-artist-pricing__hint-field', {
    'b-artist-pricing__hint-field--highlight': highlight,
  });

  return (
    <div className={`row qa-field-${name}`}>
      <div className="col-xs-8">
        <div className={fieldClassName}>{label}</div>
      </div>

      <div className="col-xs-4 qa-field-value">
        <div className={fieldClassName}>{value}</div>
      </div>
    </div>
  );
};

PricingField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  highlight: PropTypes.bool,
};

PricingField.defaultProps = {
  highlight: false,
};

module.exports = PricingField;
