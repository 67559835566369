global.Dropzone = require('dropzone');

Dropzone.autoDiscover = false; // disable the built-in autodiscovery
document.addEventListener('turbolinks:load', setupjQuery);

function getCSRFHeader() {
  return { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') };
}

const defaultAttributes = () => {
  return {
    dictFileTooBig: I18n.t('negotiation_chat.errors.file_too_big', {
      maxFilesize: '{{maxFilesize}}',
      filesize: '{{filesize}}',
    }),
    dictInvalidFileType: I18n.t('negotiation_chat.errors.invalid_file_type'),
    dictCancelUpload: '✕',
    dictRemoveFile: '✕',
    headers: getCSRFHeader(),
    parallelUploads: 1,
    addRemoveLinks: true,
    autoProcessQueue: true,
  };
};

function setupjQuery() {
  setupjQuerySegmentButtons();
  setupjQueryDropzone();

  $(".artistic-profile-form").on("ajax:success", function(e, data, status, xhr){
    $(this).find(".alert").hide();
    window.location = xhr.getResponseHeader("Location") + "/dashboard";
  }).on("ajax:error", function(e, xhr, status, error){
    $("html, body").animate({ scrollTop: 0 }, "slow");
    var fields_with_error = Object.keys(xhr.responseJSON);
    var errors = [I18n.t('shared.error_title') + "\n"];
    for(var field in fields_with_error){
      var key = fields_with_error[field]
      errors.push(I18n.t('shared.errors.' + key) + ": " + xhr.responseJSON[key]);
    }
    $(this).find(".alert").html(errors.join("<br/>")).show();
  });
}

function setupjQueryDropzone() {
  setupDropzone($('div.dropzone'));
}

function setupjQuerySegmentButtons() {
  var is_group = $("#artistic_profile_is_group").val();
  if(is_group === "true"){
    $(".last-control").addClass("segmented-control-item-selected");
  } else if(is_group === "false") {
    $(".first-control").addClass("segmented-control-item-selected");
  }

  $('.first-control').click(function () {
    $("#artistic_profile_is_group").val("false");
    $(".first-control").addClass("segmented-control-item-selected");
    $(".last-control").removeClass("segmented-control-item-selected");
  });

  $('.last-control').click(function () {
    $("#artistic_profile_is_group").val("true");
    $(".last-control").addClass("segmented-control-item-selected");
    $(".first-control").removeClass("segmented-control-item-selected");
  });
}

export function setupReactDropzone(element, initialImages, imgAddedCallback, imgRemovedCallback){
  $(element).dropzone({
    url: '/pictures',
    paramName: 'picture[url]', // The name that will be used to transfer the file
    maxFilesize: 10, // MB
    maxFiles: 12,
    acceptedFiles: 'image/*',
    init: function(){
      var appendPhotoPlaceholders = function(number_of_files){
        var elementStr = "<div class='add-more-photos'><div class='add-more-photos-inner'><p>+</p></div></div>";
        var numberOfAddMorePhotos = 4 - (number_of_files % 4);
        if(number_of_files > 0 && number_of_files < 12){
          for (var i=0; i < numberOfAddMorePhotos; i++){
            $(".dropzone").append(elementStr);
          }
        }
      }

      if(initialImages.length > 0){
        initialImages.forEach(function(image){
          var mockFile = {
            name: "saved_pic",
            id: image.id,
            dataURL: image.url.url
          };
          this.files.push(mockFile);
          this.emit("addedfile", mockFile);
          this.createThumbnailFromUrl(mockFile,
            this.options.thumbnailWidth,
            this.options.thumbnailHeight,
            this.options.thumbnailMethod,
            true,
            function (thumbnail) {
              this.emit('thumbnail', mockFile, thumbnail);
            }.bind(this),
            'Anonymous'
          );
          this.emit("complete", mockFile);
        }.bind(this));
        appendPhotoPlaceholders(this.files.length)
      }
      this.on("addedfile", function(file){
        if(this.files.length >= 1){
          $(".add-more-photos").remove();
          appendPhotoPlaceholders(this.files.length)
        }
      });
      this.on("success", function(file, response){
        file.id = response.id;
        imgAddedCallback(response);
      });

      this.on("removedfile", function(file){
        imgRemovedCallback(file.id);
        $(".add-more-photos").remove();
        appendPhotoPlaceholders(this.files.length)
      });
    },
    ...defaultAttributes(),
  });
}

function setupDropzone(elements){
  elements.dropzone({
    url: '/pictures',
    paramName: 'picture[url]', // The name that will be used to transfer the file
    maxFilesize: 10, // MB
    maxFiles: 1,
    acceptedFiles: 'image/*',
    thumbnailWidth: null, // no resizing for thumbnail
    thumbnailHeight: null,
    init: function(){
      $('.dz-image-preview').remove();
      if($(this.element).children('.picture-id').val()){
        var id = $(this.element).children('.picture-id').val();
        var url = $(this.element).children('.picture-url').val();
        var mockFile = {name: "saved_pic", dataURL: url};
        this.emit("addedfile", mockFile);
        this.createThumbnailFromUrl(mockFile,
          this.options.thumbnailWidth,
          this.options.thumbnailHeight,
          this.options.thumbnailMethod,
          true,
          function (thumbnail) {
            this.emit('thumbnail', mockFile, thumbnail);
          }.bind(this),
          'Anonymous'
        );
        this.emit("complete", mockFile);
        this.files.push(mockFile);
        this.options.maxFiles = 0;
      }
      this.on("success", function(file, response){
        $(this.element).children('.picture-id').val(response.id);
      });
      this.on("removedfile", function(file){
        $(this.element).children('.picture-id').val("");
      });
    },
    maxfilesexceeded: function(file) {
      this.options.maxFiles = 1;
      this.removeAllFiles();
      this.addFile(file);
    },
    ...defaultAttributes(),
  });
}

export function setupDropzoneAttachments(elements, options = {}) {
  elements.dropzone({
    url: '/negotiation_attachments',
    paramName: 'negotiation_attachment[file]', // The name that will be used to transfer the file
    maxFilesize: 10, // MB
    maxFiles: 1,
    thumbnailWidth: null, // no resizing for thumbnail
    thumbnailHeight: null,
    init: function(){
      $('.dz-image-preview').remove();
      if($(this.element).children('.negotiation_attachment-id').val()){
        var id = $(this.element).children('.negotiation_attachment-id').val();
        var url = $(this.element).children('.negotiation_attachment-url').val();
        var mockFile = {name: "saved_pic", dataURL: url};
        this.emit("addedfile", mockFile);
        this.createThumbnailFromUrl(mockFile,
          this.options.thumbnailWidth,
          this.options.thumbnailHeight,
          this.options.thumbnailMethod,
          true,
          function (thumbnail) {
            this.emit('thumbnail', mockFile, thumbnail);
          }.bind(this),
          'Anonymous'
        );
        this.emit("complete", mockFile);
        this.files.push(mockFile);
        this.options.maxFiles = 0;
      }

      this.on("success", function(file, response) {
        $(this.element).children('.negotiation_attachment-id').val(response.id);
        $(this.element).parent().addClass('b-chat__input-with_attach');

        if (options.onSuccess) {
          options.onSuccess();
        }
      });

      this.on("removedfile", function() {
        $(this.element).children('.negotiation_attachment-id').val("");
        $(this.element).parent().removeClass('b-chat__input-with_attach');

        if (options.onRemovedFile) {
          options.onRemovedFile();
        }
      });

      this.on('error', function(file, message) {
        if (file.previewElement) {
          file.previewElement.classList.add('dz-error');
          $(this.element).parent().addClass('b-chat__input-with_attach');
        }

        if (options.onError) {
          options.onError(message);
        }
      });
    },
    maxfilesexceeded: function(file) {
      this.options.maxFiles = 1;
      this.removeAllFiles();
      this.addFile(file);
    },
    ...defaultAttributes(),
  });
}
