var React = require('react')
var PropTypes = require('prop-types')
var SoundcloudWidget = require('./soundcloud_widget')

class AudioSection extends React.Component {
  constructor(props) {
    super(props)
    this.handleAddAudio = this.handleAddAudio.bind(this)
    this.getSoundcloudUrl = this.getSoundcloudUrl.bind(this)
    this.parseAudioUrls = this.parseAudioUrls.bind(this)
  }

  handleAddAudio(event) {
    event.preventDefault()
    var newAudioUrls;
    var newAudio = this.refs.audioUrlTextfield.value
    if (this.getSoundcloudUrl(newAudio)) {
      if (!this.props.audioUrls) {
        newAudioUrls = newAudio
      } else {
        newAudioUrls = this.props.audioUrls + "|" + newAudio
      }
      this.props.onUpdate({ 'audio_urls': newAudioUrls, 'errors': "" })
      this.refs.audioUrlTextfield.value = ""
    } else {
      this.props.onUpdate({ 'errors': I18n.t('artistic_services.errors.audios') })
    }

  }

  parseAudioUrls(urls) {
    return urls && urls.split("|") || []
  }

  getSoundcloudUrl(url) {
    return (url.includes("soundcloud") ? url : null)
  }

  getAudioWindowSizes(numberOfVideos) {
    var result = []
    for (var i = 0; i < numberOfVideos; i++) {
      result.push(12)
    }
    return result
  }

  handleRemoveAudio(index) {
    var urls = this.props.audioUrls && this.props.audioUrls.split("|") || []
    urls.splice(index, 1)
    this.props.onUpdate({ 'audio_urls': urls.join("|") })
  }

  render() {
    var audioUrls = this.parseAudioUrls(this.props.audioUrls)
    var windowSizes = this.getAudioWindowSizes(audioUrls.length)
    var errors = []
    var audioWidgets = audioUrls.map((audioUrl, index) => {
      var soundcloudUrl = this.getSoundcloudUrl(audioUrl)
      var widget = soundcloudUrl ? <SoundcloudWidget soundcloudUrl={soundcloudUrl} removeAudio={() => { this.handleRemoveAudio(index) }} show={this.props.show} /> : null
      return (<div key={index} style={{ paddingTop: 20 + 'px' }} className={"col-sm-" + windowSizes[index]}>
        {widget}
      </div>)
    })

    return (<div className="audio-section">
      {!this.props.show && <div><div className="col-sm-8 col-sm-offset-2">
        <p>{I18n.t('artistic_services.add_audio')}</p>
      </div>
        <form onSubmit={this.handleAddAudio} className={"form-inline" + (this.props.errors ? " has-error" : "")}>
          <div className="col-sm-6 col-sm-offset-2">
            <input type="text" ref="audioUrlTextfield" className="form-control" />
          </div>
          <div className="col-sm-2">
            <button type="button" onClick={this.handleAddAudio} className="grey-round-button">{I18n.t('artistic_services.add')}</button>
          </div>
          {this.props.errors ?
            <div className="col-sm-8 col-sm-offset-2">
              <span className="error-block">{this.props.errors}</span>
            </div> : null
          }
        </form> </div>}
      <div className={this.props.show ? "" : "col-sm-8 col-sm-offset-2"}>
        {audioWidgets}
      </div>

    </div>)
  }
}

module.exports = AudioSection
