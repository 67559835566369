const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');

const VerticalField = ({
  name,
  value,
  className,
  nameClasses,
  valueClasses,
}) => {
  return (
    <div className={classNames('b-vertical-field', className)}>
      <div className={classNames('b-vertical-field__name', nameClasses)}>{name}</div>
      <div className={classNames('b-vertical-field__value', valueClasses)}>{value}</div>
    </div>
  );
};

VerticalField.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  nameClasses: PropTypes.string,
  valueClasses: PropTypes.string,
};

VerticalField.defaultProps = {
  className: '',
  nameClasses: '',
  valueClasses: '',
  name: '',
  value: '',
};

module.exports = VerticalField;
