const React = require('react');
const PropTypes = require('prop-types');
const CreditCardConfirmation = require('./credit_card_confirmation');
const ReservationConfirmation = require('./reservation_confirmation');

function Confirmation({
  paymentType,
  paymentStatus,
  client,
  bookingNumber,
  bookingValue,
}) {
  return (
    <div className="container basa-offersConfirmation">
      <div className="row">
        <div className="col-sm-offset-3 col-sm-6">
          {
            paymentType === 'credit card'
              ? (
                <CreditCardConfirmation
                  client={client}
                  bookingNumber={bookingNumber}
                  bookingValue={bookingValue}
                  paymentStatus={paymentStatus}
                />
              )
              : (
                <ReservationConfirmation
                  client={client}
                  bookingNumber={bookingNumber}
                  bookingValue={bookingValue}
                />
              )
          }
        </div>
      </div>
    </div>
  );
}

Confirmation.propTypes = {
  paymentType: PropTypes.string.isRequired,
  paymentStatus: PropTypes.string.isRequired,
  client: PropTypes.object.isRequired,
  bookingNumber: PropTypes.string.isRequired,
  bookingValue: PropTypes.string.isRequired,
};

module.exports = Confirmation;
