import React from 'react';
import { stopVideos } from 'lib/stopVideo';

const lozad = require('lozad');
const PropTypes = require('prop-types');

const VideoSlide = ({
  youtube_video_uid,
  name,
  showTag,
  tagText,
  autoPlay,
}) => {
  const [showVideo, setShowVideo] = React.useState(false);
  const observer = lozad();

  const onClickImage = (event) => {
    event.preventDefault();
    stopVideos();

    setShowVideo(true);
  };

  React.useEffect(() => {
    observer.observe();
  }, []);

  if (showVideo) {
    return (
      <iframe
        width="100%"
        height="100%"
        src={`https://www.youtube.com/embed/${youtube_video_uid}?rel=0&modestbranding=1&autohide=1&showinfo=0&controls=0&autoplay=${autoPlay}`}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        sandbox="allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation"
        allowFullScreen
        title={name}
        loading="lazy"
      />
    );
  }

  return (
    <a onClick={onClickImage} href={`https://www.youtube.com/embed/${youtube_video_uid}?autoplay=1`}>
      <img
        className="lozad cactus-video-card__video-thumbnail"
        data-src={`https://img.youtube.com/vi/${youtube_video_uid}/hqdefault.jpg`}
        alt={name}
      />
      <span className="cactus-video-card__video-play-button">▶</span>
      {
        showTag
        && (
        <div className="cactus-video-card__tag-container cactus-video-card__tag-container--mobile">
          <span className="cactus-video-card__tag-text">
            {tagText}
          </span>
        </div>
        )
      }
    </a>
  );
};

VideoSlide.propTypes = {
  youtube_video_uid: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  showTag: PropTypes.bool,
  tagText: PropTypes.string,
  autoPlay: PropTypes.string,
};

VideoSlide.defaultProps = {
  showTag: false,
  autoPlay: '1',
  tagText: '',
};

export default VideoSlide;
