const React = require('react');
const PropTypes = require('prop-types');
const debounce = require('lodash.debounce');

const BillingFormField = require('./billing_form_field');
const WithBillingDataContext = require('./with-billing-data-context');
const AsyncEndpointRequest = require('../../../lib/async_endpoint_request');
const VatChecker = require('../../../lib/vat_checker');

class VatField extends React.Component {
  constructor(props) {
    super(props);

    this.onChangeField = this.onChangeField.bind(this);
    this.validateVat = debounce(this.validateVat, 1000);
  }

  onChangeField(field, value) {
    if (this.request) {
      this.request.abort();
    }

    this.props.context.updateBillingDetailState({ vat: value });
    this.validateVat(value);
  }

  validateVat(vat) {
    if (VatChecker.isValidSyntax(vat)) {
      this.props.context.updateFormState({
        vatExclude: false,
        vatValidating: true,
      });
      this.checkValidVAT(vat);
    } else {
      this.props.context.updateFormState({
        vatExclude: false,
        vatValidating: false,
      });
    }
  }

  checkValidVAT(value) {
    const offer_id = this.props.context.offer.id;

    this.request = new AsyncEndpointRequest({
      path: '/vat_validations',
      params: {
        vat: value,
        offer_id: offer_id
      },
    });

    this.request.run().then(() => {
      this.props.context.updateFormState({
        vatExclude: !VatChecker.isGerman(value),
        vatValidating: false,
      });
    }, () => {
      this.props.context.updateFormState({
        vatExclude: false,
        vatValidating: false,
      });
    });
  }

  render() {
    return (
      <BillingFormField
        fieldName="vat"
        fieldValue={this.props.vat}
        hasError={this.props.hasError}
        errorMessage={this.props.errorMessage}
        onChangeField={this.onChangeField}
      />
    );
  }
}

VatField.propTypes = {
  context: PropTypes.object.isRequired,
  vat: PropTypes.string,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
};

VatField.defaultProps = {
  vat: '',
  hasError: false,
  errorMessage: '',
};

module.exports = WithBillingDataContext(VatField);
