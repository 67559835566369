const React = require('react');
const PropTypes = require('prop-types');

function ReservationConfirmation({
  client,
  bookingNumber,
  bookingValue,
}) {
  return (
    <div>
      <h1 className="basa-offersConfirmation-h1">{I18n.t('payment_process.confirmation.congratulations', { name: client.full_name })}</h1>
      <h2 className="basa-offersConfirmation-h2">{I18n.t('payment_process.confirmation.booking_number', { number: bookingNumber })}</h2>
      <p className="basa-offersConfirmation-p">{I18n.t('payment_process.confirmation.reservation_reminder')}</p>
      <p className="basa-offersConfirmation-p">{I18n.t('payment_process.confirmation.bank_details', { email: client.email })}</p>
      <a className="basa-offersConfirmation-button" href="/search">{I18n.t('payment_process.confirmation.cta_button')}</a>
      <div id="payment-booking-value" data-booking-value={bookingValue} />
    </div>
  );
}

ReservationConfirmation.propTypes = {
  client: PropTypes.object.isRequired,
  bookingNumber: PropTypes.string.isRequired,
  bookingValue: PropTypes.string.isRequired,
};

module.exports = ReservationConfirmation;
