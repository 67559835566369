const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');

const Tab = ({
  link,
  text,
  selected,
  onClick,
}) => (
  <div className={classNames('b-tabs__tab', { 'b-tabs__tab--selected': selected })}>
    <a href={link} onClick={e => onClick(link, e)}>{text}</a>
  </div>
);

Tab.propTypes = {
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
};

Tab.defaultProps = {
  onClick: () => {},
};

module.exports = Tab;
