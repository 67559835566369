import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [];

  connect() {
    this.element.addEventListener("load", this.resizeIframe.bind(this));
  }

  resizeIframe() {
    this.timeout = setTimeout(() => {
      const height = this.element.contentWindow.document.body.scrollHeight;
      const width = this.element.contentWindow.document.body.scrollWidth;

      this.element.height = height;
      this.element.width = width;
      this.timeout = null;
    }, 1000);
  }

  disconnect() {
    this.element.removeEventListener("load", this.resizeIframe.bind(this));
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }
}
