const React = require('react');
const PropTypes = require('prop-types');

const ReactDatePicker = require('react-datepicker').default;
require('react-datepicker/dist/react-datepicker.css');

const CustomInput = ({ value, onClick }) => (
  <input onClick={onClick} readOnly value={value} />
);

CustomInput.propTypes = {
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

const DatePicker = ({
  customInput,
  selected,
  onChange,
  dateFormat,
  placeholderText,
  className,
  withPortal,
  minDate,
  popperPlacement,
  popperModifiers,
}) => {
  return (
    <div className="b-datepicker">
      <ReactDatePicker
        customInput={customInput}
        selected={selected}
        onChange={onChange}
        dateFormat={dateFormat}
        placeholderText={placeholderText}
        className={className}
        withPortal={withPortal}
        minDate={minDate}
        popperPlacement={popperPlacement}
        popperModifiers={popperModifiers}
      />
      <i className="b-datepicker__glyph glyphicon glyphicon-calendar" />
    </div>
  );
};

DatePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  customInput: PropTypes.element,
  selected: PropTypes.bool,
  dateFormat: PropTypes.string,
  placeholderText: PropTypes.string,
  className: PropTypes.string,
  withPortal: PropTypes.bool,
  minDate: PropTypes.bool,
  popperPlacement: PropTypes.string,
  popperModifiers: PropTypes.object,
};

DatePicker.defaultProps = {
  customInput: null,
  selected: null,
  dateFormat: null,
  placeholderText: null,
  className: null,
  withPortal: null,
  minDate: null,
  popperPlacement: null,
  popperModifiers: null,
};

module.exports = DatePicker;
