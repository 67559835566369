const React = require('react');
const PropTypes = require('prop-types');

class Checkbox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: props.checked,
    };
  }

  onChangeCheckbox = (event) => {
    const { checked } = event.target;
    const { onChange, name } = this.props;

    this.setState({ checked });
    onChange(name, checked);
  }

  render() {
    const { name, label } = this.props;

    return (
      <div className="checkbox">
        <label htmlFor={name}>
          <input
            type="checkbox"
            id={name}
            checked={this.state.checked}
            onChange={this.onChangeCheckbox}
          />
          <span>{label}</span>
        </label>
      </div>
    );
  }
}

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

Checkbox.defaultProps = {
  onChange: () => {},
};

module.exports = Checkbox;
