const React = require('react');
const PropTypes = require('prop-types');

const SPACING_UNIT = 10;

const InlineRow = ({ children, spacing }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      marginLeft: -(spacing * SPACING_UNIT) / 2,
      marginRight: -(spacing * SPACING_UNIT) / 2,
    }}
  >
    {React.Children.map(children, child => (
      <div
        style={{
          display: 'flex',
          marginLeft: (spacing * SPACING_UNIT) / 2,
          marginRight: (spacing * SPACING_UNIT) / 2,
          alignItems: 'center',
        }}
      >
        {child}
      </div>
    ))}
  </div>
);

InlineRow.propTypes = {
  children: PropTypes.element.isRequired,
  spacing: PropTypes.number.isRequired,
};

module.exports = InlineRow;
