const React = require('react');
const PropTypes = require('prop-types');
const ArtistOfferView = require('./artist_offer_view');

class ArtistNextSteps extends React.Component {
  listItem(number, text) {
    return (
      <div className="b-next-steps__item row">
        <div className="b-next-steps__item-number">{number}</div>
        <div className="b-next-steps__item-text">
          {text}
        </div>
      </div>
    )
  }

  header(text) {
    return (
      <div className="row">
        <h1 className="b-next-steps__header col-xs-11 col-xs-offset-1">{text}</h1>
      </div>
    )
  }

  render() {
    return (
      <div className="container-fluid b-next-steps">
        <div className="row b-next-steps__container">
          <div className="col-sm-6 b-next-steps__left">
            <div>
              <ArtistOfferView
                inquiry={this.props.inquiry}
                offer={this.props.offer}
                extraCosts={this.props.extraCosts}
                pov="artist"
              />
            </div>
          </div>
          <div className="col-sm-6 b-next-steps__right">
            {this.header(I18n.t('artist_next_steps.title'))}
            <div className="b-next-steps__list">
              {this.listItem(
                '1.',
                I18n.t('artist_next_steps.step_1'),
              )}
              {this.listItem(
                '2.',
                I18n.t('artist_next_steps.step_2'),
              )}
              {this.listItem(
                '3.',
                I18n.t('artist_next_steps.step_3'),
              )}
              {this.listItem(
                '4.',
                I18n.t('artist_next_steps.step_4'),
              )}
              <div className="b-next-steps__item row">
                <div className="col-xs-11 col-xs-offset-1">
                  <div className="b-next-steps__contact">
                    {I18n.t('artist_next_steps.any_questions')}
                    <a className="b-next-steps__contact-link" href="mailto:support@bookastreetartist.com"> support@bookastreetartist.com</a>
                  </div>
                </div>
              </div>

            </div>
            <div className="row">
              <div className="col-xs-11 col-xs-offset-1">
                <a href={this.props.dashboardPath} className="b-next-steps__button b-button">
                  {I18n.t('artist_next_steps.back_to_dashboard')}
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-11 col-xs-offset-1">
                <a href={this.props.bookingPath} className="b-next-steps__button b-button b-button--inverse">
                  {I18n.t('artist_next_steps.back_to_booking')}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

ArtistNextSteps.propTypes = {
  offer: PropTypes.object.isRequired,
  inquiry: PropTypes.object.isRequired,
  extraCosts: PropTypes.array.isRequired,
  bookingPath: PropTypes.string.isRequired,
  dashboardPath: PropTypes.string.isRequired,
};

module.exports = ArtistNextSteps;
