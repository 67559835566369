import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['artisticService', 'draft', 'published', 'pendingApproval'];

  delete(event) {
    this.decrementCounter(event.currentTarget.dataset.servicetype);
    const serviceId = event.currentTarget.dataset.serviceid;
    const card = document.getElementById(
      `artistic-service-horizontal-card-${serviceId}`,
    );
    card.classList.add('hidden');
  }

  decrementCounter(serviceType) {
    const serviceTypeTargets = {
      draft: this.hasDraftTarget && this.draftTarget,
      published: this.hasPublishedTarget && this.publishedTarget,
      pending_approval:
        this.hasPendingApprovalTarget && this.pendingApprovalTarget,
    };
    const element = serviceTypeTargets[serviceType];

    if (!element) return;

    const fullTitleText = element.textContent;
    const regex = /(\D+)\((\d+)\)/;
    const match = regex.exec(fullTitleText);

    if (!match) return;

    // eslint-disable-next-line no-unused-vars
    const [_, titleText, oldCount] = match;
    const newCount = parseInt(oldCount, 10) - 1;

    element.textContent = `${titleText} (${newCount})`;
  }
}
