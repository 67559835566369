import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from '../../../ui/Button/';
import Heading from '../../../../components/ui/Heading/';
import Layout from '../../../ui/Layout/';
import TextArea from '../../../ui/Input/TextArea/';

class Message extends Component {
  state = { message: null }

  render() {
    const {
      onLeaveMessage,
    } = this.props;

    return (
      <div>
        <Heading text={I18n.t('booking_cancelation.leave_message.title')} />
        <p>{I18n.t('booking_cancelation.leave_message.description')}</p>
        <Layout.List>
          <TextArea
            fieldValue={this.state.message}
            onChange={event => this.setState({ message: event.target.value })}
          />
          <Button primary onClick={() => onLeaveMessage({ message: this.state.message })}>{I18n.t('shared.send')}</Button>
        </Layout.List>
      </div>
    );
  }
}

Message.defaultProps = {
  onLeaveMessage: () => {},
};

Message.propTypes = {
  onLeaveMessage: PropTypes.func,
};

export default Message;
