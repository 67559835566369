const React = require('react');
const PropTypes = require('prop-types');

const PlayButton = ({ onClick, text }) => {
  return (
    <button
      className="b-button b-button--inverse b-button--play-icon"
      onClick={onClick}
      style={{ marginBottom: 10 }}
    >
      <i className="glyphicon glyphicon-play" />
      <span>{text}</span>
    </button>
  );
};

PlayButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

module.exports = PlayButton;
