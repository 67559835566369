const React = require('react');
const PropTypes = require('prop-types');
const Modal = require('../../../ui/Modal');

const ArtistDoubleCheckModal = ({
  isSending,
  isOpen,
  onClose,
  onConfirm,
}) => (
  <Modal
    isOpen={isOpen}
    onClose={onClose}
  >
    <div className="b-react-modal__title b-react-modal__title--green">
      {I18n.t('negotiation_dashboard.artist.double_check')}
    </div>
    <div className="b-react-modal__body b-react-modal__text-block">
      {I18n.t('negotiation_dashboard.artist.double_check_description')}
    </div>
    <div className="b-react-modal__footer">
      <button onClick={onClose} className="b-button b-button--inverse">
        {I18n.t('negotiation_dashboard.artist.review')}
      </button>
      <button
        disabled={isSending}
        onClick={onConfirm}
        className="b-button"
      >
        {I18n.t('negotiation_dashboard.artist.send_to_client')}
      </button>
    </div>
  </Modal>
);

ArtistDoubleCheckModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isSending: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

module.exports = ArtistDoubleCheckModal;
