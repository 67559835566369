const Container = require('./Container');
const List = require('./List');
const InlineRow = require('./InlineRow');

const Layout = {
  Container,
  List,
  InlineRow,
};

module.exports = Layout;
