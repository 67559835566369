const Slideout = require('slideout');
const { dataLayer } = require('../lib/analytics');

const initSidebar = () => {
  $('html').removeClass('slideout-open');
  // $('.navbar-toggle').removeClass('is-active');
  $('main#panel').removeClass('content-open');

  const panel = document.getElementById('panel');
  const menu = document.getElementById('menu');

  if (!(panel && menu)) {
    return;
  }

  const slideout = new Slideout({
    panel: document.getElementById('panel'),
    menu: document.getElementById('menu'),
    padding: 342,
    tolerance: 70,
    side: 'right',
    touch: false,
  });

  $('.slideout-open').click(() => {
    slideout.toggle();
    // $(this).toggleClass('is-active');
  });

  $('.slideout-close').click(() => {
    slideout.toggle();
    // $(this).toggleClass('is-active');
  });

  function close(eve) {
    eve.preventDefault();
    // const hamburger = document.querySelector('.navbar-toggle');
    // $(hamburger).toggleClass('is-active');
    slideout.close();
  }

  slideout
    .on('beforeopen', () => {
      panel.classList.add('content-open');
      const elementsToHide = document.querySelectorAll('.navbar-toggle-hide');
      elementsToHide.forEach((e) => {
        e.dataset.opacityWas = e.style.opacity;
        e.dataset.zIndexWas = e.style.zIndex;
        e.style.opacity = '0';
        e.style.zIndex = '0';
      });
    })
    .on('open', () => {
      dataLayer.push({
        event: 'openMenu',
      });
      panel.addEventListener('click', close);
    })
    .on('beforeclose', () => {
      panel.classList.remove('content-open');
      // $(this).toggleClass('is-active');
      panel.removeEventListener('click', close);
      const elementsToShow = document.querySelectorAll('.navbar-toggle-hide');
      elementsToShow.forEach((e) => {
        e.style.opacity = e.dataset.opacityWas;
        e.style.zIndex = e.dataset.zIndexWas;
      });
    });
};

document.addEventListener('turbolinks:load', initSidebar);
