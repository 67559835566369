const React = require('react');
const PropTypes = require('prop-types');

const Label = ({ children }) => (
  <div className="b-input__label">{children}</div>
);

Label.propTypes = {
  children: PropTypes.any.isRequired,
};

module.exports = Label;
