const React = require("react");
const PropTypes = require("prop-types");
const { ampEvent } = require("lib/amplitude");
const flow = require("lodash.flow");
const ScrollableAnchor = require("react-scrollable-anchor").default;

const Modal = require("../../../ui/Modal");
const Step = require("../../step");
const withFoldableStep = require("./with-foldable-step");
const NegotiationChat = require("../../../negotiation_chat/negotiation-chat");
const ArtistPricing = require("./artist_pricing");
const withArtistDashboardContext = require("./with-artist-dashboard-context");
const ContextPropTypes = require("./context-prop-types");
const HelpTooltip = require("../../../ui/HelpTooltip");
const { dataLayer } = require("../../../../lib/analytics");

class ChatStep extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpened: false,
    };
  }

  onClickMoveToOffer = () => {
    this.props.context.toggleFold("offer", false);
  };

  onClickCall = () => {
    this.setState({ modalOpened: true });

    dataLayer.push({
      event: "clickCallClient",
    });
    ampEvent("artist_dashboard: clickCallClient");
  };

  renderModal() {
    const { modalOpened } = this.state;
    const { context } = this.props;
    const { isMobile, inquiry } = context;

    return (
      <Modal
        onClose={() => this.setState({ modalOpened: false })}
        isOpen={modalOpened}
      >
        {isMobile ? (
          <>
            <p>{I18n.t("negotiation_dashboard.artist.call_modal.title")}</p>
            <p>{I18n.t("negotiation_dashboard.artist.call_modal.good_luck")}</p>
            <a href={`tel://${inquiry.phone}`} className="b-button">
              {I18n.t("negotiation_dashboard.artist.call_modal.button")}
            </a>
          </>
        ) : (
          <>
            <p>{I18n.t("negotiation_dashboard.artist.call_modal.title")}</p>
            <p>
              {I18n.t("negotiation_dashboard.artist.call_modal.client_phone", {
                number: inquiry.phone,
              })}
            </p>
            <p>{I18n.t("negotiation_dashboard.artist.call_modal.good_luck")}</p>
          </>
        )}
      </Modal>
    );
  }

  render() {
    const { isFolded, onClickFold } = this.props;

    const {
      inquiry,
      messages,
      messagingPath,
      showCalculator,
      toggleCalculator,
    } = this.props.context;

    return (
      <>
        <ScrollableAnchor id="chat">
          <div id="chat-step" />
        </ScrollableAnchor>
        <Step
          title={I18n.t("negotiation_dashboard.artist.get_in_touch", {
            name: inquiry.full_name,
          })}
          number={2}
          folded={isFolded}
          done
          onClickHeader={onClickFold}
          foldingLocator="qa-toggle-chat"
        >
          <div className="b-artist-dashboard__subheader">
            <h3 id="chat-step-calculator">
              {I18n.t("negotiation_dashboard.artist.calculator")}
            </h3>
            <span className="b-artist-dashboard__subheader__tip">
              <HelpTooltip
                text={I18n.t(
                  "negotiation_dashboard.tips.artist.final_gross_amount"
                )}
              />
            </span>
            <div className="b-artist-dashboard__subheader__error">
              {this.props.context.errors.zero_total_amount}
            </div>
          </div>

          <div className="b-artist-dashboard__money-info">
            {I18n.t("negotiation_dashboard.artist.calculator_description")}
          </div>

          {showCalculator && <ArtistPricing />}

          <button
            className="b-artist-dashboard__toggle-button qa-toggle-calculator"
            type="button"
            onClick={toggleCalculator}
            style={{ marginBottom: 20 }}
          >
            {showCalculator
              ? I18n.t("negotiation_dashboard.artist.calculator_toggle_hide")
              : I18n.t("negotiation_dashboard.artist.calculator_toggle_show")}
          </button>

          <div className="b-artist-dashboard__subheader" id="chat-section">
            <h3>{I18n.t("negotiation_dashboard.messenger.title")}</h3>
            <span className="b-artist-dashboard__subheader__tip">
              <HelpTooltip
                text={I18n.t(
                  "negotiation_dashboard.tips.artist.worry_response"
                )}
              />
            </span>
          </div>

          <div className="b-artist-dashboard__chat-info">
            {I18n.t("negotiation_dashboard.artist.messenger_description")}
          </div>

          <div className="b-artist-dashboard__chat-call">
            <h5 className="b-artist-dashboard__chat-call__name">
              {inquiry.full_name}
            </h5>
            <button
              className="b-artist-dashboard__chat-call__button"
              type="button"
              onClick={this.onClickCall}
            >
              <span className="glyphicon glyphicon-earphone" />
            </button>
          </div>

          <NegotiationChat
            origin="artist"
            userName={inquiry.full_name}
            messages={messages}
            messagingPath={messagingPath}
            placeholder={I18n.t(
              "negotiation_dashboard.artist.messenger_placeholder"
            )}
          />

          <div className="b-negotiation__button-wrapper">
            <a
              href="#offer-step"
              className="b-button"
              onClick={this.onClickMoveToOffer}
            >
              {this.props.context.isOfferSent
                ? I18n.t("negotiation_dashboard.artist.view_quote")
                : I18n.t("negotiation_dashboard.artist.send_a_quote")}
            </a>
          </div>
        </Step>
        {this.renderModal()}
      </>
    );
  }
}

ChatStep.propTypes = {
  isFolded: PropTypes.bool.isRequired,
  onClickFold: PropTypes.func.isRequired,

  context: ContextPropTypes.isRequired,
};

module.exports = flow(
  withFoldableStep("chat"),
  withArtistDashboardContext
)(ChatStep);
