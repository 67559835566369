const React = require('react');
const PropTypes = require('prop-types');
const Button = require('../ui/Button');

const ShareLink = ({ link }) => {
  const inputEl = React.useRef(null);

  const copyToClipboard = () => {
    inputEl.current.select();
    document.execCommand('copy');
  };

  return (
    <div className="row">
      <div className="col-sm-8">
        <input
          className="b-input-field__value"
          type="text"
          ref={inputEl}
          value={link}
          readOnly
        />
      </div>
      <div className="col-sm-4">
        <Button
          onClick={copyToClipboard}
          primary
          small
        >
          {I18n.t('hunters.new.copy_link')}
        </Button>
      </div>
    </div>
  );
};

ShareLink.propTypes = {
  link: PropTypes.string.isRequired,
};

module.exports = ShareLink;
