const { dataLayer } = require('../lib/analytics');

const setupClientIdTracking = () => {
  if ($('#client-negotiation-dashboard').length > 0) {
    if (window.__client_id) {
      dataLayer.push({
        userId: `client/${window.__client_id}`,
      });
    }
  }

  if ($('.b-payment').length > 0) {
    if (window.__client_id) {
      dataLayer.push({
        userId: `client/${window.__client_id}`,
      });
    }
  }
};

document.addEventListener('turbolinks:load', setupClientIdTracking);
