const React = require("react");
const { ampEvent } = require("lib/amplitude");
const FinalAmount = require("../../final_amount");
const InputField = require("../../input_field");
const OptionalInputField = require("../../optional_input_field");
const TextAreaField = require("../../text_area_field");
const withArtistDashboardContext = require("./with-artist-dashboard-context");
const ContextPropTypes = require("./context-prop-types");
const { OFFER_SPECIFIC_CHAR_LIMIT } = require("../../../../domain/offer");
const CancellationPolicyField = require("./cancellation_policy_field");
const { dataLayer } = require("../../../../lib/analytics");

class ArtistOffer extends React.Component {
  onOfferChange = (event) => {
    const { value, name } = event.target;

    this.props.context.updateDraftOffer({
      [name]: value,
    });

    this.props.context.clearErrors([name]);
  };

  onValidateOffer = () => {
    const { context } = this.props;

    context.validateOffer();

    const { booking, inquiry } = context;

    // Send tag to hotjar on click
    hj("tagRecording", ["artist_send_offer_click"]);
    dataLayer.push({
      event: "clickSendOffer",
    });
    ampEvent("artist_dashboard: clickSendOffer", {
      booking_id: booking.id,
      inquiry_id: inquiry.id,
    });
  };

  onOfferSubmit = (event) => {
    event.preventDefault();
  };

  render() {
    const {
      draftOffer,
      inquiry,
      errors,
      error,
      computedPricing,
      isSaving,
      isSending,
      updateDraftOffer,
    } = this.props.context;

    return (
      <div className="b-artist-offer">
        <form onSubmit={this.onOfferSubmit}>
          <InputField
            name="client_name"
            fieldName={I18n.t("inquiry_preview.client_name")}
            fieldValue={draftOffer.client_name}
            onChange={this.onOfferChange}
            fieldError={errors.client_name}
          />
          <OptionalInputField
            name="company_name"
            fieldName={I18n.t("inquiry_preview.company_name")}
            fieldValue={draftOffer.company_name}
            onChange={this.onOfferChange}
            isEnabled={draftOffer.represents_company && draftOffer.company_name}
          />
          <br />
          <InputField
            name="artist_name"
            fieldName={I18n.t("inquiry_preview.artist_name")}
            fieldValue={draftOffer.artist_name}
            onChange={this.onOfferChange}
            fieldError={errors.artist_name}
          />
          <InputField
            name="artist_service"
            fieldName={I18n.t("inquiry_preview.artistic_service")}
            fieldValue={draftOffer.artist_service}
            onChange={this.onOfferChange}
          />
          <br />
          <InputField
            name="kind_of_moment"
            fieldName={I18n.t("inquiry_preview.kind_of_moment")}
            fieldValue={draftOffer.kind_of_moment}
            onChange={this.onOfferChange}
            fieldError={errors.kind_of_moment}
          />
          <InputField
            name="where"
            fieldName={I18n.t("inquiry_preview.where")}
            fieldValue={draftOffer.where}
            onChange={this.onOfferChange}
            fieldError={errors.where}
          />
          {inquiry["visual?"] ? (
            <div>
              <InputField
                name="when"
                fieldName={I18n.t("inquiry_preview.project_deadline")}
                fieldValue={draftOffer.when}
                onChange={this.onOfferChange}
                fieldError={errors.when}
              />
              <InputField
                name="surface_size"
                fieldName={I18n.t("inquiry_preview.surface_size")}
                fieldValue={draftOffer.surface_size}
                onChange={this.onOfferChange}
                fieldError={errors.surface_size}
              />
            </div>
          ) : (
            <div>
              <InputField
                name="when"
                fieldName={I18n.t("inquiry_preview.date_of_event")}
                fieldValue={draftOffer.when}
                onChange={this.onOfferChange}
                fieldError={errors.when}
              />
              <InputField
                name="show_beginning"
                fieldName={I18n.t("inquiry_preview.desired_starting_time")}
                fieldValue={draftOffer.show_beginning}
                onChange={this.onOfferChange}
                fieldError={errors.show_beginning}
              />
              <InputField
                name="show_duration"
                fieldName={I18n.t("inquiry_preview.desired_duration")}
                fieldValue={draftOffer.show_duration}
                onChange={this.onOfferChange}
                fieldError={errors.show_duration}
              />
            </div>
          )}
          <TextAreaField
            name="specific"
            fieldName={I18n.t("inquiry_preview.details")}
            fieldValue={draftOffer.specific}
            placeholder={I18n.t(
              "negotiation_dashboard.artist.description_placeholder"
            )}
            onChange={this.onOfferChange}
            fieldError={errors.specific}
            maxLength={OFFER_SPECIFIC_CHAR_LIMIT}
          />

          <FinalAmount
            totalPrice={
              computedPricing.total_gross_booking_value_with_extra_costs
            }
            currency={draftOffer.currency}
          />

          <div className="row">
            <div className="col-xs-12" style={{ paddingTop: 30 }}>
              <CancellationPolicyField
                cancellation_policy_option={
                  draftOffer.cancellation_policy_option
                }
                cancellation_policy={draftOffer.cancellation_policy}
                updateDraftOffer={updateDraftOffer}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 b-artist-offer__review">
              {I18n.t("negotiation_dashboard.artist.please_review")}
            </div>
          </div>
          {error && (
            <div className="row">
              <div className="col-xs-12 b-artist-offer__error">{error}</div>
            </div>
          )}
          <div className="row">
            <div className="col-xs-12 b-artist-offer__error">
              <button
                disabled={isSaving}
                onClick={this.onValidateOffer}
                className={`b-artist-offer__send b-button ${
                  isSending ? "b-button--loading" : ""
                }`}
              >
                {I18n.t("negotiation_dashboard.artist.send_to_client")}
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

ArtistOffer.propTypes = {
  context: ContextPropTypes.isRequired,
};

module.exports = withArtistDashboardContext(ArtistOffer);
