// eslint-disable-next-line import/prefer-default-export
export function stopVideos(element) {
  const container = element || document;

  container.querySelectorAll('iframe').forEach((el) => {
    const srcCopy = el.src;

    // eslint-disable-next-line no-param-reassign
    el.src = srcCopy.replace('autoplay=1', 'autoplay=0');
  });
}
