const React = require('react');
const PropTypes = require('prop-types');

const ExtraCostField = ({
  name,
  value,
}) => (
  <div className="row">
    <div className="col-xs-7 col-xs-offset-1 qa-field-extra-cost">
      <div className="b-artist-pricing__hint-field">{name}</div>
    </div>

    <div className="col-xs-4 qa-field-value">
      <div className="b-artist-pricing__hint-field">{value}</div>
    </div>
  </div>
);

ExtraCostField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

module.exports = ExtraCostField;
