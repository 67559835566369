const React = require('react');
const PropTypes = require('prop-types');
const InquiryPreview = require('../dashboard/containers/inquiry_preview');
const Step = require('../dashboard/step');

const Show = (props) => {
  const {
    inquiry,
    forwardedInquiry,
    artisticService,
    acceptPath,
    rejectPath,
  } = props;

  return (
    <div className="b-negotiation">
      <Step
        title={I18n.t('negotiation_dashboard.artist.title')}
        number={1}
        folded={false}
        blocked
      >
        <InquiryPreview
          inquiry={inquiry}
          artisticService={artisticService}
          forwardedInquiry={forwardedInquiry}
        />
        <div className="b-negotiation__button-wrapper">
          <a className="b-button b-button--inverse" href={rejectPath}>{I18n.t('forwarded_inquiries.reject')}</a>
          <a className="b-button" href={acceptPath}>{I18n.t('forwarded_inquiries.accept')}</a>
        </div>
      </Step>
    </div>
  );
};

Show.propTypes = {
  inquiry: PropTypes.object.isRequired,
  forwardedInquiry: PropTypes.object.isRequired,
  artisticService: PropTypes.object.isRequired,
  acceptPath: PropTypes.string.isRequired,
  rejectPath: PropTypes.string.isRequired,
};

module.exports = Show;
