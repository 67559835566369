const Slugger = require('./slugger');

module.exports = function anchorizeContents(contentNode) {
  const slugger = new Slugger();

  $(contentNode).find('h1, h2, h3, h4, h5, h6').toArray().forEach((node) => {
    const anchor = slugger.slugFor($(node).text());
    $(node).wrap(`<a id="${anchor}" href="#${anchor}"></a>"`);
  });
};
