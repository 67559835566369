import { Controller } from 'stimulus';

export default class extends Controller {
  // eslint-disable-next-line class-methods-use-this
  onClick(e) {
    e.preventDefault();

    const target = (e.target.tagName === 'A') ? e.target : e.target.closest('a');

    target.dispatchEvent(new CustomEvent('loadSmartInquiry', { bubbles: true }));
  }
}
