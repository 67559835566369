// TODO: use `pt` when available
import { enUS, de, ptBR } from 'date-fns/locale';

// eslint-disable-next-line import/prefer-default-export
export function dateFnsLocale() {
  return {
    en: enUS,
    de,
    pt: ptBR,
  }[I18n.locale] || enUS;
}
