const Cookies = require('js-cookie');

const setupCookiesBar = () => {
  $('.js-cookies-accept').click(() => {
    $('.js-cookies-bar').remove();
    Cookies.set('allow_cookies', 'true', { expires: 365 });
  });
};

document.addEventListener('turbolinks:load', setupCookiesBar);
