const React = require('react');
const PropTypes = require('prop-types');
const OfferShowField = require('./show_field');

function BookingInfo({
  artist_name,
  artist_service,
  when,
  where,
  specific,
  show_beginning,
  show_duration,
  surface_size,
  isVisual,
  artistic_profile_url,
  artistic_service_url,
}) {
  return (
    <div className="basa-bookingInfo">
      <OfferShowField
        fieldName={I18n.t('offer.artist_name')}
      >
        {artistic_profile_url && <a href={artistic_profile_url}>{artist_name}</a>}
        {!artistic_profile_url && <span>{artist_name}</span>}
      </OfferShowField>
      <OfferShowField
        fieldName={I18n.t('offer.service_name')}
      >
        {artistic_service_url && <a href={artistic_service_url}>{artist_service}</a>}
        {!artistic_service_url && <span>{artist_service}</span>}
      </OfferShowField>
      <OfferShowField
        fieldName={isVisual ? I18n.t('offer.deadline') : I18n.t('offer.when')}
        fieldValue={when}
        className="value-light"
      />
      <OfferShowField
        fieldName={I18n.t('offer.where')}
        fieldValue={where}
        className="value-light"
      />
      <OfferShowField
        isEnabled={!!show_beginning}
        fieldName={I18n.t('offer.desired_starting_time')}
        fieldValue={show_beginning}
        className="value-light"
      />
      <OfferShowField
        isEnabled={!!show_duration}
        fieldName={I18n.t('offer.desired_duration')}
        fieldValue={show_duration}
        className="value-light"
      />
      <OfferShowField
        isEnabled={!!surface_size}
        fieldName={I18n.t('offer.surface_size')}
        fieldValue={surface_size}
        className="value-light"
      />
      <OfferShowField
        fieldName={I18n.t('offer.specifications')}
        fieldValue={specific}
        className="value-light value-wrapped"
      />
    </div>
  );
}

BookingInfo.propTypes = {
  artist_name: PropTypes.string.isRequired,
  artist_service: PropTypes.string.isRequired,
  when: PropTypes.string.isRequired,
  where: PropTypes.string.isRequired,
  specific: PropTypes.string.isRequired,
  show_beginning: PropTypes.string,
  show_duration: PropTypes.string,
  surface_size: PropTypes.string,
  isVisual: PropTypes.bool,
  artistic_profile_url: PropTypes.string,
  artistic_service_url: PropTypes.string,
};

BookingInfo.defaultProps = {
  show_beginning: null,
  show_duration: null,
  surface_size: null,
  isVisual: false,
  artistic_profile_url: null,
  artistic_service_url: null,
};

module.exports = BookingInfo;
