const lozad = require('lozad');

const initLozad = () => {
  const observer = lozad();
  observer.observe();
};

// This is a workaround to make it compatible with Google Optimize
// page hiding snippet. For some reason, lozad does not work if
// it is initialized after Google Optimize (check _gtm_head.html.erb)
if (!window.MutationObserver) {
  document.addEventListener('turbolinks:load', initLozad);
} else if (document.documentElement.classList.contains('async-hide')) {
  const observer = new MutationObserver((mutationsList, observer) => {
    if (!document.documentElement.classList.contains('async-hide')) {
      initLozad(); // init lozard once Google Optimize snippet removes the `async-hide` class
      document.addEventListener('turbolinks:load', initLozad);
      observer.disconnect();
    }
  });

  observer.observe(document.documentElement, {
    attributes: true,
    childList: false,
    subtree: false
  });
} else {
  document.addEventListener('turbolinks:load', initLozad);
}
