/* eslint-disable react/jsx-fragments */
const React = require("react");
const PropTypes = require("prop-types");
const BookingTotals = require("../booking_totals");
const BookingTotalsFull = require("../booking_totals_full");
const { formatCurrency } = require("../../../lib/currency");

function PartialTotals({ offer, extraCosts, skip_vat }) {
  const { gross_amount_artist_with_extra_costs, currency } = offer;

  const grossAmountArtistWithExtraCosts = formatCurrency(
    I18n.locale,
    currency,
    gross_amount_artist_with_extra_costs
  );

  return (
    <React.Fragment>
      {+gross_amount_artist_with_extra_costs === 0 ? (
        <BookingTotalsFull offer={offer} skip_vat={skip_vat} />
      ) : (
        <BookingTotals
          offer={offer}
          extraCosts={extraCosts}
          skip_vat={skip_vat}
        />
      )}
      {+gross_amount_artist_with_extra_costs > 0 && (
        <div className="row">
          <div className="col-sm-offset-5 col-sm-7 basa-offerField-value value-light">
            {I18n.t("offer.remaining_amount_description", {
              amount: grossAmountArtistWithExtraCosts,
            })}
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

PartialTotals.propTypes = {
  offer: PropTypes.object.isRequired,
  extraCosts: PropTypes.array.isRequired,
  skip_vat: PropTypes.bool,
};

PartialTotals.defaultProps = {
  skip_vat: false,
};

module.exports = PartialTotals;
