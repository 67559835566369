var React = require('react')
var PropTypes = require('prop-types')

function InputField({ name, fieldName, fieldValue, placeholder, onChange, fieldError, attributes, disabled = false }) {
  const hasError = fieldError && fieldError.length > 0
  const errorMessage = Array.isArray(fieldError) ? fieldError.join(', ') : fieldError

  const additionalAttributes = Array.isArray(attributes) ? attributes : [attributes]
  const joinedAttributes = additionalAttributes.map(a => `b-input-field__value--${a}`).join(' ')

  return (
    <div className="b-input-field__container">
      <div className="row b-input-field__value-container">
        <div className="col-xs-12">
          <div className="b-input-field__name">
            {fieldName}
          </div>
        </div>
        <div className="col-xs-12">
          <input
            name={name}
            placeholder={placeholder}
            disabled={disabled}
            className={`b-input-field__value ${joinedAttributes} ${hasError ? 'b-input-field__value--error' : ''}`}
            onChange={onChange}
            value={fieldValue || ""}
          />
        </div>
        <div className="col-xs-12 b-input-field__error">
          {errorMessage}
        </div>
      </div>
    </div>
  )
}

module.exports = InputField
