const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');

const TogglePill = ({
  onChange,
  labels,
  names,
  selectedValue,
}) => {
  return (
    <div className="b-toggle-pill">
      <button
        className={classNames('b-toggle-pill__left', { 'b-toggle-pill--selected': selectedValue === names[0] })}
        onClick={(e) => { e.preventDefault(); onChange(names[0]); }}
      >
        {labels[0]}
      </button>
      <button
        className={classNames('b-toggle-pill__right', { 'b-toggle-pill--selected': selectedValue === names[1] })}
        onClick={(e) => { e.preventDefault(); onChange(names[1]); }}
      >
        {labels[1]}
      </button>
    </div>
  );
};

TogglePill.propTypes = {
  onChange: PropTypes.func.isRequired,
  labels: PropTypes.array.isRequired,
  names: PropTypes.array.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

module.exports = TogglePill;
