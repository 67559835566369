const React = require('react');
const PropTypes = require('prop-types');

class Tips extends React.Component {
  renderTips() {
    const { tips } = this.props;

    return tips.map((tip_key) => {
      return (
        <li key={tip_key}>
          {I18n.t(`negotiation_dashboard.tips.${tip_key}`)}
        </li>
      );
    });
  }

  render() {
    const { faqPath, isMobile } = this.props;

    return (
      <div className={isMobile ? 'b-step__tips-mobile' : 'b-step__tips'}>
        <div className="b-step__tips__info-icon">i</div>
        <h4 className="b-step__tips__title">{I18n.t('negotiation_dashboard.tips.title')}</h4>
        <ul>{this.renderTips()}</ul>
        <p className="b-step__tips__find-more">
          <a href={faqPath} target="_blank" rel="noopener noreferrer">{I18n.t('negotiation_dashboard.tips.more')}</a>
        </p>
      </div>
    );
  }
}

Tips.propTypes = {
  tips: PropTypes.array.isRequired,
  faqPath: PropTypes.string.isRequired,
  isMobile: PropTypes.bool,
};

Tips.defaultProps = {
  isMobile: false,
};

module.exports = Tips;
