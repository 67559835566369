const React = require("react");
const Sentry = require("@sentry/browser");
const PropTypes = require("prop-types");

const Modal = require("../../../ui/Modal");

const CLOSE_CONTRACT_ACCEPTED_MODAL_DELAY = 3000;

class QuickContractModal extends React.Component {
  constructor(props) {
    super(props);
    const { contractAccepted } = this.props;

    this.state = {
      modalOpen: !contractAccepted,
      contractAcceptedModalOpen: false,
      agreementPoints: [],
    };

    this.closeModal = this.closeModal.bind(this);
    this.toggleCheckbox = this.toggleCheckbox.bind(this);
    this.acceptContract = this.acceptContract.bind(this);
    this.renderAgreementPoints = this.renderAgreementPoints.bind(this);
    this.renderContractAcceptedModal =
      this.renderContractAcceptedModal.bind(this);
  }

  componentDidMount() {
    const { contractAccepted } = this.props;
    if (contractAccepted) return;

    const agreementText = I18n.t(
      "negotiation_dashboard.quick_contract_modal.agreement_text"
    );
    const agreementPoints = agreementText.split("<ends>");
    const inputs = agreementPoints.reduce((acc, _point, index) => {
      acc[index] = false;
      return acc;
    }, {});

    this.setState((prevState) => ({ ...prevState, agreementPoints, inputs }));
  }

  closeModal() {
    this.setState((prevState) => ({
      ...prevState,
      modalOpen: false,
      contractAcceptedModalOpen: true,
    }));

    setTimeout(() => {
      this.setState((prevState) => ({
        ...prevState,
        modalOpen: false,
        contractAcceptedModalOpen: false,
      }));
    }, CLOSE_CONTRACT_ACCEPTED_MODAL_DELAY);
  }

  toggleCheckbox(index) {
    this.setState((prevState) => ({
      ...prevState,
      inputs: {
        ...prevState.inputs,
        [index]: !prevState.inputs[index],
      },
    }));
  }

  acceptContract() {
    const { bookingPublicArtistId } = this.props;
    $.ajax({
      type: "POST",
      url: `${bookingPublicArtistId}/artist_accept_contract`,
      data: {},
      success: () => {
        this.closeModal();
      },
      error: () => {
        Sentry.captureException(
          "API Error: Artist contract could not be accepted"
        );
      },
    });
  }

  renderAgreementPoints() {
    const { agreementPoints, inputs } = this.state;

    return (
      <div className="b-contract-modal__agreement">
        {agreementPoints.map((agreementPoint, index) => {
          const agreementPointKey = `point-${index}`;
          const inputChecked = inputs[index];
          return (
            <div
              className="b-contract-modal__agreement-wrapper"
              key={agreementPointKey}
            >
              <input
                type="checkbox"
                value={inputChecked}
                onChange={() => this.toggleCheckbox(index)}
                id={agreementPointKey}
                className="b-contract-modal__agreement-input"
              />
              <label
                className="b-contract-modal__agreement-label"
                htmlFor={agreementPointKey}
              >
                {agreementPoint}
              </label>
            </div>
          );
        })}
      </div>
    );
  }

  renderContractAcceptedModal() {
    const { contractAcceptedModalOpen } = this.state;

    return (
      <Modal isOpen={contractAcceptedModalOpen}>
        <div className="b-react-modal__title b-contract-modal__title">
          {I18n.t("negotiation_dashboard.contract_accepted_modal.title")}
        </div>
        <div className="b-contract-modal__thank-you-image" />
        <div className="b-react-modal__footer">
          <p
            className="b-contract-modal__description"
            dangerouslySetInnerHTML={{
              __html: I18n.t(
                "negotiation_dashboard.contract_accepted_modal.description_html"
              ),
            }}
          />
        </div>
      </Modal>
    );
  }

  render() {
    const { modalOpen, contractAcceptedModalOpen, inputs = {} } = this.state;

    const agreedToAll = Object.values(inputs).every((input) => !!input);

    if (!contractAcceptedModalOpen && !modalOpen) {
      return null;
    }

    if (contractAcceptedModalOpen) {
      return this.renderContractAcceptedModal();
    }

    return (
      <Modal
        isOpen={modalOpen}
        showCloseButton={false}
        extraClass="b-react-modal--fixed-height"
      >
        <div className="b-contract-modal foo">
          <div className="b-react-modal__title b-contract-modal__title">
            {I18n.t("negotiation_dashboard.quick_contract_modal.title")}
          </div>
          <p>
            {I18n.t("negotiation_dashboard.quick_contract_modal.description")}
          </p>
          {this.renderAgreementPoints()}
          <div className="b-react-modal__footer">
            <button
              className="b-button b-button--wide"
              type="button"
              onClick={this.acceptContract}
              disabled={!agreedToAll}
            >
              {I18n.t(
                "negotiation_dashboard.quick_contract_modal.agree_button"
              )}
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

QuickContractModal.propTypes = {
  contractAccepted: PropTypes.bool.isRequired,
  bookingPublicArtistId: PropTypes.string.isRequired,
};

module.exports = QuickContractModal;
