/* Usage:
<%= react_component('dashboard/containers/inquiry_preview', {
  inquiry: @inquiry.as_json(
    include: {
      pictures: { only: [:id], methods: [:original_url] }
    },
    methods: [:visual?]
  )
}) %>
*/

const React = require('react');
const PropTypes = require('prop-types');
const HorizontalField = require('../HorizontalField');
const { formatDate } = require('../../../lib/datetime_formatters');

const picturesToUrls = pictures => (
  <div>
    {pictures.map((picture, index) => (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={picture.original_url}
        className="b-inquiry-preview__photo-url"
        key={picture.id}
      >
        photo{index + 1}
      </a>
    ))}
  </div>
);

const InquiryPreview = ({
  inquiry,
  artisticService,
  forwardedInquiry,
  isArtist,
}) => {
  const photos = inquiry.pictures.length > 0 ? picturesToUrls(inquiry.pictures) : null;

  return (
    <div className="b-inquiry-preview">
      <HorizontalField
        fieldName={I18n.t('inquiry_preview.where')}
        fieldValue={inquiry.city}
        highlightValue
        optional
      />
      <HorizontalField
        fieldName={I18n.t('inquiry_preview.client_name')}
        fieldValue={inquiry.full_name}
      />
      <HorizontalField
        fieldName={I18n.t('inquiry_preview.company_name')}
        fieldValue={inquiry.company_name}
        optional
      />
      {inquiry.kind_of_moment &&
        <HorizontalField
          fieldName={I18n.t('inquiry_preview.kind_of_moment')}
          fieldValue={I18n.t(`kinds_of_moment.${inquiry.kind_of_moment}`)}
          optional
        />
      }
      {artisticService &&
        <HorizontalField
          fieldName={I18n.t('inquiry_preview.artistic_service')}
          fieldValue={artisticService.name}
        />
      }
      {inquiry.surface_deadline &&
        <HorizontalField
          fieldName={I18n.t('inquiry_preview.project_deadline')}
          fieldValue={formatDate(inquiry.surface_deadline)}
          optional
          highlightValue
        />
      }
      <HorizontalField
        fieldName={I18n.t('inquiry_preview.photos')}
        fieldValue={photos}
        optional
      />
      <HorizontalField
        fieldName={I18n.t('inquiry_preview.surface_size')}
        fieldValue={inquiry.surface_size}
        optional
      />
      <HorizontalField
        fieldName={I18n.t('inquiry_preview.dimension')}
        fieldValue={inquiry.dimension && I18n.t(`dimensions.${inquiry.dimension}`)}
        optional
      />
      <HorizontalField
        fieldName={I18n.t('inquiry_preview.dimensions_description')}
        fieldValue={inquiry.dimensions_description}
        optional
      />
      {inquiry.date &&
        <HorizontalField
          fieldName={I18n.t('inquiry_preview.date_of_event')}
          fieldValue={formatDate(inquiry.date)}
          optional
          highlightValue
        />
      }
      {inquiry.occasion_date &&
        <HorizontalField
          fieldName={I18n.t('inquiry_preview.date_of_event')}
          fieldValue={formatDate(inquiry.occasion_date)}
          optional
          highlightValue
        />
      }
      {inquiry.occasion_time && (
        <HorizontalField
          fieldName={I18n.t('inquiry_preview.desired_starting_time')}
          fieldValue={`${formatDate(inquiry.occasion_time, 'HH:mm', 'UTC')} h`}
          optional
          highlightValue
        />
      )}
      <HorizontalField
        fieldName={I18n.t('inquiry_preview.desired_starting_time')}
        fieldValue={inquiry.show_beginning}
        optional
      />
      {!(inquiry.duration === null || inquiry.duration === undefined) &&
        inquiry.duration > 0 &&
        <HorizontalField
          fieldName={I18n.t('inquiry_preview.desired_duration')}
          fieldValue={I18n.t(`smart_form.inquiry_durations.${inquiry.duration}`)}
          optional
        />
      }
      <HorizontalField
        fieldName={I18n.t('inquiry_preview.desired_duration')}
        fieldValue={inquiry.show_duration}
        optional
      />
      <HorizontalField
        fieldName={I18n.t('inquiry_preview.guests_count')}
        fieldValue={inquiry.guests_count}
        optional
      />
      <HorizontalField
        fieldName={I18n.t('inquiry_preview.environment')}
        fieldValue={inquiry.environment}
        optional
      />
      {!(inquiry.sound_system === undefined || inquiry.sound_system === null) &&
        <HorizontalField
          fieldName={I18n.t('inquiry_preview.sound_system')}
          fieldValue={inquiry.sound_system ? I18n.t('inquiry_preview.sound_system_yes') : I18n.t('inquiry_preview.sound_system_no')}
        />
      }
      {!(inquiry.stage === undefined || inquiry.stage === null) &&
        <HorizontalField
          fieldName={I18n.t('inquiry_preview.stage')}
          fieldValue={inquiry.stage ? I18n.t('inquiry_preview.stage_yes') : I18n.t('inquiry_preview.stage_no')}
        />
      }
      {inquiry.original_songs &&
        <HorizontalField
          fieldName={I18n.t('inquiry_preview.original_songs')}
          fieldValue={I18n.t(`inquiry_preview.songs_preference.${inquiry.original_songs}`)}
        />
      }
      <HorizontalField
        fieldName={I18n.t('inquiry_preview.additional_booking_details')}
        fieldValue={forwardedInquiry.description}
        optional
      />
      <HorizontalField
        fieldName={I18n.t('inquiry_preview.other_requests')}
        fieldValue={forwardedInquiry.additional_description}
        optional
      />
      <HorizontalField
        fieldName={I18n.t('inquiry_preview.estimated_budget')}
        fieldValue={inquiry.price_range || I18n.t('inquiry_preview.not_provided')}
        highlightValue={!!inquiry.price_range}
        tip={isArtist ? I18n.t('negotiation_dashboard.tips.artist.max_budget') : ''}
      />
      <HorizontalField
        fieldName={I18n.t('inquiry_preview.extra_info')}
        fieldValue={forwardedInquiry.message}
        italicValue
        optional
      />
    </div>
  );
};

InquiryPreview.propTypes = {
  inquiry: PropTypes.object.isRequired,
  forwardedInquiry: PropTypes.object.isRequired,
  artisticService: PropTypes.object,
  isArtist: PropTypes.bool,
};

InquiryPreview.defaultProps = {
  artisticService: null,
  isArtist: false,
};

module.exports = InquiryPreview;
