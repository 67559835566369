const React = require('react');
const PropTypes = require('prop-types');
const ButtonOptions = require('../../../ui/ButtonOptions');

const { CANCELLATION_POLICY_OPTIONS, CANCELLATION_POLICY_OPTIONS_WITH_CUSTOM } = require('../../../../domain/offer');

const CancellationPolicyField = ({
  cancellation_policy_option,
  cancellation_policy,
  updateDraftOffer,
}) => {
  const content = CANCELLATION_POLICY_OPTIONS.includes(cancellation_policy_option) ?
    I18n.t(`cancellation_policies.artist.${cancellation_policy_option}.content`) :
    cancellation_policy;

  const head = I18n.t('cancellation_policies.artist.head');
  const note_1 = I18n.t('cancellation_policies.artist.note_1');
  const fieldName = I18n.t('cancellation_policies.field_name');

  return (
    <div className="b-cancellation-field">
      <div className="b-cancellation-field__label">{fieldName}</div>
      <ButtonOptions
        options={CANCELLATION_POLICY_OPTIONS.map(value => (
          { name: I18n.t(`cancellation_policies.${value}`), value }
        ))}
        selectedValues={[cancellation_policy_option]}
        onChange={options => updateDraftOffer({ cancellation_policy_option: options[0] })}
        multi={false}
      />
      <div className="b-cancellation-field__content" style={{ padding: 20 }}>
        <p>{head}</p>
        <p dangerouslySetInnerHTML={{ __html: content }} />
        <p>{note_1}</p>
      </div>
    </div>
  );
};

CancellationPolicyField.propTypes = {
  cancellation_policy_option: PropTypes.oneOf(CANCELLATION_POLICY_OPTIONS_WITH_CUSTOM).isRequired,
  cancellation_policy: PropTypes.string,
  updateDraftOffer: PropTypes.func.isRequired,
};

CancellationPolicyField.defaultProps = {
  cancellation_policy: '',
};

module.exports = CancellationPolicyField;
