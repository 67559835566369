const React = require('react');

const Header = () => (
  <div className="b-admin__menu">
    <ul>
      <li>
        <a href="/admin">Admin Dashboard</a>
      </li>
      <li>
        <a href="/booking_status">Booking Status</a>
      </li>
      <li>
        <a href="/offers">Offers</a>
      </li>
      <li>
        <a href="/admin/invoices">Invoices</a>
      </li>
    </ul>
  </div>
);

module.exports = Header;