const React = require('react');
const PropTypes = require('prop-types');

const OfferShowField = ({
  fieldName,
  fieldValue,
  className,
  isEnabled,
  children,
}) => {
  if (!isEnabled) {
    return null;
  }

  return (
    <div className="row basa-offerField">
      <div className={`col-sm-5 basa-offerField-name ${className}`}>{fieldName}</div>
      {children && <div className={`col-sm-7 basa-offerField-value ${className}`}>{children}</div>}
      {!children && <div className={`col-sm-7 basa-offerField-value ${className}`}>{fieldValue}</div>}
    </div>
  );
};

OfferShowField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  fieldValue: PropTypes.string,
  className: PropTypes.string,
  isEnabled: PropTypes.bool,
  children: PropTypes.any,
};

OfferShowField.defaultProps = {
  className: '',
  isEnabled: true,
  fieldValue: undefined,
  children: undefined,
};

module.exports = OfferShowField;
