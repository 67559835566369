/*
 * Usage:
 *
 *   const request = new AsyncEndpointRequest({
 *     path: '/vat_validations',
 *     params: { vat: 'ES77123123W' },
 *   });
 *
 *   request.run().then((response) => {
 *     konsole.leg('success', response);
 *   }, (errors) => {
 *     konsole.leg('error', errors);
 *   });
 */

const pollingMax = 5;

class AsyncEndpointRequest {
  constructor(options) {
    this.path = options.path;
    this.params = options.params;
    this.pollingCount = 0;
    this.aborted = false;
  }

  sendPOSTRequest(path, params, onload) {
    const token = document.querySelectorAll('meta[name="csrf-token"]');
    const xhr = new XMLHttpRequest();

    xhr.open('POST', path);
    xhr.setRequestHeader('Content-Type', 'application/json');

    if (token.length) {
      xhr.setRequestHeader('X-CSRF-Token', token[0].getAttribute('content'));
    }

    xhr.onload = () => onload(xhr);
    xhr.send(JSON.stringify(params));

    this.xhr = xhr;
  }

  pollingRequest(job_token, resolve, reject) {
    if (this.aborted || this.pollingCount > pollingMax) {
      reject({ errors: 'Validation timeout' });
      return;
    }

    this.pollingCount += 1;

    this.sendPOSTRequest(this.path, { job_token }, (xhr) => {
      const response = JSON.parse(xhr.responseText);

      if (xhr.status === 200) {
        resolve(response);
      } else if (xhr.status === 202) {
        setTimeout(() => this.pollingRequest(job_token, resolve, reject), 2000);
      } else {
        reject(response.errors);
      }
    });
  }

  run() {
    const promise = new Promise((resolve, reject) => {
      this.sendPOSTRequest(this.path, this.params, (xhr) => {
        const response = JSON.parse(xhr.responseText);

        if (xhr.status === 202) {
          setTimeout(() => this.pollingRequest(response.job_token, resolve, reject), 1000);
        } else {
          reject(response.errors);
        }
      });
    });

    return promise;
  }

  abort() {
    if (this.xhr) {
      this.xhr.abort();
    }

    this.aborted = true;
  }
}

module.exports = AsyncEndpointRequest;
