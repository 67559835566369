const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');

const CheckIcon = require('../Icons/CheckIcon').default;
const ErrorIcon = require('../Icons/ErrorIcon').default;

const MultiInput = ({
  label,
  names,
  values,
  placeholders,
  widths,
  onChange,
  error,
  validated,
  separator,
  type,
}) => {
  const hasError = !!error;

  const updateValue = (index, value) => {
    const newValues = values.slice();

    newValues[index] = value;

    onChange(newValues);
  };

  return (
    <div className={classNames('b-input', { 'b-input--error': hasError })}>
      <div className="b-input__top">
        <div
          className="b-input__label"
        >
          {label}
        </div>
        {hasError && <div className="b-input__error">{error}</div>}
      </div>
      <div className="b-input__input-wrapper-multi">
        {values.map((value, index) => (
          <React.Fragment key={names[index]}>
            <input
              type={type}
              className={classNames('b-input__input', { 'b-input__input--empty': !value })}
              value={value || ''}
              placeholder={placeholders[index]}
              name={names[index]}
              onChange={e => updateValue(index, e.target.value)}
              style={{ width: `${widths[index]}%` }}
            />
            {separator && (index < (values.length - 1)) && <div className="b-input__separator">{separator}</div>}
          </React.Fragment>
        ))}
        {hasError && <div className="b-input__icon"><ErrorIcon /></div>}
        {!hasError && validated && <div className="b-input__icon"><CheckIcon /></div>}
      </div>
    </div>
  );
};

MultiInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  values: PropTypes.array.isRequired,
  names: PropTypes.array.isRequired,
  widths: PropTypes.array.isRequired,
  placeholders: PropTypes.array,
  label: PropTypes.string,
  validated: PropTypes.bool,
  error: PropTypes.string,
  separator: PropTypes.string,
  type: PropTypes.string,
};

MultiInput.defaultProps = {
  label: undefined,
  error: undefined,
  validated: false,
  separator: undefined,
  placeholders: [],
  type: 'text',
};

module.exports = MultiInput;
