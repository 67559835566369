var React = require('react')
var PropTypes = require('prop-types')
var VimeoWidget = require('./vimeo_widget')
var YoutubeWidget = require('./youtube_widget')

class VideoSection extends React.Component {
  constructor(props) {
    super(props)
    this.handleAddVideo = this.handleAddVideo.bind(this)
    this.getYoutubeId = this.getYoutubeId.bind(this)
    this.parseVideoUrls = this.parseVideoUrls.bind(this)
  }

  handleAddVideo(event) {
    event.preventDefault()
    var newVideoUrls;
    var newVideo = this.refs.videoUrlTextfield.value
    if (this.getYoutubeId(newVideo) || this.getVimeoId(newVideo)) {
      if (!this.props.videoUrls) {
        newVideoUrls = newVideo
      } else {
        newVideoUrls = this.props.videoUrls + "|" + newVideo
      }
      this.props.onUpdate({ 'video_urls': newVideoUrls, 'errors': "" })
      this.refs.videoUrlTextfield.value = ""
    } else {
      this.props.onUpdate({ 'errors': I18n.t('artistic_services.errors.videos') })
    }
  }

  parseVideoUrls(urls) {
    return urls.split("|")
  }

  getYoutubeId(url) {
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);
    if (match && match[2].length == 11) {
      return match[2];
    } else {
      return null;
    }
  }

  getVimeoId(url) {
    var regExp = /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/;
    var match = url.match(regExp);
    if (match && match[3]) {
      return match[3]
    } else {
      return null;
    }
  }

  getVideoWindowSizes(numberOfVideos) {
    if (numberOfVideos == 1) {
      return [12]
    } else if (numberOfVideos == 2) {
      return [6, 6]
    } else if (numberOfVideos == 3) {
      return [6, 6, 12]
    } else {
      return [6, 6, 6, 6]
    }
  }

  handleRemoveVideo(index) {
    var urls = this.props.videoUrls.split("|")
    urls.splice(index, 1)
    this.props.onUpdate({ 'video_urls': urls.join("|") })
  }

  render() {
    var videoUrls = this.parseVideoUrls(this.props.videoUrls)
    var windowSizes = this.getVideoWindowSizes(videoUrls.length)
    var videoWidgets = videoUrls.map((videoUrl, index) => {
      var youtubeId = this.getYoutubeId(videoUrl)
      var widget = youtubeId ? <YoutubeWidget youtubeId={youtubeId} removeVideo={() => { this.handleRemoveVideo(index) }} show={this.props.show} /> : null
      var vimeoId = this.getVimeoId(videoUrl)
      widget = vimeoId ? <VimeoWidget vimeoId={vimeoId} removeVideo={() => { this.handleRemoveVideo(index) }} show={this.props.show} /> : widget
      return (<div key={index} className={"service-video col-sm-" + windowSizes[index]}>
        {widget}
      </div>)
    })

    return (
      <div className="video-section">
        {!this.props.show && <div><div className="col-sm-8 col-sm-offset-2">
          <p>{I18n.t('artistic_services.add_videos')}</p>
        </div>
          <form onSubmit={this.handleAddVideo} className={"form-inline" + (this.props.errors ? " has-error" : "")}>
            <div className="col-sm-6 col-sm-offset-2">
              <input type="text" ref="videoUrlTextfield" className="form-control" />
            </div>
            <div className="col-sm-2">
              <button type="button" onClick={this.handleAddVideo} className="grey-round-button">{I18n.t('artistic_services.add')}</button>
            </div>
            {this.props.errors ?
              <div className="col-sm-8 col-sm-offset-2">
                <span className="error-block">{this.props.errors}</span>
              </div> : null
            }
          </form></div>
        }
        <div className={this.props.show ? "" : "col-sm-8 col-sm-offset-2"}>
          {videoWidgets}
        </div>
      </div>)
  }
}

module.exports = VideoSection
