const React = require('react');
const PropTypes = require('prop-types');

const OptionsBox = ({ title, children, style }) => {
  return (
    <div className="b-options-box" style={style}>
      <div className="b-options-box__title">{title}</div>
      <div className="b-options-box__content">
        {children}
      </div>
    </div>
  );
};

OptionsBox.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.object.isRequired,
  style: PropTypes.object,
};

OptionsBox.defaultProps = {
  style: null,
};

module.exports = OptionsBox;
