// eslint-disable-next-line import/prefer-default-export
export const sumValues = (extraCosts) => {
  return extraCosts.reduce((memo, { value, _destroy }) => {
    if (!_destroy) {
      // eslint-disable-next-line no-param-reassign
      memo += Number(value || 0);
    }

    return memo;
  }, 0);
};
