const { resourcePath } = require('./BaseResource');

const basePath = '/admin/invoices';

export function invoicesPath(action, params) {
  return resourcePath(basePath, action, params);
}

function create(params) {
  return $.ajax({
    type: 'POST',
    url: invoicesPath('index'),
    data: params,
  });
}

function update(id, params) {
  return $.ajax({
    type: 'PUT',
    url: invoicesPath('show', { id }),
    data: params,
  });
}

export function saveInvoice(id, params) {
  if (id) {
    return update(id, params);
  }

  return create(params);
}

export function deleteInvoice(id) {
  return $.ajax({
    type: 'DELETE',
    url: invoicesPath('show', { id }),
  });
}
