var React = require('react')
var PropTypes = require('prop-types')

class YoutubeWidget extends React.Component {
  constructor(props) {
    super(props)
    this.handleRemoveVideo = this.handleRemoveVideo.bind(this)
  }

  handleRemoveVideo(event) {
    event.preventDefault();
    this.props.removeVideo();
  }

  render() {
    var embed = null
    if (this.props.youtubeId) {
      var id = this.props.youtubeId
      embed = <iframe className="embed-reponsive-item" src={"https://www.youtube.com/embed/" + id + "?showinfo=0"} allowFullScreen></iframe>
    }
    return (
      <div className="embed-responsive embed-responsive-16by9">
        {!this.props.show && <a href="#" onClick={this.handleRemoveVideo} className="remove-video-icon" />}
        {embed}
      </div>
    );
  }
}

module.exports = YoutubeWidget
