const React = require('react');
const PropTypes = require('prop-types');
const Tooltip = require('rc-tooltip').default;

const Overlay = ({ text }) => (
  <span>{text}</span>
);

Overlay.propTypes = {
  text: PropTypes.string.isRequired,
};

const HelpTooltip = (props) => {
  const {
    text,
  } = props;

  return (
    <Tooltip
      placement="top"
      trigger={['click', 'hover']}
      overlay={<Overlay text={text} />}
      overlayClassName="rc-tooltip--help"
      arrowContent={<div className="rc-tooltip-arrow-inner" />}
      align={{ offset: [0, -8] }}
    >
      <button
        type="button"
        onClick={(e) => e.stopPropagation()}
        className="b-help-button"
      >
        i
      </button>
    </Tooltip>
  );
};

HelpTooltip.propTypes = {
  text: PropTypes.string.isRequired,
};

module.exports = HelpTooltip;
