var React = require('react')
var PropTypes = require('prop-types')

class VimeoWidget extends React.Component {
  constructor(props) {
    super(props)
    this.handleRemoveVideo = this.handleRemoveVideo.bind(this)
  }

  handleRemoveVideo(event) {
    event.preventDefault();
    this.props.removeVideo();
  }

  render() {
    var embed = this.props.vimeoId ? <iframe src={"//player.vimeo.com/video/" + this.props.vimeoId + "?title=0&byline=0&badge=0"} frameBorder="0" allowFullScreen /> : null
    return (
      <div className="embed-responsive embed-responsive-16by9">
        {!this.props.show && <a href="#" onClick={this.handleRemoveVideo} className="remove-video-icon" />}
        {embed}
      </div>
    );
  }
}

module.exports = VimeoWidget
