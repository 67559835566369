import { Controller } from 'stimulus';

require('slick-carousel');

export default class extends Controller {
  static get targets() {
    return ['sliderItems', 'arrowLeft', 'arrowRight'];
  }

  connect() {
    let options = {
      mobileFirst: true,
      infinite: true,
      slidesToShow: 1,
      variableWidth: false,
      dots: true,
      arrows: false,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows: true,
            dots: true,
            slidesToShow: 1,
          },
        },
      ],
    };

    if (this.hasArrowLeftTarget && this.hasArrowRightTarget) {
      options = Object.assign(options, {
        prevArrow: this.arrowLeftTarget,
        nextArrow: this.arrowRightTarget,
      });
    }

    $(this.sliderItemsTarget).slick(options);
  }

  disconnect() {
    if (this.hasSliderItemsTarget) {
      $(this.sliderItemsTarget).slick('unslick');
    }
  }
}
