const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');

function PaymentActionsController({
  isProcessing,
  handleBackButton,
  handleSubmit,
}) {
  return (
    <div className="b-payment__buttons">
      <button className={classNames('b-button', { 'b-button--loading': isProcessing })} onClick={handleSubmit}>
        {I18n.t('payment_process.payment_method.next')}
      </button>
      <button className="b-button b-button--inverse" type="button" onClick={handleBackButton}>
        {I18n.t('payment_process.payment_method.back')}
      </button>
    </div>
  );
}

PaymentActionsController.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
  handleBackButton: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

module.exports = PaymentActionsController;
