const React = require("react");
const PropTypes = require("prop-types");
const BookingTotalsField = require("./booking_totals_field");
const BookingTotalsGrossArtist = require("./booking_totals_gross_artist");
const { formatCurrency } = require("../../lib/currency");

const BookingTotals = ({ offer, extraCosts, skip_vat }) => {
  const {
    gross_amount_basa,
    vat_percent_basa,
    vat_amount_basa,
    total_gross_booking_value_with_extra_costs,
    currency,
  } = offer;

  const totalGrossBookingValueWithExtraCosts = formatCurrency(
    I18n.locale,
    currency,
    total_gross_booking_value_with_extra_costs
  );
  const grossAmountBasa = formatCurrency(
    I18n.locale,
    currency,
    gross_amount_basa
  );
  const vatAmountBasa = formatCurrency(
    I18n.locale,
    currency,
    skip_vat ? 0 : vat_amount_basa
  );

  return (
    <div className="basa-offersShow-totals">
      <BookingTotalsField
        className="value-light"
        fieldName={I18n.t("offer.total_booking_value")}
        fieldValue={totalGrossBookingValueWithExtraCosts}
      />
      <BookingTotalsField
        className="name-dark"
        fieldName={I18n.t("offer.amount_due_now")}
        fieldValue={grossAmountBasa}
      />
      <BookingTotalsField
        className="value-light qa-booking-totals-vat"
        fieldName={I18n.t("offer.vat", {
          vat: skip_vat ? 0 : vat_percent_basa,
        })}
        fieldValue={vatAmountBasa}
        smallHint={I18n.t("offer.reverse_deduction_hint")}
      />
      {!!extraCosts && (
        <BookingTotalsGrossArtist offer={offer} extraCosts={extraCosts} />
      )}
    </div>
  );
};

BookingTotals.propTypes = {
  offer: PropTypes.object.isRequired,
  extraCosts: PropTypes.array.isRequired,
  skip_vat: PropTypes.bool,
};

BookingTotals.defaultProps = {
  skip_vat: false,
};

module.exports = BookingTotals;
