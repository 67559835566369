const React = require('react');
const PropTypes = require('prop-types');

const initialState = {
  category: '',
  location: '',
  variant: null,
};

class HomepageEntities extends React.Component {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  componentDidMount() {
    const {
      experimentId,
    } = this.props;

    if (experimentId) {
      if (window.dataLayer) {
        window.dataLayer.push({ event: 'optimize.activate' });
      }
      this.intervalId = setInterval(() => {
        if (window.google_optimize !== undefined) {
          const variant = window.google_optimize.get(experimentId);
          this.setState({ variant });
          clearInterval(this.intervalId);
        }
      }, 100);
    }
  }

  render() {
    const {
      variant,
    } = this.state;

    if (variant !== '1') {
      return null;
    }

    const locale = I18n.locale !== 'en' ? `/${I18n.locale}` : '';
    const url = `${locale}/search`;

    return (
      <div className="b-he">
        <div className="b-he__section b-he__section--private">
          <h3 className="b-he__title">
            {I18n.t('homepage_entities.private.title')}
          </h3>
          <div className="b-he__description">
            {I18n.t('homepage_entities.private.description')}
          </div>
          <a
            id="homepage-entity-private"
            href={url}
            className="b-button b-button--primary b-he__cta"
            onClick={() => { hj('tagRecording', ['homepage_entity_private_click']); }}
          >
            {I18n.t('homepage_entities.private.cta')}
          </a>
        </div>
        <div className="b-he__section b-he__section--company">
          <h3 className="b-he__title">
            {I18n.t('homepage_entities.company.title')}
          </h3>
          <div className="b-he__description">
            {I18n.t('homepage_entities.company.description')}
          </div>
          <a
            id="homepage-entity-company"
            href={url}
            className="b-button b-button--red b-he__cta"
            onClick={() => { hj('tagRecording', ['homepage_entity_company_click']); }}
          >
            {I18n.t('homepage_entities.company.cta')}
          </a>
        </div>
      </div>
    );
  }
}

HomepageEntities.defaultProps = {
  experimentId: null,
};

HomepageEntities.propTypes = {
  experimentId: PropTypes.any,
};

export default HomepageEntities;
