import { Controller } from 'stimulus';
import { stopVideos } from 'lib/stopVideo';

export default class extends Controller {
  static get targets() {
    return ['extraContent'];
  }

  toggle() {
    const expanded = !(this.data.get('expanded') === 'true');
    this.data.set('expanded', expanded);

    if (expanded) {
      this.extraContentTarget.style.display = 'flex';
    } else {
      this.extraContentTarget.style.display = 'none';
      stopVideos(this.extraContentTarget);
    }
  }

  connect() {
  }

  disconnect() {
  }
}
