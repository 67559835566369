/* eslint-disable import/first */
/* eslint-disable import/order */
const React = require('react');
const PropTypes = require('prop-types');
const BookingInfo = require('../../offers/booking_info');
const CancellationPolicy = require('../../CancellationPolicy');
const FullTotals = require('./full_totals');
const PartialTotals = require('./partial_totals');

class BookingData extends React.Component {
  constructor(props) {
    super(props);

    this.handleNextClick = this.handleNextClick.bind(this);
  }

  handleNextClick() {
    this.props.onNext();
  }

  render() {
    const {
      offer,
      inquiry,
      extraCosts,
    } = this.props;
    const {
      cancellation_policy,
      cancellation_policy_option,
    } = offer;

    return (
      <div className="container">
        <div className="row">
          <div className="col-sm-8 col-sm-offset-2">
            <div className="b-payment__header">
              <h1>{I18n.t('payment_process.booking_data.title')}</h1>
              <p>{I18n.t('payment_process.booking_data.subtitle')}</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3">
            <BookingInfo {...offer} isVisual={inquiry['visual?']} />

            <div className="b-payment__blue-box">
              {offer.is_full_payment === true ? (
                <FullTotals offer={offer} />
              ) : (
                <PartialTotals offer={offer} extraCosts={extraCosts} />
              )}
            </div>
            <div className="row">
              <div className="col-sm-12 u-light-font">
                <CancellationPolicy
                  content={cancellation_policy}
                  option={cancellation_policy_option}
                  pov="client"
                  showNotes={!offer.is_full_payment}
                  amountDue={offer.gross_amount_basa}
                />
              </div>
            </div>
            <p className="b-payment__info-text">
              {I18n.t('payment_process.booking_data.vat_info')}
            </p>
            <div>
              <button
                className="b-button b-button--wide"
                onClick={this.handleNextClick}
              >
                {I18n.t('payment_process.booking_data.cta_button')}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

BookingData.propTypes = {
  onNext: PropTypes.func.isRequired,
  offer: PropTypes.object.isRequired,
  inquiry: PropTypes.object.isRequired,
};

module.exports = BookingData;
