import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import Button from '../../../../components/ui/Button/';
import Modal from '../../../../components/ui/Modal/';

import Confirm from './confirm';
import Survey from './survey';
import Message from './message';
import Thanks from './thanks';

const steps = {
  artist: [
    'confirm',
    'survey',
    'message',
    'thanks',
  ],
  client: [
    'confirm',
    'survey',
    'thanks',
  ],
};

const stepBy = ({ index, authorType }) => steps[authorType][index];

class BookingCancelation extends Component {
  state = {
    isModalOpen: false,
    didCancel: false,
    currentStepIndex: -1,
  }

  componentDidUpdate() {
    if (!this.isInLastStep()) {
      return;
    }

    window.setTimeout(() => window.location.reload(), 5000);
  }

  onClickCancel = () => this.openModal()

  onConfirm = ({ wantsRecommendations }) => {
    this.setState({ didCancel: true });

    $.ajax({
      url: this.props.cancelationPath,
      type: 'POST',
      dataType: 'json',
      contentType: 'application/json',
      data: JSON.stringify({ wants_recommendations: wantsRecommendations }),
    }).then(() => {
      this.advanceStep();
    });
  }

  onSubmitSurvey = ({ reason, optional }) => {
    $.ajax({
      url: this.props.cancelationPath,
      type: 'PUT',
      contentType: 'application/json',
      data: JSON.stringify({ reason, optional }),
      dataType: 'json',
    }).then(() => {
      this.advanceStep();
    });
  }

  onLeaveMessage = ({ message }) => {
    $.ajax({
      url: this.props.cancelationPath,
      type: 'put',
      contentType: 'application/json',
      data: JSON.stringify({ message }),
      dataType: 'json',
    }).then(() => {
      this.advanceStep();
    });
  }

  onSkip = () => {
    const thanksStepIndex = steps[this.props.authorType].indexOf('thanks');

    this.setState({ currentStepIndex: thanksStepIndex });
  }

  openModal = () => this.setState({ isModalOpen: true, currentStepIndex: 0 })
  closeModal = () => {
    if (this.state.didCancel) {
      window.location.reload();
      return;
    }

    this.setState({ isModalOpen: false });
  }

  advanceStep = () => this.setState({ currentStepIndex: this.state.currentStepIndex + 1 })

  isInLastStep = () => this.state.currentStepIndex === (steps[this.props.authorType].length - 1)

  render() {
    const step = stepBy({
      index: this.state.currentStepIndex,
      authorType: this.props.authorType,
    });

    return (
      <Fragment>
        <Button inverse onClick={this.onClickCancel}>{I18n.t('negotiation_dashboard.cancelation.close_button')}</Button>
        <Modal
          isOpen={this.state.isModalOpen}
          onClose={this.closeModal}
        >
          <Fragment>
            {step === 'confirm' &&
              <Confirm
                authorType={this.props.authorType}
                onConfirm={this.onConfirm}
                onCancel={this.closeModal}
              />
            }
            {step === 'survey' &&
              <Survey
                reasons={this.props.reasons}
                onSubmitSurvey={this.onSubmitSurvey}
                onSkip={this.onSkip}
              />
            }
            {step === 'message' &&
              <Message
                onLeaveMessage={this.onLeaveMessage}
                onSkip={this.onSkip}
              />
            }
            {step === 'thanks' && <Thanks />}
          </Fragment>
        </Modal>
      </Fragment>
    );
  }
}

BookingCancelation.propTypes = {
  reasons: PropTypes.arrayOf(PropTypes.string).isRequired,
  authorType: PropTypes.string.isRequired,
  cancelationPath: PropTypes.string.isRequired,
};

export default BookingCancelation;
