const React = require('react');
const SelectBox = require('./select_box');

const options = {
  on_social_media: I18n.t('invitation_requests.new.options_1'),
  when_searching_on_internet: I18n.t('invitation_requests.new.options_2'),
  at_an_event: I18n.t('invitation_requests.new.options_3'),
  someone_recommended_it: I18n.t('invitation_requests.new.options_4'),
  invited_by_basa: I18n.t('invitation_requests.new.options_6'),
  other: I18n.t('invitation_requests.new.options_5'),
};

class InvitationRequestsSource extends React.Component {
  constructor() {
    super();

    this.state = {
      selectedOption: '',
      referral: '',
    };
  }

  _onReferralTextChange(event) {
    this.setState({
      referral: event.target.value,
    });
  }

  _onSelectChange(value) {
    this.setState({
      selectedOption: value,
    });
  }

  render() {
    return (
      <div className="form-group">
        <SelectBox
          label={I18n.t('invitation_requests.new.select_header')}
          options={options}
          selected={this.state.selectedOption}
          placeholder={I18n.t('invitation_requests.new.default')}
          onChangeValue={this._onSelectChange.bind(this)}
        />

        {this.state.selectedOption === 'someone_recommended_it' &&
          <div className='referral_source'>
            <label>{I18n.t('invitation_requests.new.referral_source_header')}</label>
            <input type="text" className="form-control" name="invitation_request[referral_source]" value={this.state.referral} onChange={this._onReferralTextChange.bind(this)} placeholder={I18n.t('invitation_requests.new.referral_source_placeholder_1')} />
          </div>
        }

        {this.state.selectedOption === 'other' &&
          <div className='referral_source'>
            <label>{I18n.t('invitation_requests.new.explain')}</label>
            <textarea className="form-control" name="invitation_request[referral_source]" value={this.state.referral} onChange={this._onReferralTextChange.bind(this)} placeholder={I18n.t('invitation_requests.new.referral_source_placeholder_2')} rows='5' />
          </div>
        }

        <input type="text" className="element--hidden" id="referral_option" name="invitation_request[referral_option]" readOnly value={this.state.selectedOption} />
      </div>
    );
  }
}

module.exports = InvitationRequestsSource
