var setupForwardedInquiryRejectionSurvey = function(){
  $(".small-budget-optional-fields").hide()

  $("input[name='survey[reason]']").change(function () {
    if ($("#survey_reason_small_budget").prop("checked")) {
      $(".small-budget-optional-fields").show()
    } else {
      $(".small-budget-optional-fields").hide()
    }
  })

  $("#new_survey").on("ajax:error", function (e, xhr, status, error) {
    if ($("#new_survey .b-survey__error").length == 0) {
      $("#new_survey .b-survey__button").before('<p class="b-survey__error">Please choose an option</p>')
    }
  })
}

document.addEventListener('turbolinks:load', setupForwardedInquiryRejectionSurvey)
