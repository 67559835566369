import React from 'react';
import Heading from '../../../../components/ui/Heading/';

const Thanks = () => (
  <div>
    <Heading text={I18n.t('shared.thank_you')} />
    <p className="text-center">{I18n.t('negotiation_dashboard.cancelation.thank_you.message')}</p>
  </div>
);

export default Thanks;
