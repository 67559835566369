const React = require('react');

const Header = () => (
  <header className="booksa-header">
    <div className="container">
      <div className="row">
        <div className="col-lg-8 col-lg-offset-2 col-md-10 col-md-offset-1">
          <div className="site-heading" />
        </div>
      </div>
    </div>
  </header>
);

module.exports = Header;
