/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */

import React from 'react';
import ReactDOM from 'react-dom';

const LazyComponents = {
  booking_status_index: React.lazy(() => import(/* webpackChunkName: "lc-booking-status-index" */ '../lazy_components/booking_status/index')),
  settings: React.lazy(() => import(/* webpackChunkName: "lc-settings-notifications" */ '../lazy_components/settings/index')),
  icn_category_visual: React.lazy(() => import(/* webpackChunkName: "lc-icons" */ '../lazy_components/icons/icn_category_visual')),
  icn_category_music: React.lazy(() => import(/* webpackChunkName: "lc-icons" */ '../lazy_components/icons/icn_category_music')),
  icn_category_perf: React.lazy(() => import(/* webpackChunkName: "lc-icons" */ '../lazy_components/icons/icn_category_perf')),
  icn_category_somethingelse: React.lazy(() => import(/* webpackChunkName: "lc-icons" */ '../lazy_components/icons/icn_category_somethingelse')),
};

const renderLazyComponents = () => {
  Object.keys(LazyComponents).forEach((name) => {
    const containers = document.querySelectorAll(`[data-lazy-react-class="${name}"]`);

    containers.forEach((container) => {
      const stringProps = container.getAttribute('data-lazy-react-props');
      const props = JSON.parse(stringProps);
      const Component = LazyComponents[name];

      ReactDOM.render((
        <React.Suspense fallback={<div />}>
          <Component {...props} />
        </React.Suspense>
      ), container);
    });
  });
};

document.addEventListener('turbolinks:load', renderLazyComponents);
