const React = require('react');
const PropTypes = require('prop-types');
const { saveAdminResponse } = require('../../../../resources/InvitationRequestResource');

const optionInfo = {
  general_yes: 'This will only send the "Next steps" email',
  geo_yes: 'This will send two emails: The "Next steps" email and the GEO Yes "template" from the Excel',
  capacity_yes: 'This will send two emails: The "Next steps" email and the CAPACITY Yes "template" from the Excel',
  manual_yes: 'This will only send the "Next steps" email, after the ARM can send an extra email to the artist',
};

const optionText = {
  general_yes: 'General YES',
  geo_yes: 'Geo YES',
  capacity_yes: 'Capacity YES',
  manual_yes: 'Manual YES',
};

const ApproveForm = (props) => {
  const [approval_option, setApprovalOption] = React.useState('general_yes');
  const [sending, setSending] = React.useState(false);
  const {
    admin_responses_yes,
    invitation_request_id,
  } = props;

  const onRespond = () => {
    setSending(true);
    saveAdminResponse(invitation_request_id, { admin_response: approval_option }).then(() => {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    });
  };

  const onSelect = (event) => {
    setApprovalOption(event.target.value);
  };

  return (
    <form style={{ marginTop: 20 }} className="qa-approve-form">
      <div className="form-group">
        <select onChange={onSelect} value={approval_option}>
          {admin_responses_yes.map(response => (
            <option value={response}>{optionText[response]}</option>
          ))}
        </select>
      </div>
      <p className="alert alert-info">{optionInfo[approval_option]}</p>
      <button
        className="btn btn-default"
        onClick={onRespond}
        disabled={sending}
      >
        Approve
      </button>
    </form>
  );
};

ApproveForm.propTypes = {
  invitation_request_id: PropTypes.number.isRequired,
  admin_responses_yes: PropTypes.array.isRequired,
};

module.exports = ApproveForm;
