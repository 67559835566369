const React = require('react');
const ArtisticServiceItemContainer = require('./containers/artistic_service_item_container');

class ArtisticServiceItemRecommendation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isExpanded: true,
      isSending: false,
    };
    this.onExpandChange = this.onExpandChange.bind(this);
    this.onInquire = this.onInquire.bind(this);
  }

  onExpandChange(isExpanded) {
    this.setState({
      isExpanded,
    });
  }

  onInquire() {
    this.setState({
      isSending: true,
    });

    $.ajax({
      url: this.props.cta_url,
      type: 'POST',
    });
  }

  render() {
    const {
      isExpanded,
      isSending,
    } = this.state;
    return (
      <ArtisticServiceItemContainer
        onExpandChange={this.onExpandChange}
        disableMoreInfo
        {...this.props}
        disableAnchor
        showLocation
        moreInfo
      >
        <button
          className={`b-button b-button--centered ${isExpanded ? 'b-button--primary' : 'b-button--inverse b-button--transparent'}`}
          onClick={this.onInquire}
          disabled={isSending}
          type="button"
        >
          { I18n.t('artistic_service_item.inquire') }
        </button>
      </ArtisticServiceItemContainer>
    );
  }
}

module.exports = ArtisticServiceItemRecommendation;
