/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/jsx-fragments */
import { Controller } from 'stimulus';
import React from 'react';
import ReactDOM from 'react-dom';

const contentElement = (onClose) => (
  <React.Fragment>
    <a
      href="#clients-section"
      onClick={onClose}
    >
      Clients
    </a>
    <a
      href="#about-us-section"
      onClick={onClose}
    >
      About Us
    </a>
    <a
      href="#our-services-section"
      onClick={onClose}
    >
      Our services
    </a>
    <a
      href="#how-it-works-section"
      onClick={onClose}
    >
      How it works
    </a>
    <a
      href="#cases-section"
      onClick={onClose}
    >
      Cases
    </a>
  </React.Fragment>
);

const slideout = (content, onClose) => (
  <React.Fragment>
    <button
      type="button"
      className="b2b__slideout-close"
      onClick={onClose}
    >
      X
    </button>
    <div className="b2b__slideout-content">
      {content}
    </div>
  </React.Fragment>
);

export default class extends Controller {
  static get targets() {
    return [];
  }

  onClose() {
    this.slideout.classList.remove('b2b__slideout-container--show');
    $(document).unbind('keydown.slideout');
  }

  connect() {
    this.slideout = document.querySelector('#slideout-container');

    if (!this.slideout) {
      this.slideout = document.createElement('div');
      this.slideout.classList.add('b2b__slideout-container');
      document.body.querySelector('main').appendChild(this.slideout);
    }

    const slideoutElement = slideout(
      contentElement(this.onClose.bind(this)),
      this.onClose.bind(this),
    );

    ReactDOM.render(slideoutElement, this.slideout);
  }

  disconnect() {
    $(document).unbind('keydown.slideout');
    const container = document.querySelector('#slideout-container');
    if (container) {
      document.body.querySelector('main').removeChild(container);
      this.slideout = null;
    }
  }

  open() {
    if (this.slideout) {
      this.slideout.classList.add('b2b__slideout-container--show');
      $(document).on('keydown.slideout', (e) => {
        if (e.key === 'Escape') {
          this.onClose();
        }
      });
    }
  }
}
