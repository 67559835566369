const React = require('react');
const GallerySection = require('./sections/gallery');
const VideoSection = require('./sections/video');
const AudioSection = require('./sections/audio');

class MoreInfo extends React.Component {
  renderSection(section) {
    const { type, id, pictures, video_urls, audio_urls } = section;
    const { alt } = this.props;

    if (type === 'GallerySection') {
      return <GallerySection key={id} alt={alt} pictures={pictures} />;
    } else if (type === 'YoutubeSection') {
      return <VideoSection key={id} videoUrls={video_urls} />;
    } else if (type === 'AudioSection') {
      return <AudioSection key={id} audioUrls={audio_urls} />;
    }

    return null;
  }

  renderSections() {
    const { sections } = this.props;

    return sections.map(section => this.renderSection(section));
  }

  render() {
    return (
      <div className="b-service__more-info">
        {this.renderSections()}
      </div>
    );
  }
}

module.exports = MoreInfo;
