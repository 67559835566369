const React = require("react");
const PropTypes = require("prop-types");
const classNames = require("classnames");

const currentClass = (value, selectedValue, small) => {
  return classNames(
    "b-radio-select__option",
    `qa-radio-select-value-${value}`,
    {
      "b-radio-select__option--selected": value === selectedValue,
      "b-radio-select__option--small": small,
    }
  );
};

const RadioSelect = ({ options, inline, small, selectedValue, onChange }) => {
  // TODO add keyboard navigation, improve a11y
  /* eslint-disable jsx-a11y/click-events-have-key-events */
  /* eslint-disable jsx-a11y/interactive-supports-focus */

  return (
    <div
      className={classNames("b-radio-select", {
        "b-radio-select--inline": inline,
        "b-radio-select--small": small,
      })}
    >
      {options.map((option) => (
        <div
          key={option.value}
          className={currentClass(option.value, selectedValue, small)}
          onClick={() => onChange(option.value)}
          role="button"
        >
          {option.icon && (
            <div className="b-radio-select__option__icon">
              <option.icon />
            </div>
          )}
          <div className="b-radio-select__option__text">{option.name}</div>
        </div>
      ))}
    </div>
  );
};

RadioSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  selectedValue: PropTypes.string,
  inline: PropTypes.bool,
  small: PropTypes.bool,
};

RadioSelect.defaultProps = {
  selectedValue: null,
  inline: false,
  small: false,
};

module.exports = RadioSelect;
