const React = require("react");
const PropTypes = require("prop-types");
const classNames = require("classnames");
const VerticalField = require("./vertical_field");
const { formatCurrency } = require("../../lib/currency");

const OfferAmountBreakdown = ({ offer, extraCosts, pov }) => {
  const [showBreakdown, setShowBreakdown] = React.useState(false);

  const toggleBreakdown = () => {
    setShowBreakdown(!showBreakdown);
  };

  const canShowBreakdown = pov === "artist";

  const totalGrossBookingValueWithExtraCosts = formatCurrency(
    I18n.locale,
    offer.currency,
    offer.total_gross_booking_value_with_extra_costs
  );
  const grossAmountBasa = formatCurrency(
    I18n.locale,
    offer.currency,
    offer.gross_amount_basa
  );
  const grossAmountArtistWithExtraCosts = formatCurrency(
    I18n.locale,
    offer.currency,
    offer.gross_amount_artist_with_extra_costs
  );

  return (
    <div className="b-offer__amount">
      <VerticalField
        name={I18n.t(`inquiry_preview.total_booking_value_${pov}`)}
        value={totalGrossBookingValueWithExtraCosts}
        nameClasses="b-offer__field--left b-offer__field--black"
        valueClasses="b-offer__field--bold b-offer__field--right"
      />
      {canShowBreakdown && (
        <button
          className={classNames("b-offer__toggle b-arrow_toggle", {
            "b-arrow-toggle--expanded": showBreakdown,
          })}
          onClick={toggleBreakdown}
        >
          {I18n.t("inquiry_preview.total_cost_breakdown")}
          <i className="b-arrow-toggle__arrow" />
        </button>
      )}
      {showBreakdown && (
        <div style={{ marginTop: 6 }}>
          <>
            <VerticalField
              name={I18n.t("inquiry_preview.basa_community_fee")}
              value={grossAmountBasa}
              nameClasses="b-offer__field--left b-offer__field--black"
              valueClasses="b-offer__field--right"
            />
            <VerticalField
              name={I18n.t("inquiry_preview.total_amount_you_receive")}
              value={grossAmountArtistWithExtraCosts}
              nameClasses="b-offer__field--left b-offer__field--black"
              valueClasses="b-offer__field--right"
            />
          </>
          {!!extraCosts.length && (
            <>
              <VerticalField name={I18n.t("inquiry_preview.extra_costs")} />
              {extraCosts.map(({ id, name, value }) => {
                const formattedValue = formatCurrency(
                  I18n.locale,
                  offer.currency,
                  value
                );
                return (
                  <VerticalField
                    key={id}
                    name={name}
                    value={formattedValue}
                    nameClasses="b-offer__field--left"
                    valueClasses="b-offer__field--right"
                  />
                );
              })}
            </>
          )}
        </div>
      )}
    </div>
  );
};

OfferAmountBreakdown.propTypes = {
  offer: PropTypes.object.isRequired,
  extraCosts: PropTypes.array.isRequired,
  pov: PropTypes.oneOf(["client", "artist"]).isRequired,
};

module.exports = OfferAmountBreakdown;
