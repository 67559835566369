const React = require("react");
const withArtistDashboardContext = require("./containers/artist_dashboard/with-artist-dashboard-context");
const HelpTooltip = require("../ui/HelpTooltip");
const { formatCurrency } = require("../../lib/currency");

function FinalAmount({ totalPrice, currency, context }) {
  const formattedPrice = formatCurrency(I18n.locale, currency, totalPrice);
  return (
    <div className="row b-final-amount__wrapper">
      <div className="col-xs-12">
        <div className="row" onClick={context.scrollToCalculator}>
          <div className="col-xs-6 b-final-amount__label">
            {I18n.t("negotiation_dashboard.artist.final_amount")}
            <span className="b-final-amount__tooltip">
              <HelpTooltip
                text={I18n.t("negotiation_dashboard.tips.artist.final_amount")}
              />
            </span>
          </div>
          <div className="col-xs-6 b-final-amount__value">
            <div className="text-right">{formattedPrice}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <div className="text-right b-final-amount__info">
              {I18n.t("negotiation_dashboard.artist.total_gross_vat")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

module.exports = withArtistDashboardContext(FinalAmount);
