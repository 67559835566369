const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');
const CheckIcon = require('./Icons/CheckIcon').default;
const ErrorIcon = require('./Icons/ErrorIcon').default;

const InputWrapper = ({
  label,
  error,
  validated,
  empty,
  children,
}) => {
  const hasError = !!error;

  return (
    <div className={classNames('b-input', { 'b-input--error': hasError, 'b-input--empty': empty })}>
      <div className="b-input__top">
        <div className="b-input__label">{label}</div>
        {hasError && <div className="b-input__error">{error}</div>}
      </div>
      <div className="b-input__input-wrapper">
        {children}
        {hasError && <div className="b-input__icon"><ErrorIcon /></div>}
        {!hasError && validated && <div className="b-input__icon"><CheckIcon /></div>}
      </div>
    </div>
  );
};

InputWrapper.propTypes = {
  label: PropTypes.string,
  validated: PropTypes.bool,
  error: PropTypes.string,
  empty: PropTypes.bool,
  children: PropTypes.element.isRequired,
};

InputWrapper.defaultProps = {
  label: undefined,
  error: undefined,
  validated: false,
  empty: false,
};

module.exports = InputWrapper;
