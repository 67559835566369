/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

const React = require('react');
const PropTypes = require('prop-types');

const mapOptions = values => values.map(({ value, label }) => (
  <option key={value} value={value}>{label}</option>
));

const FiltersMobile = ({
  occasions,
  categories,
  filteredMasterTags,
  onCategoryChange,
  onOccasionChange,
  onMasterTagChange,
  currentOccasions,
  currentCategory,
  currentMasterTag,
  toggleFilters,
  onApply,
  onClear,
}) => {
  return (
    <div
      className="b-search-page__filters-mobile"
      onClick={(event) => {
        if (event.target === event.currentTarget) {
          toggleFilters(event);
        }
      }}
    >
      <div className="b-search-page__filters-mobile__selectors">
        <select
          id="mobile-category-filter"
          onChange={event => onCategoryChange(event.target.value)}
          value={(currentCategory && currentCategory.value) || ''}
          className="b-category-filter--mobile"
        >
          {mapOptions(categories)}
        </select>
        {currentCategory && currentCategory.value && currentCategory.value !== 'all' &&
          <select
            id="mobile-master_tag-filter"
            onChange={event => onMasterTagChange(event.target.value)}
            value={(currentMasterTag && currentMasterTag.value) || ''}
            className="b-master-tag-filter--mobile"
          >
            {mapOptions(filteredMasterTags)}
          </select>
        }
        <select
          id="mobile-occasions-filter"
          onChange={event => onOccasionChange([event.target.value])}
          value={(currentOccasions[0] && currentOccasions[0].value) || ''}
          className="b-occasions-filter--mobile"
        >
          {mapOptions(occasions)}
        </select>
      </div>
      <button
        className="b-search-page__filters-mobile__clear-button"
        onClick={onClear}
      >
        <i className="glyphicon glyphicon-remove-circle" />
        Clear filters
      </button>
      <button
        type="submit"
        className="b-search-page__filters-mobile__apply-button"
        onClick={onApply}
      >
        Apply filters
      </button>
    </div>
  );
};

FiltersMobile.propTypes = {
  occasions: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  filteredMasterTags: PropTypes.array.isRequired,
  onOccasionChange: PropTypes.func.isRequired,
  onCategoryChange: PropTypes.func.isRequired,
  onMasterTagChange: PropTypes.func.isRequired,
  currentOccasions: PropTypes.array.isRequired,
  currentCategory: PropTypes.object.isRequired,
  currentMasterTag: PropTypes.any.isRequired,
  onClear: PropTypes.func.isRequired,
  toggleFilters: PropTypes.func.isRequired,
  onApply: PropTypes.func.isRequired,
};

module.exports = FiltersMobile;
