const React = require('react');
const InvoicesTable = require('./table');
const Layout = require('../layout');
const { invoicesPath } = require('../../../resources/InvoiceResource');

class Invoices extends React.Component {
  render() {
    return (
      <Layout>
        <h1>Invoices</h1>
        <div className="b-admin__submenu">
          <a href={invoicesPath('new')} className="b-button b-button--narrow">New Invoice</a>
        </div>
        <InvoicesTable invoices={this.props.invoices} />
      </Layout>
    );
  }
};

module.exports = Invoices;