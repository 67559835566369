const React = require('react');

const HighDemand = () => (
  <div className="b-step">
    <div className="b-step__high-demand-header">
      <h4>
        <span className="glyphicon glyphicon-signal b-step__high-demand-icon" aria-hidden="true" />
        <span className="b-step__high-demand-text">
          &nbsp;{ I18n.t('negotiation_dashboard.high_demand.title') }
        </span>
        <span className="b-step__high-demand-explanation"> - { I18n.t('negotiation_dashboard.high_demand.description') }</span>
      </h4>
    </div>
  </div>
);

module.exports = HighDemand;
