var React = require('react')
var PropTypes = require('prop-types')
var InputField = require('./input_field')

class OptionalInputField extends React.Component {
  constructor(props) {
    super(props)

    const {
      isEnabled = true
    } = props

    this.state = {
      isEnabled: isEnabled
    }
  }

  render() {
    if (!this.state.isEnabled) {
      return null
    }

    return (
      <div className="row b-optional-input-field">
        <div className="col-sm-12 b-optional-input-field__container">
          <InputField {...this.props} />
        </div>
      </div>
    )
  }
}

module.exports = OptionalInputField
