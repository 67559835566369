const React = require('react');
const PropTypes = require('prop-types');

function ConfirmationNoCommission({
  client,
  bookingNumber,
  bookingValue,
}) {
  return (
    <div className="container basa-offersConfirmation">
      <div className="row">
        <div className="col-sm-offset-3 col-sm-6">
          <div>
            <h1 className="basa-offersConfirmation-h1">{I18n.t('payment_process.confirmation.congratulations', { name: client.full_name })}</h1>
            <h2 className="basa-offersConfirmation-h2">{I18n.t('payment_process.confirmation.booking_number', { number: bookingNumber })}</h2>
            <p className="basa-offersConfirmation-p">{I18n.t('payment_process.confirmation.reservation_reminder')}</p>
            <a className="basa-offersConfirmation-button" href="/search">{I18n.t('payment_process.confirmation.cta_button')}</a>
            <div id="payment-booking-value" data-booking-value={bookingValue} />
          </div>
        </div>
      </div>
    </div>
  );
}

ConfirmationNoCommission.propTypes = {
  client: PropTypes.object.isRequired,
  bookingNumber: PropTypes.string.isRequired,
  bookingValue: PropTypes.string.isRequired,
};

module.exports = ConfirmationNoCommission;
