/* eslint-disable jsx-a11y/label-has-associated-control */
const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');
const Autosuggest = require('react-autosuggest');
const debounce = require('lodash.debounce');

const RECOMPUTE_DEBOUNCE_MS = 350;

const {
  CANCELLATION_POLICY_OPTIONS,
  CANCELLATION_POLICY_OPTIONS_WITH_CUSTOM,
  OFFER_SPECIFIC_CHAR_LIMIT,
  VAT_PERCENT_BASA,
} = require('../../../domain/offer');

const { sumValues } = require('../../../lib/calculations');

const cancellationPolicy = (cancellation_policy_option, locale) => {
  if (!CANCELLATION_POLICY_OPTIONS.includes(cancellation_policy_option)) {
    return null;
  }

  return I18n.t(`cancellation_policies.${cancellation_policy_option}.content`, { locale });
};

const ExtraCosts = require('./ExtraCosts');

const addPlaceholders = (extraCosts) => {
  const updatedExtraCosts = extraCosts.slice();

  while (updatedExtraCosts.length < 5) {
    updatedExtraCosts.push({ name: '', value: '' });
  }

  return updatedExtraCosts;
};

class OffersNew extends React.Component {
  constructor(props) {
    super(props);

    const offer = {
      inquiry_id: '',
      artist_name: '',
      artist_service: '',
      where: '',
      when: '',
      specific: '',
      kind_of_moment: '',
      show_beginning: '',
      show_duration: '',
      surface_size: '',
      vat_percent_basa: VAT_PERCENT_BASA,
      vat_percent_artist: VAT_PERCENT_BASA, // this is just the default value in the input
      gross_amount_basa: 0,
      locale: I18n.locale,
      cancellation_policy_option: 'moderate',
      cancellation_policy: I18n.t('cancellation_policies.moderate.content', { locale: I18n.locale }),
      is_full_payment: false,
      ...props.offer,
    };

    const client = {
      email: '',
      full_name: '',
      phone: '',
      ...props.client,
    };

    this.state = {
      artistProfileValue: '',
      clientInputValue: '',
      artistProfileSuggestions: [],
      clientSuggestions: [],
      selectedArtistProfile: null,
      isSubmitting: false,
      offer,
      client,
      errors: {},
      extraCosts: addPlaceholders(props.extraCosts),
    };

    this.calculateOfferPricing = debounce(this.calculateOfferPricing, RECOMPUTE_DEBOUNCE_MS);
  }

  componentDidMount() {
    this.calculateOfferPricing();
  }

  onArtistProfileChange = (event, { newValue }) => {
    this.setState({
      artistProfileValue: newValue,
    });
  }

  onClientChange = (event, { newValue }) => {
    this.setState({
      clientInputValue: newValue,
    });
  }

  onArtistProfileSuggestionSelected = (event, { suggestion }) => {
    const newOffer = this.updateOffer({
      artist_name: suggestion.artistic_name,
      artist_profile_id: suggestion.id,
    });

    this.setState({
      selectedArtistProfile: suggestion,
      offer: newOffer,
    });
  }

  onClientSuggestionSelected = (event, { suggestion }) => {
    const newOffer = this.updateOffer({
      client_id: suggestion.id,
      client_name: suggestion.full_name,
    });

    this.setState({
      offer: newOffer,
      client: { ...suggestion },
    });
  }

  onArtistProfileSuggestionsFetchRequested = ({ value }) => {
    this.loadArtistProfileSuggestions(value);
  };

  onArtistProfileSuggestionsClearRequested = () => {
    this.setState({
      artistProfileSuggestions: [],
    });
  }

  onClientSuggestionsFetchRequested = ({ value }) => {
    this.loadClientSuggestions(value);
  }

  onClientSuggestionsClearRequested = () => {
    this.setState({
      clientSuggestions: [],
    });
  }

  onChangeCancellationPolicyOption = (event) => {
    const { value } = event.target;
    const { offer } = this.state;
    const message = 'Are you sure that you want to override the current cancellation policy?';

    // custom is selected
    if (!CANCELLATION_POLICY_OPTIONS.includes(value)) {
      this.setState({
        offer: this.updateOffer({
          cancellation_policy_option: value,
        }),
      });

    // fixed policy is selected
    } else if (CANCELLATION_POLICY_OPTIONS.includes(offer.cancellation_policy_option) ||
      window.confirm(message)) {
      const cancellation_policy = cancellationPolicy(value, offer.locale);

      this.setState({
        offer: this.updateOffer({
          cancellation_policy_option: value,
          cancellation_policy,
        }),
      });
    }
  }

  // Artisitc Service selection
  onArtisticServiceSelected = (event) => {
    const index = event.target.value;
    const artisticService = this.state.selectedArtistProfile.published_services[index];

    if (index) {
      const newOffer = this.updateOffer({
        artist_service: artisticService.name,
        artistic_service_id: artisticService.id,
      });

      this.setState({
        offer: newOffer,
      });
    }
  }

  onOfferLocaleChange = (event) => {
    const locale = event.target.value || 'en';
    const { offer } = this.state;

    const cancellation_policy = cancellationPolicy(offer.cancellation_policy_option, locale);

    if (cancellation_policy) {
      this.setState({ offer: this.updateOffer({ locale, cancellation_policy }) });
    } else {
      this.setState({ offer: this.updateOffer({ locale }) });
    }
  }

  onExtraCostsChange = (updatedExtraCosts, updatePricing = false) => {
    this.setState({ extraCosts: updatedExtraCosts }, () => {
      if (updatePricing) {
        this.calculateOfferPricing();
      }
    });
  }

  onIsFullPaymentChange = (event) => {
    const isFullPayment = event.target.checked;
    const {
      offer,
    } = this.state;

    this.setState({
      offer: {
        ...offer,
        is_full_payment: isFullPayment,
      },
    }, () => {
      this.calculateOfferPricing();
    });
  }

  getArtistProfileSuggestionValue = (suggestion) => {
    return suggestion.artistic_name;
  }

  getClientSuggestionValue = (suggestion) => {
    return suggestion.email;
  }

  loadClientSuggestions(value) {
    // Request
    $.get(`/autosuggest_client?q=${value}`).done((data) => {
      this.setState({
        clientSuggestions: data,
      });
    });
  }

  loadArtistProfileSuggestions(value) {
    $.get(`/autosuggest?q=${value}`).done((artist_profiles) => {
      this.setState({
        artistProfileSuggestions: artist_profiles,
      });
    });
  }

  updateOffer(values) {
    return Object.assign({}, this.state.offer, values);
  }

  updateClient(values) {
    return Object.assign({}, this.state.client, values);
  }

  calculateOfferPricing = () => {
    const {
      offer,
      extraCosts,
    } = this.state;

    const {
      calculate_pricing_path,
    } = this.props;

    const {
      net_amount_basa,
      net_amount_artist,
      vat_percent_basa,
      vat_percent_artist,
      is_full_payment,
    } = offer;

    $.get(calculate_pricing_path, {
      net_amount_basa,
      net_amount_artist,
      vat_percent_basa,
      vat_percent_artist,
      is_full_payment,
      amount_extra_costs: sumValues(extraCosts),
    }).done((data) => {
      const newOffer = this.updateOffer({
        vat_amount_basa: data.vat_amount_basa,
        vat_amount_artist: data.vat_amount_artist,
        gross_amount_basa: data.gross_amount_basa,
        gross_amount_artist: data.gross_amount_artist,
        total_net_booking_value: data.total_net_booking_value,
        total_gross_booking_value_with_extra_costs: data.total_gross_booking_value_with_extra_costs,
      });

      // calculate new values
      this.setState({
        offer: newOffer,
      });
    });
  }

  offerInputChange = (event) => {
    const newOffer = this.updateOffer({
      [event.target.id]: event.target.value,
    });

    this.setState({
      offer: newOffer,
    });
  }

  pricingInputChange = (event) => {
    const newOffer = this.updateOffer({
      [event.target.id]: event.target.value,
    });

    this.setState({
      offer: newOffer,
    }, () => {
      this.calculateOfferPricing();
    });
  }

  hasClient = () => Boolean(this.state.offer.client_id)

  // Handle submit of the form
  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      isSubmitting: true,
    });

    const isEditing = !!this.state.offer.public_id;
    const createOrUpdateFunction = isEditing ? this.updateOfferRequest : this.createOfferRequest;

    createOrUpdateFunction().done((offer, status, xhr) => {
      window.location = xhr.getResponseHeader('Location');
    }).fail((data) => {
      this.setState({
        isSubmitting: false,
        errors: data.responseJSON.errors,
      });
    });
  }

  createOfferRequest = () => {
    const { offer, extraCosts } = this.state;

    return $.post('/offers.json', { offer: { ...offer, extra_costs_attributes: extraCosts } });
  }

  updateOfferRequest = () => {
    const { offer, extraCosts } = this.state;

    return $.ajax({
      url: `/offers/${offer.public_id}.json`,
      type: 'PUT',
      data: { offer: { ...offer, extra_costs_attributes: extraCosts } },
    });
  }

  renderArtistProfileSuggestion = (suggestion) => {
    return (
      <span>{suggestion.artistic_name}</span>
    );
  }

  renderClientSuggestion = suggestion => <span>{suggestion.full_name} - {suggestion.email}</span>

  render() {
    const {
      artistProfileValue,
      clientInputValue,
      artistProfileSuggestions,
      selectedArtistProfile,
      clientSuggestions,
      extraCosts,
      offer,
      errors,
    } = this.state;

    const {
      is_full_payment,
      vat_percent_artist,
    } = offer;

    const inputArtistProfileProps = {
      placeholder: 'Type the artist name',
      value: artistProfileValue,
      onChange: this.onArtistProfileChange,
      name: 'artist_profile_autosuggest',
    };

    const inputClientProps = {
      placeholder: 'Type the email of the client',
      value: clientInputValue,
      onChange: this.onClientChange,
    };

    let artisticServices;
    let artisticServicesOptions;

    if (selectedArtistProfile) {
      artisticServices = selectedArtistProfile.published_services;
      artisticServicesOptions = artisticServices.map((service, index) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <option key={index + 1} value={index}>
            {service.name}
          </option>
        );
      });
      artisticServicesOptions.unshift(<option key={0} value="" />);
    }

    const loadingClass = this.state.isSubmitting ? 'basa-button--loading' : '';
    const isEditing = this.state.offer.public_id != null;
    const buttonText = isEditing ? 'Update offer' : 'Create offer';
    const titleText = isEditing ? 'Edit Offer' : 'New Offer';

    return (
      <div className="container">
        <div className="row">
          <div className="col-sm-6 col-sm-offset-3">
            <h1>{titleText}</h1>
            <h3>Autofill form</h3>
            <label htmlFor="artist_profile_autosuggest">search artist name</label>
            <Autosuggest
              suggestions={artistProfileSuggestions}
              onSuggestionsFetchRequested={this.onArtistProfileSuggestionsFetchRequested}
              onSuggestionsClearRequested={this.onArtistProfileSuggestionsClearRequested}
              onSuggestionSelected={this.onArtistProfileSuggestionSelected}
              getSuggestionValue={this.getArtistProfileSuggestionValue}
              renderSuggestion={this.renderArtistProfileSuggestion}
              inputProps={inputArtistProfileProps}
            />
            <br />
            <label>
              search artistic service for
              <b>{selectedArtistProfile && selectedArtistProfile.artistic_name}</b>
            </label>
            <br />
            <select name="offers-serviceSelect" onChange={this.onArtisticServiceSelected}>
              {artisticServicesOptions}
            </select>
            <br />
            <form onSubmit={this.handleSubmit}>
              <h3>Offer fields</h3>
              <div className="form-group">
                <label htmlFor="locale">Language (used for translated links to client)</label>
                <select
                  id="locale"
                  onChange={this.onOfferLocaleChange}
                  value={this.state.offer.locale}
                  className="form-control"
                >
                  <option value="en">English</option>
                  <option value="de">Deutsch</option>
                  <option value="pt">Portuguese</option>
                </select>
                <span className="help-block">Changing this will update the cancellation policy (only if it is empty)</span>
              </div>
              <div className={classNames('form-group', { 'has-error': errors.inquiry })}>
                <label htmlFor="inquiry_id">Inquiry ID</label>
                <input id="inquiry_id" className="form-control" onChange={this.offerInputChange} value={this.state.offer.inquiry_id || ''} />
              </div>
              <div className={classNames('form-group', { 'has-error': errors.artist_name })}>
                <label htmlFor="artist_name">Artist name</label>
                <input id="artist_name" className="form-control" onChange={this.offerInputChange} value={this.state.offer.artist_name || ''} />
              </div>
              <div className={classNames('form-group', { 'has-error': errors.artist_service })}>
                <label htmlFor="artist_service">Artist service</label>
                <input id="artist_service" className="form-control" onChange={this.offerInputChange} value={this.state.offer.artist_service || ''} />
              </div>
              <div className={classNames('form-group', { 'has-error': errors.kind_of_moment })}>
                <label htmlFor="kind_of_moment">Kind of moment</label>
                <input id="kind_of_moment" className="form-control" onChange={this.offerInputChange} value={this.state.offer.kind_of_moment || ''} />
              </div>
              <div className={classNames('form-group', { 'has-error': errors.when })}>
                <label htmlFor="when">Show date/Deadline (indicate date of show, or deadline for graffiti/3D art/installations)</label>
                <input id="when" className="form-control" onChange={this.offerInputChange} value={this.state.offer.when || ''} />
              </div>
              <div className={classNames('form-group', { 'has-error': errors.show_beginning })}>
                <label htmlFor="show_beginning">Start time (indicate time show_beginning show should start if applicable)</label>
                <input id="show_beginning" className="form-control" onChange={this.offerInputChange} value={this.state.offer.show_beginning || ''} />
              </div>
              <div className={classNames('form-group', { 'has-error': errors.where })}>
                <label htmlFor="where">Where (as precise as possible, indicate city and country for sure, address or location name if available)</label>
                <input id="where" className="form-control" onChange={this.offerInputChange} value={this.state.offer.where || ''} />
              </div>
              <div className={classNames('form-group', { 'has-error': errors.show_duration })}>
                <label htmlFor="show_duration">Duration</label>
                <input id="show_duration" className="form-control" onChange={this.offerInputChange} value={this.state.offer.show_duration || ''} />
              </div>
              <div className={classNames('form-group', { 'has-error': errors.surface_size })}>
                <label htmlFor="surface_size">Surface size ( if applicable )</label>
                <input id="surface_size" className="form-control" onChange={this.offerInputChange} value={this.state.offer.surface_size || ''} />
              </div>
              <div className={classNames('form-group', { 'has-error': errors.specific })}>
                <label htmlFor="specific">Specific</label>
                <textarea id="specific" maxLength={OFFER_SPECIFIC_CHAR_LIMIT} rows="5" className="form-control" onChange={this.offerInputChange} value={this.state.offer.specific || ''} />
              </div>

              <div className="b-amount-calculator">
                <div className="row">
                  <div className={classNames('col-xs-3', { 'has-error': errors.net_amount_basa_cents })}>
                    <label className="b-amount-calculator__label" htmlFor="net_amount_basa">Net amount for BASA (EUR)</label>
                    <input id="net_amount_basa" placeholder="0.00" className="form-control" onChange={this.pricingInputChange} value={this.state.offer.net_amount_basa ? this.state.offer.net_amount_basa : ''} />
                  </div>
                  <div className="col-xs-3">
                    <label className="b-amount-calculator__label" htmlFor="vat_percent_basa">VAT tax rate for BASA (%)</label>
                    <input id="vat_percent_basa" placeholder="0" className="form-control" onChange={this.pricingInputChange} value={this.state.offer.vat_percent_basa ? this.state.offer.vat_percent_basa : ''} />
                  </div>
                  <div className="col-xs-3">
                    <label className="b-amount-calculator__label" htmlFor="vat_amount_basa">VAT tax amount for BASA (EUR)</label>
                    <input id="vat_amount_basa" placeholder="0.00" className="form-control" value={this.state.offer.vat_amount_basa ? this.state.offer.vat_amount_basa : ''} readOnly />
                  </div>
                  <div className="col-xs-3">
                    <label className="b-amount-calculator__label" htmlFor="gross_amount_basa">Gross amount for BASA (EUR)</label>
                    <input id="gross_amount_basa" placeholder="0.00" className="form-control" value={this.state.offer.gross_amount_basa ? this.state.offer.gross_amount_basa : ''} readOnly />
                  </div>
                </div>

                <div className="row">
                  <div className="form-group">
                    <div className={classNames('col-xs-3', { 'has-error': errors.net_amount_artist_cents })}>
                      <label
                        className="b-amount-calculator__label"
                        htmlFor="net_amount_artist"
                      >
                        Net amount for artist (EUR)
                      </label>
                      <input
                        id="net_amount_artist"
                        placeholder="0.00"
                        className="form-control"
                        onChange={this.pricingInputChange}
                        value={offer.net_amount_artist ? offer.net_amount_artist : ''}
                      />
                    </div>
                    <div className="col-xs-3">
                      <label
                        className="b-amount-calculator__label"
                        htmlFor="vat_percent_artist"
                      >
                        VAT tax rate for artist (%)
                      </label>
                      <input
                        id="vat_percent_artist"
                        placeholder="0"
                        disabled={is_full_payment}
                        className="form-control"
                        onChange={this.pricingInputChange}
                        value={is_full_payment ? '-' : (vat_percent_artist || '')}
                      />
                    </div>
                    <div className="col-xs-3">
                      <label className="b-amount-calculator__label" htmlFor="vat_amount_artist">VAT tax amount for artist (EUR)</label>
                      <input id="vat_amount_artist" placeholder="0.00" className="form-control" value={this.state.offer.vat_amount_artist ? this.state.offer.vat_amount_artist : ''} readOnly />
                    </div>
                    <div className="col-xs-3">
                      <label className="b-amount-calculator__label" htmlFor="gross_amount_artist">Gross amount for the artist (EUR)</label>
                      <input id="gross_amount_artist" placeholder="0.00" className="form-control" value={this.state.offer.gross_amount_artist ? this.state.offer.gross_amount_artist : ''} readOnly />
                    </div>
                  </div>
                </div>

                <ExtraCosts
                  extraCosts={extraCosts}
                  onChange={this.onExtraCostsChange}
                />

                <div className="row">
                  <div className="form-group">
                    <div className="col-xs-3">
                      <label className="b-amount-calculator__label" htmlFor="total_net_booking_value">Total net booking value (EUR)</label>
                      <input id="total_net_booking_value" placeholder="0.00" className="form-control" value={this.state.offer.total_net_booking_value ? this.state.offer.total_net_booking_value : ''} readOnly />
                    </div>
                    <div className="col-xs-6" />
                    <div className="col-xs-3">
                      <label className="b-amount-calculator__label" htmlFor="total_gross_booking_value_with_extra_costs">Total gross booking value (EUR)</label>
                      <input id="total_gross_booking_value_with_extra_costs" placeholder="0.00" className="form-control" value={this.state.offer.total_gross_booking_value_with_extra_costs ? this.state.offer.total_gross_booking_value_with_extra_costs : ''} readOnly />
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="form-group">
                <label htmlFor="is_full_payment">Is this a 100% payment?</label>
                <input
                  id="is_full_payment"
                  name="is_full_payment"
                  type="checkbox"
                  checked={is_full_payment}
                  onChange={this.onIsFullPaymentChange}
                  disabled={isEditing}
                  title={isEditing ? 'Can change this only for new offers!' : 'Choose between 20% and 100% payment'}
                />
              </div>
              <br />
              <div className={classNames('form-group', { 'has-error': errors.cancellation_policy })}>
                <label htmlFor="cancellation_policy">Cancellation policy (only change, if really necessary)</label>
                <select
                  onChange={this.onChangeCancellationPolicyOption}
                  value={this.state.offer.cancellation_policy_option}
                  name="cancellation_policy_option"
                >
                  {CANCELLATION_POLICY_OPTIONS_WITH_CUSTOM.map(option => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
                <textarea
                  id="cancellation_policy"
                  rows="5"
                  className="form-control"
                  onChange={this.offerInputChange}
                  value={this.state.offer.cancellation_policy}
                  disabled={this.state.offer.cancellation_policy_option !== 'custom'}
                />
              </div>
              <h3>Client info fields</h3>
              <p>search for an existing client</p>
              <Autosuggest
                suggestions={clientSuggestions}
                onSuggestionsFetchRequested={this.onClientSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onClientSuggestionsClearRequested}
                onSuggestionSelected={this.onClientSuggestionSelected}
                getSuggestionValue={this.getClientSuggestionValue}
                renderSuggestion={this.renderClientSuggestion}
                inputProps={inputClientProps}
              />
              <br />
              <div className={classNames('form-group', { 'has-error': errors['client.email'] })}>
                <label htmlFor="email">Email</label>
                <input id="email" disabled className="form-control" value={this.state.client.email} />
              </div>
              <div className={classNames('form-group', { 'has-error': errors['client.full_name'] })}>
                <label htmlFor="full_name">Full name</label>
                <input id="full_name" disabled className="form-control" value={this.state.client.full_name} />
              </div>
              <div className={classNames('form-group', { 'has-error': errors['client.phone'] })}>
                <label htmlFor="phone">Phone</label>
                <input id="phone" disabled className="form-control" value={this.state.client.phone} />
              </div>
              {Object.keys(this.state.errors).length > 0 &&
                <div className="center-block">
                  <p className="text-center text-danger">Input contains errors!</p>
                </div>
              }
              <span>{this.hasClient() && <span><a className="green-link" href={`/admin/clients/${this.state.offer.client_id}/edit`}>Edit client</a> | </span>}<a className="green-link" href="/admin/clients/new">Create a new client</a></span>
              <button className={`basa-button ${loadingClass}`} type="submit">{buttonText}</button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

OffersNew.propTypes = {
  client: PropTypes.object.isRequired,
  offer: PropTypes.object.isRequired,
  extraCosts: PropTypes.array.isRequired,
  calculate_pricing_path: PropTypes.string.isRequired,
};

module.exports = OffersNew;
