const React = require('react');
const Modal = require('components/ui/Modal');

const ModalLoader = () => (
  <Modal isOpen>
    <div className="b-react-modal__body b-react-modal__text-block">
      <p style={{ width: 300 }}>Loading...</p>
    </div>
  </Modal>
);

module.exports = ModalLoader;
