const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');

class BillingFormField extends React.Component {
  fieldValueClassName() {
    return classNames('b-input-field__value', { 'b-input-field__value--error': this.props.field_error });
  }

  renderError() {
    const {
      hasError,
      errorMessage,
    } = this.props;

    if (!hasError) return null;

    return (
      <div className="b-input-field__error">{errorMessage}</div>
    );
  }

  render() {
    const {
      fieldName,
      fieldValue,
    } = this.props;

    return (
      <div className="b-payment__form__field">
        <div className="b-input-field__name">
          {I18n.t(`payment_process.billing_data.form.${fieldName}`)}
        </div>
        <input
          className={this.fieldValueClassName()}
          value={fieldValue}
          name={`billing_detail_${fieldName}`}
          onChange={event => this.props.onChangeField(fieldName, event.target.value)}
          placeholder={I18n.t(`payment_process.billing_data.placeholder.${fieldName}`, { defaultValue: '' })}
        />
        {this.renderError()}
      </div>
    );
  }
}

BillingFormField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  fieldValue: PropTypes.string,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  onChangeField: PropTypes.func.isRequired,
};

BillingFormField.defaultProps = {
  hasError: false,
  errorMessage: '',
  fieldValue: '',
};

module.exports = BillingFormField;
