const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');

const isStepCompleted = (currentStep, activeStep) => currentStep < activeStep;

class StepsBar extends React.Component {
  constructor(props) {
    super(props);

    this.stepClass = this.stepClass.bind(this);
    this.stepIcon = this.stepIcon.bind(this);
  }

  stepClass(currentStep) {
    const {
      activeStep,
    } = this.props;

    const isCompleted = isStepCompleted(currentStep, activeStep);
    const isCurrent = currentStep === activeStep;

    return classNames(
      'b-steps-bar__step',
      { 'b-steps-bar__step--completed': isCompleted },
      { 'b-steps-bar__step--active': !isCompleted && isCurrent },
    );
  }

  stepIcon(currentStep) {
    const {
      activeStep,
    } = this.props;

    if (isStepCompleted(currentStep, activeStep)) {
      return <span>&nbsp;</span>;
    }

    return <span>{currentStep}</span>;
  }

  render() {
    return (
      <nav className="b-steps-bar">
        <li className={this.stepClass(1)}>
          {this.stepIcon(1)}
          <p>{I18n.t('payment_process.booking_data.step_name')}</p>
          <hr />
        </li>
        <li className={this.stepClass(2)}>
          {this.stepIcon(2)}
          <p>{I18n.t('payment_process.billing_data.step_name')}</p>
          <hr />
        </li>
        <li className={this.stepClass(3)}>
          {this.stepIcon(3)}
          <p>{I18n.t('payment_process.review.step_name')}</p>
          <hr />
        </li>
        <li className={this.stepClass(4)}>
          {this.stepIcon(4)}
          <p>{I18n.t('payment_process.payment_method.step_name')}</p>
        </li>
      </nav>
    );
  }
}

StepsBar.propTypes = {
  activeStep: PropTypes.number.isRequired,
};

module.exports = StepsBar;
