const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');

const BillingFormField = require('./billing_form_field');
const VatField = require('./vat_field');
const WithBillingDataContext = require('./with-billing-data-context');

class BillingForm extends React.Component {
  constructor(props) {
    super(props);

    this.onChangeField = this.onChangeField.bind(this);
    this.onChangeRepresentCompany = this.onChangeRepresentCompany.bind(this);
  }

  onChangeField(field, value) {
    this.props.context.updateBillingDetailState({ [field]: value });
  }

  onChangeRepresentCompany(event) {
    this.props.context.updateBillingDetailState({ represents_company: event.target.checked });
  }

  errorMessage(field) {
    const errors = this.props.context.errors.billing_detail[field];

    return errors && errors[0];
  }

  isFolded() {
    const { full_name, country } = this.props.context.billing_detail;

    return !full_name || !country;
  }

  foldedClassNames() {
    return classNames('b-payment__form__folded', { 'b-payment__form__folded--slide-in': !this.isFolded() });
  }

  renderField(name) {
    return (
      <BillingFormField
        fieldName={name}
        fieldValue={this.props.context.billing_detail[name]}
        hasError={!!this.errorMessage(name)}
        errorMessage={this.errorMessage(name)}
        onChangeField={this.onChangeField}
      />
    );
  }

  renderVatField() {
    return (
      <VatField
        vat={this.props.context.billing_detail.vat}
        hasError={!!this.errorMessage('vat')}
        errorMessage={this.errorMessage('vat')}
      />
    );
  }

  renderCompanyFields() {
    if (!this.props.context.billing_detail.represents_company) return null;

    return (
      <div>
        {this.renderField('company_name')}
        {this.renderVatField()}
      </div>
    );
  }

  renderCheckbox() {
    return (
      <div className="checkbox">
        <label htmlFor="represents_company">
          <input
            id="represents_company"
            name="represents_company"
            type="checkbox"
            className="checkbox"
            checked={this.props.context.billing_detail.represents_company || false}
            onChange={this.onChangeRepresentCompany}
          />
          <span>{I18n.t('payment_process.billing_data.form.represents_company')}</span>
        </label>
      </div>
    );
  }

  renderFoldedFields() {
    return (
      <div className={this.foldedClassNames()}>
        {this.renderField('street_and_house')}
        <div className="b-payment__form__double">
          {this.renderField('postcode')}
          {this.renderField('city')}
        </div>
        {this.renderField('additional_address')}
        {this.renderCheckbox()}
        {this.renderCompanyFields()}
      </div>
    );
  }

  render() {
    return (
      <div className="b-payment__form">
        {this.renderField('full_name')}
        {this.renderField('country')}
        {this.renderFoldedFields()}
      </div>
    );
  }
}

BillingForm.propTypes = {
  context: PropTypes.object.isRequired,
};

module.exports = WithBillingDataContext(BillingForm);
