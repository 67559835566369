const React = require('react');
const PropTypes = require('prop-types');
const HowToVideoModal = require('./HowToVideoModal');
const PlayButton = require('../../../ui/Button/PlayButton');

const HowToVideoButton = ({ tipsGroup, bookingPublicArtistId, startOpened }) => {
  const [isOpen, setModalOpen] = React.useState(startOpened);

  return (
    <React.Fragment>
      <PlayButton
        onClick={() => setModalOpen(true)}
        text={I18n.t('negotiation_dashboard.how_to_modal.trigger_button')}
      />
      <HowToVideoModal
        isOpen={isOpen}
        closeModal={() => setModalOpen(false)}
        tipsGroup={tipsGroup}
        booking_public_id={bookingPublicArtistId}
      />
    </React.Fragment>
  );
};

HowToVideoButton.propTypes = {
  tipsGroup: PropTypes.object.isRequired,
  bookingPublicArtistId: PropTypes.string.isRequired,
  startOpened: PropTypes.bool,
};

HowToVideoButton.defaultProps = {
  startOpened: false,
};

module.exports = HowToVideoButton;
