const React = require('react');
const PropTypes = require('prop-types');
const ReactSticky = require('react-sticky');

const StepsBar = require('./steps-bar');

const BookingData = require('./booking_data');
const BillingData = require('./billing_data');
const Payment = require('./payment');
const Review = require('./review');
const Confirmation = require('./confirmation');
const { isBankTransferPayment } = require('../../domain/payment');

const viewSteps = {
  offerInfoView: 1,
  billingDataView: 2,
  reviewView: 3,
  paymentView: 4,
  confirmationView: 5,
};

const initialView = (status) => {
  if (['paid_with_cc', 'paid_with_bt', 'reserved', 'processing_cc'].indexOf(status) >= 0) {
    return 'confirmationView';
  }

  return 'offerInfoView';
};

class PaymentProcess extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      paymentType: isBankTransferPayment(props.payment.status) ? 'bank transfer' : 'credit card',
      currentView: initialView(props.payment.status),
      billing_detail: props.billing_detail || {},
      client: props.client,
      offer: props.offer,
    };
  }

  onConfirmBillingData = (billing_detail, client, offer) => {
    this.setCurrentView('reviewView');
    this.setState({ billing_detail, client, offer });
  }

  setCurrentView = (viewName) => {
    window.scrollTo(0, 0);

    this.setState({ currentView: viewName });
  }

  getViewComponents = () => {
    return {
      offerInfoView: this.renderOfferInfoView(),
      billingDataView: this.renderBillingDataView(),
      reviewView: this.renderReviewView(),
      paymentView: this.renderPaymentView(),
      confirmationView: this.renderConfirmationView(),
    };
  }

  changePaymentType = (type) => {
    this.setState({ paymentType: type });
  }

  renderOfferInfoView() {
    return (
      <BookingData
        inquiry={this.props.inquiry}
        offer={this.state.offer}
        extraCosts={this.props.extraCosts}
        onNext={() => this.setCurrentView('billingDataView')}
      />
    );
  }

  renderBillingDataView() {
    return (
      <BillingData
        inquiry={this.props.inquiry}
        offer={this.state.offer}
        extraCosts={this.props.extraCosts}
        client={this.state.client}
        billing_detail={this.state.billing_detail}
        termsPath={this.props.termsPath}
        privacyPath={this.props.privacyPath}
        offerPolicyUpdatePath={this.props.offerPolicyUpdatePath}
        faqPath={this.props.faqPath}
        onNext={this.onConfirmBillingData}
        onBack={() => this.setCurrentView('offerInfoView')}
      />
    );
  }

  renderReviewView() {
    return (
      <Review
        offer={this.state.offer}
        inquiry={this.props.inquiry}
        client={this.props.client}
        onBack={() => this.setCurrentView('billingDataView')}
        onNext={() => this.setCurrentView('paymentView')}
      />
    );
  }

  renderPaymentView() {
    return (
      <Payment
        stripePublicKey={this.props.stripe_public_key}
        offer={this.state.offer}
        client={this.props.client}
        paymentType={this.state.paymentType}
        changePaymentType={this.changePaymentType}
        faqPath={this.props.faqPath}
        inquiry={this.props.inquiry}
        reserve_path={this.props.reserve_path}
        payment_intent_path={this.props.payment_intent_path}
        payment_intent_failed_path={this.props.payment_intent_failed_path}
        onBack={() => this.setCurrentView('reviewView')}
        onNext={() => this.setCurrentView('confirmationView')}
      />
    );
  }

  renderConfirmationView() {
    const { offer } = this.state;

    return (
      <Confirmation
        client={this.props.client}
        paymentType={this.state.paymentType}
        paymentStatus={this.props.payment.status}
        bookingNumber={offer.booking_number}
        bookingValue={offer.total_gross_booking_value_with_extra_costs}
      />
    );
  }

  render() {
    return (
      <ReactSticky.StickyContainer>
        <div id={`payment-${this.state.currentView}`} className="b-payment">
          <StepsBar activeStep={viewSteps[this.state.currentView]} />
          {this.getViewComponents()[this.state.currentView]}
        </div>
      </ReactSticky.StickyContainer>
    );
  }
}

PaymentProcess.propTypes = {
  offer: PropTypes.object.isRequired,
  extraCosts: PropTypes.array.isRequired,
  client: PropTypes.object.isRequired,
  inquiry: PropTypes.object.isRequired,
  billing_detail: PropTypes.object,
  payment: PropTypes.object.isRequired,
  reserve_path: PropTypes.string.isRequired,
  payment_intent_path: PropTypes.string.isRequired,
  payment_intent_failed_path: PropTypes.string.isRequired,
  faqPath: PropTypes.string.isRequired,
  termsPath: PropTypes.string.isRequired,
  privacyPath: PropTypes.string.isRequired,
  offerPolicyUpdatePath: PropTypes.string.isRequired,
  stripe_public_key: PropTypes.string.isRequired,
};

PaymentProcess.defaultProps = {
  billing_detail: {},
};

module.exports = PaymentProcess;
