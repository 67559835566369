const React = require('react');
const PropTypes = require('prop-types');
const PortfolioLinks = require('./PortfolioLinks');
const { formatDate } = require('../../../lib/datetime_formatters');

const date = (value_at) => {
  return formatDate(value_at, 'yyyy-MM-dd HH:mm');
};

const InvitationRequest = (props) => {
  const {
    name,
    approved,
    approved_at,
    admin_response,
    admin_response_at,
    created_at,
    email,
    locale,
    location,
    phone,
    portfolio_link,
    portfolio_link_extra_1,
    portfolio_link_extra_2,
    referral_option,
    referral_source,
  } = props;

  const portfolio_links = [
    portfolio_link,
    portfolio_link_extra_1,
    portfolio_link_extra_2,
  ].filter(Boolean);

  return (
    <table className="table">
      <tbody>
        <tr>
          <td>Name</td>
          <td><strong>{name}</strong></td>
        </tr>
        <tr>
          <td>Email</td>
          <td>{email}</td>
        </tr>
        <tr>
          <td>Phone</td>
          <td>{phone}</td>
        </tr>
        <tr>
          <td>Location</td>
          <td>{location}</td>
        </tr>
        <tr>
          <td>Locale</td>
          <td>{locale}</td>
        </tr>
        <tr>
          <td>Portfolio links</td>
          <td><PortfolioLinks links={portfolio_links} /></td>
        </tr>
        <tr>
          <td>Referral option</td>
          <td>{referral_option}</td>
        </tr>
        <tr>
          <td>Referral source</td>
          <td>{referral_source}</td>
        </tr>
        <tr>
          <td>Created at</td>
          <td>{date(created_at)}</td>
        </tr>
        <tr>
          <td>Approved</td>
          <td>
            <span>{String(approved)}</span>
            <span style={{ marginLeft: 8 }}>{approved && `(${date(approved_at)})`}</span>
          </td>
        </tr>
        <tr>
          <td>Admin Response</td>
          <td>
            <span>{admin_response}</span>
            <span style={{ marginLeft: 8 }}>{admin_response && `(${date(admin_response_at)})`}</span>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

InvitationRequest.propTypes = {
  name: PropTypes.string.isRequired,
  approved: PropTypes.bool.isRequired,
  approved_at: PropTypes.string,
  admin_response: PropTypes.string,
  admin_response_at: PropTypes.string,
  created_at: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  portfolio_link: PropTypes.string.isRequired,
  portfolio_link_extra_1: PropTypes.string,
  portfolio_link_extra_2: PropTypes.string,
  referral_option: PropTypes.string,
  referral_source: PropTypes.string,
};

InvitationRequest.defaultProps = {
  portfolio_link_extra_1: '',
  portfolio_link_extra_2: '',
  referral_option: '',
  referral_source: '',
  approved_at: '',
  admin_response: '',
  admin_response_at: '',
};

module.exports = InvitationRequest;
