const React = require("react");
const PropTypes = require("prop-types");
const flow = require("lodash.flow");
const ScrollableAnchor = require("react-scrollable-anchor").default;

const Step = require("../../step");

const ArtistOffer = require("./artist_offer");
const ArtistOfferView = require("../../artist_offer_view");

const withArtistDashboardContext = require("./with-artist-dashboard-context");
const withFoldableStep = require("./with-foldable-step");
const ContextPropTypes = require("./context-prop-types");

class OfferStep extends React.Component {
  offerStepTitle() {
    if (this.props.context.isOfferSent) {
      return I18n.t("negotiation_dashboard.artist.final_price_sent_title");
    }
    return I18n.t("negotiation_dashboard.artist.final_price_title");
  }

  branchShowCreateOffer() {
    const { client, inquiry, offer, extraCosts, isOfferSent } =
      this.props.context;

    if (isOfferSent) {
      return (
        <div>
          <p className="b-artist-dashboard__offer-info">
            {I18n.t("negotiation_dashboard.artist.fingers_crossed")}
          </p>

          <ArtistOfferView
            inquiry={inquiry}
            offer={offer}
            extraCosts={extraCosts}
            client={client}
            pov="artist"
          />
        </div>
      );
    }

    return (
      <div>
        <p className="b-artist-dashboard__offer-info">
          {I18n.t("negotiation_dashboard.artist.all_information")}
        </p>
        <ArtistOffer />
      </div>
    );
  }

  render() {
    const { isFolded, onClickFold } = this.props;

    const { isOfferSent } = this.props.context;

    return (
      <>
        <ScrollableAnchor id="offer-step">
          <div id="offer-step" />
        </ScrollableAnchor>
        <Step
          title={this.offerStepTitle()}
          number={3}
          done={isOfferSent}
          checkmark
          folded={isFolded}
          onClickHeader={onClickFold}
          foldingLocator="qa-toggle-offer"
          headerTip={
            isOfferSent ? "artist.client_will_pay" : "artist.price_double_check"
          }
        >
          {this.branchShowCreateOffer()}
        </Step>
      </>
    );
  }
}

OfferStep.propTypes = {
  isFolded: PropTypes.bool.isRequired,
  onClickFold: PropTypes.func.isRequired,

  context: ContextPropTypes.isRequired,
};

module.exports = flow(
  withFoldableStep("offer"),
  withArtistDashboardContext
)(OfferStep);
