const React = require('react');
const PropTypes = require('prop-types');

const WithBillingDataContext = require('./with-billing-data-context');

class AgreementCheckboxes extends React.Component {
  constructor(props) {
    super(props);

    this.onChangeTosAgree = this.onChangeTosAgree.bind(this);
    this.onChangeDataCollectAgree = this.onChangeDataCollectAgree.bind(this);
  }

  onChangeTosAgree(event) {
    this.props.context.updateFormState({ tosAgree: event.target.checked });
  }

  onChangeDataCollectAgree(event) {
    this.props.context.updateFormState({ dataCollectAgree: event.target.checked });
  }

  render() {
    const options = {
      terms_url: this.props.termsPath,
      privacy_url: this.props.privacyPath,
    };

    return (
      <div className="basa-offersShow-consentBlock">
        <div className="checkbox">
          <label htmlFor="data_collect_agree" className="">
            <input
              id="data_collect_agree"
              name="data_collect_agree"
              type="checkbox"
              className="checkbox"
              checked={this.props.context.form.dataCollectAgree}
              onChange={this.onChangeDataCollectAgree}
            />
            <span dangerouslySetInnerHTML={{ __html: I18n.t('payment_process.booking_data.data_collect_agree', options) }} />
          </label>
        </div>
        <div className="checkbox basa-offersShow-tosBlock">
          <label htmlFor="tos_agree" className="">
            <input
              id="tos_agree"
              name="tos_agree"
              type="checkbox"
              className="checkbox"
              checked={this.props.context.form.tosAgree}
              onChange={this.onChangeTosAgree}
            />
            <span dangerouslySetInnerHTML={{ __html: I18n.t('payment_process.booking_data.tos_agree', options) }} />
          </label>
        </div>
      </div>
    );
  }
}

AgreementCheckboxes.propTypes = {
  context: PropTypes.object.isRequired,
  termsPath: PropTypes.string.isRequired,
  privacyPath: PropTypes.string.isRequired,
};

module.exports = WithBillingDataContext(AgreementCheckboxes);
