const React = require('react');

const grid = {
  1: ['cols-sm-12'],
  2: ['cols-sm-6', 'cols-sm-6'],
  3: ['cols-sm-4', 'cols-sm-4', 'cols-sm-4'],
};

class VideoSection extends React.Component {
  parseVideoUrls(urls) {
    return urls.split("|").map((url) => {
      const youtubeId = this.getYoutubeId(url);
      const vimeoId = this.getVimeoId(url);

      if (youtubeId) {
        return { provider: 'youtube', id: youtubeId };
      } else if (vimeoId) {
        return { provider: 'vimeo', id: vimeoId };
      }

      return null;
    }).filter((element) => ( element !== null));
  }

  getYoutubeId(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    if (match && match[2].length == 11) {
      return match[2];
    } else {
      return null;
    }
  }

  getVimeoId(url) {
    const regExp = /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/;
    const match = url.match(regExp);
    if (match && match[3]) {
      return match[3]
    } else {
      return null;
    }
  }

  renderVideoWidget(id, provider) {
    if (provider === 'youtube') {
      return <iframe
              className="embed-responsive-item"
              src={`https://www.youtube.com/embed/${id}?rel=0&modestbranding=1&autohide=1&showinfo=0`}
              frameBorder="0"
              sandbox="allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation"
              allowFullScreen
      />;
    } else if (provider === 'vimeo') {
      return <iframe className="embed-responsive-item" src={`//player.vimeo.com/video/${id}?title=0&byline=0&badge=0`} frameBorder="0" allowFullScreen />;
    }

    return null;
  }

  renderVideoWidgets(videoUrls) {
    const twin = videoUrls.length === 2;
    const paddings = {
      0: '0 3px 0 0',
      1: '0 0 0 3px',
    };

    return videoUrls.map(({ id, provider }, index) => (
      <div key={id} className={twin ? "col-sm-6" : "col-sm-12"}>
        <div className="b-service__video-section__video" style={{ padding: twin ? paddings[index] : '0 0 0 0' }}>
          <div className="embed-responsive embed-responsive-16by9">
            {this.renderVideoWidget(id, provider)}
          </div>
        </div>
      </div>
    ));
  }

  renderVideoRow(videoUrls) {
    if (videoUrls.length) {
      return (
        <div className="row no-gutter">
          {this.renderVideoWidgets(videoUrls)}
        </div>
      );
    }

    return null;
  }

  render() {
    const videoUrls = this.parseVideoUrls(this.props.videoUrls);
    const count = videoUrls.length;

    if (!count) return null;

    const split = Math.floor(count / 2);
    const firstRow = videoUrls.slice(0, split);
    const secondRow = videoUrls.slice(split);

    return (
      <div className="b-service__video-section">
        {this.renderVideoRow(firstRow)}
        {this.renderVideoRow(secondRow)}
      </div>
    );
  }
}

module.exports = VideoSection;
