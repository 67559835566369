function baseURL(id) {
  return `/client_offers/${id}`;
}

function update(id, params) {
  return $.ajax({
    type: 'PUT',
    url: baseURL(id),
    data: params,
  });
}

export function saveClientOffer(id, params) {
  return update(id, params);
}
