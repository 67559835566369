const React = require("react");
const PropTypes = require("prop-types");
const PaymentActionsController = require("./payment_actions_controller");
const { formatCurrency } = require("../../../lib/currency");

class CreditCardPayment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isProcessing: false,
      errorCode: null,
      cardholderName: "",
    };
  }

  componentDidMount() {
    const { stripePublicKey } = this.props;

    const style = {
      base: {
        iconColor: "#666EE8",
        color: "#31325F",
        lineHeight: "40px",
        fontWeight: 300,
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: "15px",

        "::placeholder": {
          color: "#CFD7E0",
        },
      },
    };

    const stripe = Stripe(stripePublicKey);
    const elements = stripe.elements({ locale: I18n.locale });
    const cardElement = elements.create("card", {
      style,
      hidePostalCode: true,
    });

    cardElement.on("change", (event) => {
      this.onCardChange(event);
    });

    cardElement.mount("#card-element");

    this.stripe = stripe;
    this.cardElement = cardElement;
  }

  onCardChange = ({ error }) => {
    if (error) {
      this.setState({
        isProcessing: false,
        errorCode: error.code,
      });
    }
  };

  // https://stripe.com/docs/testing#cards-responses
  errorMessage() {
    const { errorCode } = this.state;
    const incomplete_data_codes = ["blank_name", "incomplete_number"];
    const try_again_codes = ["processing_error", "try_again", "card_declined"];
    const check_all_data_codes = [
      "invalid_number",
      "incorrect_cvc",
      "incorrect_number",
      "invalid_expiry_year_past",
    ];

    if (incomplete_data_codes.includes(errorCode)) {
      return I18n.t("payment_process.error.incomplete_data");
    } else if (check_all_data_codes.includes(errorCode)) {
      return I18n.t("payment_process.error.check_all_data");
    } else if (try_again_codes.includes(errorCode)) {
      return I18n.t("payment_process.error.try_again");
    }

    return I18n.t("payment_process.error.try_again");
  }

  handleSubmit = () => {
    const { cardholderName } = this.state;
    const { payment_intent_path } = this.props;

    if (!cardholderName) {
      this.setState({ errorCode: "blank_name", isProcessing: false });
      return;
    }

    this.setState({ isProcessing: true, errorCode: null });

    $.post(payment_intent_path).done((result) => {
      const { payment_intent } = result;

      if (payment_intent.client_secret) {
        this.handleCardPayment(payment_intent.client_secret, payment_intent.id);
      } else {
        this.setState({ errorCode: "try_again", isProcessing: false });
      }
    });
  };

  handleCardPayment = (client_secret, payment_intent_id) => {
    const { cardholderName } = this.state;
    const { onNext } = this.props;

    this.stripe
      .handleCardPayment(client_secret, this.cardElement, {
        payment_method_data: {
          billing_details: { name: cardholderName },
        },
      })
      .then((result) => {
        if (result.error) {
          this.handleCardPaymentError(payment_intent_id, result.error);
        } else {
          onNext();
        }
      });
  };

  handleCardPaymentError = (payment_intent_id, error) => {
    const { payment_intent_failed_path } = this.props;

    $.ajax({
      url: payment_intent_failed_path,
      type: "PUT",
      dataType: "json",
      data: { payment_intent_id },
    });

    this.setState({
      errorCode: error.code,
      isProcessing: false,
    });
  };

  render() {
    const { cardholderName, errorCode } = this.state;

    const { onBack, offer } = this.props;

    const {
      gross_amount_basa,
      total_gross_booking_value_with_extra_costs,
      is_full_payment,
      currency,
    } = offer;

    const totalGrossBookingValueWithExtraCosts = formatCurrency(
      I18n.locale,
      currency,
      total_gross_booking_value_with_extra_costs
    );
    const grossAmountBasa = formatCurrency(
      I18n.locale,
      currency,
      gross_amount_basa
    );

    const hasError = !!errorCode;

    return (
      <div>
        <div>
          <p className="basa-payment-info1">
            {is_full_payment === true
              ? I18n.t(
                  "payment_process.payment_method.credit_card_explanation",
                  { amount: totalGrossBookingValueWithExtraCosts }
                )
              : I18n.t(
                  "payment_process.payment_method.credit_card_explanation",
                  { amount: grossAmountBasa }
                )}
          </p>
        </div>
        <div>
          <div className="stripe-group">
            <label htmlFor="cardholder-name" className="stripe-label">
              <span>
                {I18n.t("payment_process.payment_method.cardholder_name")}
              </span>
              <input
                name="cardholder-name"
                className="stripe-field"
                placeholder="Jane Doe"
                value={cardholderName}
                onChange={(ev) =>
                  this.setState({ cardholderName: ev.target.value })
                }
              />
            </label>
          </div>
          <div className="stripe-group">
            <label htmlFor="card-element" className="stripe-label">
              <span>
                {I18n.t("payment_process.payment_method.cardholder_card")}
              </span>
              <div id="card-element" className="stripe-field" />
            </label>
          </div>

          <div className="stripe-questionMark">
            &nbsp;?
            <div className="stripe-tooltipText">
              <p className="stripe-tooltipText-header">
                {I18n.t("payment_process.payment_method.what_is_cvc")}
              </p>
              <p className="stripe-tooltipText-paragraph">
                {I18n.t("payment_process.payment_method.cvc_explanation")}
              </p>
            </div>
          </div>

          <p className="basa-payment-secure-info">
            <span className="stripe-lockIcon" />
            {I18n.t("payment_process.payment_method.encryption_explanation")}
          </p>

          <div className="stripe-outcome">
            {hasError && (
              <div className="stripe-error" role="alert">
                {this.errorMessage()}
              </div>
            )}
          </div>
        </div>
        <PaymentActionsController
          isProcessing={this.state.isProcessing}
          handleBackButton={onBack}
          handleSubmit={this.handleSubmit}
        />
      </div>
    );
  }
}

CreditCardPayment.propTypes = {
  stripePublicKey: PropTypes.string.isRequired,
  payment_intent_path: PropTypes.string.isRequired,
  payment_intent_failed_path: PropTypes.string.isRequired,
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  gross_amount_basa: PropTypes.string.isRequired,
  offer: PropTypes.object.isRequired,
};

module.exports = CreditCardPayment;
