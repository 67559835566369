const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');

const Heading = ({ text, subheading }) => {
  const className = classNames('b-heading', subheading && 'b-heading--subheading');

  return React.createElement(subheading ? 'h2' : 'h1', { className }, text);
};

Heading.propTypes = {
  text: PropTypes.string.isRequired,
  subheading: PropTypes.bool,
};

Heading.defaultProps = {
  subheading: false,
};

module.exports = Heading;
