const React = require('react');
const PropTypes = require('prop-types');
const ArtisticServiceCard = require('./artistic_service_card');

function ArtisticServiceResultItem({ artistic_service }) {
  return (
    <ArtisticServiceCard artistic_service={artistic_service} />
  );
}

ArtisticServiceResultItem.defaultProps = {
};

ArtisticServiceResultItem.propTypes = {
  artistic_service: PropTypes.object.isRequired,
};

module.exports = ArtisticServiceResultItem;
