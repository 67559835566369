import { Controller } from "stimulus";
import React from "react";
import ReactDOM from "react-dom";
import lozad from "lozad";
import VideoSlide from "../components/virtual_artistic_experiences/video_slide";
import { formatCurrency } from "../lib/currency";

export default class extends Controller {
  static get targets() {
    return [
      "tabsConainter",
      "resultsArea",
      "pagination",
      "previousPage",
      "nextPage",
    ];
  }

  initLozad() {
    this.observer = lozad();
    this.observer.observe();
  }

  connect() {
    this.digitalServices = JSON.parse(this.data.get("vae"));
    // TODO: hardcode to JS
    this.clockIconUrl = this.data.get("clock-icon-url");
    this.talkIconUrl = this.data.get("talk-icon-url");
    this.walletIconUrl = this.data.get("wallet-icon-url");
    this.arrowIconUrl = this.data.get("arrow-icon-url");

    this.currentPage = 1;
    this.totalPages = 1;
    this.category = "all";

    this.locale = I18n.locale;

    ReactDOM.render(this.getTabsJSX("all"), this.tabsConainterTarget);
    ReactDOM.render(
      this.getResultsJSX("all", this.currentPage),
      this.resultsAreaTarget
    );
    this.renderPagination(this.category, this.currentPage);

    this.initLozad();
  }

  renderResults() {
    ReactDOM.render(
      this.getResultsJSX(this.category, this.currentPage),
      this.resultsAreaTarget
    );
    this.renderPagination(this.category, this.currentPage);
    this.tabsConainterTarget.scrollIntoView({
      behavior: "smooth",
    });
    this.observer.observe();
  }

  previousPageClick() {
    if (this.currentPage !== 1) {
      this.currentPage -= 1;
      this.renderResults();
    }
  }

  nextPageClick() {
    if (this.currentPage < this.totalPages) {
      this.currentPage += 1;
      this.renderResults();
    }
  }

  pageNumberClick(e) {
    this.currentPage = parseInt(e.target.innerText, 10);
    this.renderResults();
  }

  renderPagination(category, page) {
    const filteredVae = this.filterServices(category);

    this.totalPages = Math.ceil(filteredVae.length / 5);
    this.paginationTarget.innerHTML = `
      <span
        data-target="previousPage"
        data-action="click->cactus-tabs#previousPageClick"
        class="cactus-pagination__prev"
      >
        ‹
      </span>`;

    for (let i = 1; i <= this.totalPages; i += 1) {
      this.paginationTarget.innerHTML += `
        <span
          data-target="pageNumber"
          class="cactus-pagination__page ${
            page === i ? "cactus-pagination__page--current" : ""
          }"
          data-action="click->cactus-tabs#pageNumberClick"1
        >
          ${i}
        </span>`;
    }
    this.paginationTarget.innerHTML += `
      <span
        data-target="nextPage"
        data-action="click->cactus-tabs#nextPageClick"
        class="cactus-pagination__next"
      >
        ›
      </span>`;
  }

  tabClick(e) {
    this.category = e.target.dataset.category;

    ReactDOM.render(
      this.getResultsJSX(this.category, 1),
      this.resultsAreaTarget
    );
    ReactDOM.render(this.getTabsJSX(this.category), this.tabsConainterTarget);
    this.renderPagination(this.category, 1);
  }

  getResultsJSX(category, page) {
    const filteredVae = this.filterServices(category);
    const resultsHTML = [];

    const firstIndex = (page - 1) * 5;
    const lastIndex = firstIndex + 4;

    filteredVae.forEach((vaeJSON, index) => {
      if (index >= firstIndex && index <= lastIndex) {
        resultsHTML.push(this.serviceListItem(vaeJSON));
      }
    });

    return resultsHTML;
  }

  filterServices(category) {
    if (category === "all") {
      return this.digitalServices;
    }
    return this.digitalServices.filter((ds) => ds.category === category);
  }

  getTabsJSX(category) {
    return (
      <div>
        <div
          className={`lp-tabs__tab ${
            category === "all" ? "lp-tabs__tab--active" : ""
          }`}
          data-turbolinks="false"
          data-action="click->cactus-tabs#tabClick"
          data-category="all"
        >
          {I18n.t("digital_services.index.categories.all")}
        </div>
        <div
          className={`lp-tabs__tab ${
            category === "music" ? "lp-tabs__tab--active" : ""
          }`}
          data-turbolinks="false"
          data-action="click->cactus-tabs#tabClick"
          data-category="music"
        >
          {I18n.t("digital_services.index.categories.music")}
        </div>
        <div
          className={`lp-tabs__tab ${
            category === "visual" ? "lp-tabs__tab--active" : ""
          }`}
          data-turbolinks="false"
          data-action="click->cactus-tabs#tabClick"
          data-category="visual"
        >
          {I18n.t("digital_services.index.categories.visual")}
        </div>
        <div
          className={`lp-tabs__tab ${
            category === "performance" ? "lp-tabs__tab--active" : ""
          }`}
          data-turbolinks="false"
          data-action="click->cactus-tabs#tabClick"
          data-category="performance"
        >
          {I18n.t("digital_services.index.categories.performance")}
        </div>
      </div>
    );
  }

  serviceListItem(service) {
    const servicePrice = formatCurrency(
      this.locale,
      service.currency,
      service.price_cents / 100
    );
    return (
      <div
        className="cell cell--12 digital-services-item"
        data-controller="digital-service-list-item"
        data-digital-service-list-item-expanded="false"
        key={`vae-${service.id}`}
      >
        <div className="digital-services-item__content">
          <button
            className="lozad digital-services-item__button"
            data-background-image={`https://img.youtube.com/vi/${service.youtube_video_uid}/hqdefault.jpg`}
            data-action="digital-service-list-item#toggle"
            type="button"
          >
            <span className="digital-services-item__button-icon">▶</span>
          </button>
          <div className="digital-services-item__preview-video-mobile">
            <VideoSlide
              youtube_video_uid={service.youtube_video_uid}
              name={this.setTitleLocale(service)}
              showTag
              tagText={I18n.t(
                `digital_services.index.categories.${service.category}`
              )}
            />
          </div>
          <div className="digital-services-item__list">
            <div className="digital-services-item__preview-content">
              <div className="digital-services-item__category">
                {I18n.t(
                  `digital_services.index.categories.${service.category}`
                )}
              </div>
              <h2 className="digital-services-item__title">
                {this.setTitleLocale(service)}
              </h2>
              <div className="digital-services-item__description-mobile">
                {this.setDescriptionLocale(service)}
              </div>
            </div>
            <div className="digital-services-item__service-details">
              <div className="digital-services-item__feature">
                <img
                  src={this.clockIconUrl}
                  className="digital-services-item__service-details-icon"
                  alt="clock-icon"
                />
                {service.duration}min
              </div>
              <div className="digital-services-item__feature">
                <img
                  src={this.talkIconUrl}
                  alt="talk-icon"
                  className="digital-services-item__service-details-icon"
                />
                {service.languages.slice(1).join(", ").toUpperCase()}
              </div>
              <div className="digital-services-item__feature">
                <img
                  src={this.walletIconUrl}
                  className="digital-services-item__service-details-icon"
                  alt="wallet-icon"
                />
                {service.price_subject_to_change && "*"}
                {servicePrice}
              </div>
            </div>
            <div className="digital-services-item__cta-button-wrapper">
              <button
                type="button"
                className="b-button b-button--primary inquiry-form-button digital-services-item__cta-button"
                data-controller="smart-form-trigger"
                data-action="smart-form-trigger#onClick"
                data-flow-name="digital_service"
                data-title={`${I18n.t(
                  "digital_services.index.business_pages.cta"
                )}`}
                data-subtitle={`${I18n.t(
                  "business_pages.send_us_your_briefing"
                )}`}
                data-additional-description={this.setTitleLocale(service)}
              >
                {I18n.t("digital_services.index.service.check_availability")}
              </button>
            </div>
            <button
              className="digital-services-item__details"
              data-action="digital-service-list-item#toggle"
              type="button"
            >
              {I18n.t("digital_services.index.services.details")}
              <img
                src={this.arrowIconUrl}
                className="digital-services-item__arrow-expand"
                alt="arrow-collpase"
              />
            </button>
          </div>
        </div>
        <div className="digital-services-item__extra-content">
          <div
            data-target="digital-service-list-item.extraContent"
            style={{ display: "none" }}
          >
            <div className="digital-services-item__extra-content--expanded">
              <div className="digital-services-item__video-container">
                <VideoSlide
                  youtube_video_uid={service.youtube_video_uid}
                  name={this.setTitleLocale(service)}
                  tagText={I18n.t(
                    `digital_services.index.categories.${service.category}`
                  )}
                />
              </div>

              <div className="digital-services-reviews__review">
                <div className="digital-services-reviews__content">
                  <div className="digital-services-reviews__description-title">
                    {I18n.t("digital_services.index.description")}
                  </div>
                  <div className="digital-services-reviews__description-text">
                    {this.setDescriptionLocale(service)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  setTitleLocale(service) {
    if (I18n.locale === "pt") {
      return service.name_pt;
    }
    if (I18n.locale === "de") {
      return service.name_de;
    }
    return service.name_en;
  }

  setDescriptionLocale(service) {
    if (I18n.locale === "pt") {
      return service.description_pt;
    }
    if (I18n.locale === "de") {
      return service.description_de;
    }
    return service.description_en;
  }
}
