const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');

class Button extends React.PureComponent {
  render() {
    const {
      domRef,
      disabled,
      inverse,
      primary,
      small,
      submit,
      children,
      onClick,
    } = this.props;

    return (
      <button
        ref={domRef}
        disabled={disabled}
        onClick={onClick}
        className={classNames(
          'b-button',
          disabled && 'b-button--disabled',
          inverse && 'b-button--inverse',
          !primary && 'b-button--grey',
          small && 'b-button--small',
          submit && 'b-button--submit',
        )}
        style={{
          width: !submit && '100%',
          margin: 0,
        }}
      >
        {children}
      </button>
    );
  }
}

Button.propTypes = {
  domRef: PropTypes.bool,
  disabled: PropTypes.bool,
  inverse: PropTypes.bool,
  primary: PropTypes.bool,
  small: PropTypes.bool,
  submit: PropTypes.bool,
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  domRef: undefined,
  disabled: false,
  inverse: false,
  primary: false,
  small: false,
  submit: false,
  onClick: () => {},
};

module.exports = Button;
