const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');

const currentClass = (value, selectedValues) => {
  return classNames('b-button-options__option', `qa-button-options-value-${value}`, {
    'b-button-options__option--selected': selectedValues.includes(value),
  });
};

const ButtonOptions = ({
  selectedValues,
  options,
  multi,
  onChange,
}) => {
  const onClick = (value) => {
    if (multi) {
      if (selectedValues.includes(value)) {
        onChange(selectedValues.filter(i => i !== value));
      } else {
        onChange(selectedValues.concat([value]));
      }
    } else {
      onChange([value]);
    }
  };

  return (
    <div className="b-button-options">
      {options.map(({ value, name }) => (
        <button
          key={value}
          className={currentClass(value, selectedValues)}
          onClick={(e) => { e.preventDefault(); onClick(value); }}
        >
          {name}
        </button>
      ))}
    </div>
  );
};

ButtonOptions.propTypes = {
  options: PropTypes.array.isRequired,
  selectedValues: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  multi: PropTypes.bool.isRequired,
};

ButtonOptions.defaultProps = {
  selectedValues: [],
};

module.exports = ButtonOptions;
