const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');
const CheckIcon = require('./Icons/CheckIcon').default;
const ErrorIcon = require('./Icons/ErrorIcon').default;

const Input = ({
  label,
  name,
  value,
  placeholder,
  onChange,
  error,
  validated,
  type,
}) => {
  const hasError = !!error;
  const hasValue = !!value;

  return (
    <div className={classNames('b-input', { 'b-input--error': hasError, 'b-input--empty': !hasValue })}>
      <div className="b-input__top">
        <div className="b-input__label">{label}</div>
        {hasError && <div className="b-input__error">{error}</div>}
      </div>
      <div className="b-input__input-wrapper">
        <input
          className="b-input__input"
          type={type}
          name={name}
          value={value || ''}
          placeholder={placeholder}
          onChange={e => onChange(e.target.value)}
        />
        {hasError && <div className="b-input__icon"><ErrorIcon /></div>}
        {!hasError && validated && <div className="b-input__icon"><CheckIcon /></div>}
      </div>
    </div>
  );
};

Input.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  validated: PropTypes.bool,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
};

Input.defaultProps = {
  label: undefined,
  value: undefined,
  error: undefined,
  placeholder: undefined,
  validated: false,
  type: 'text',
};

module.exports = Input;
