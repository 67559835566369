const React = require('react');
const PropTypes = require('prop-types');
const Modal = require('../../../ui/Modal');

const ArtistOfferSentModal = ({
  isOpen,
  onClose,
}) => (
  <Modal
    isOpen={isOpen}
    onClose={onClose}
  >
    <div className="b-react-modal__title b-react-modal__title--green">
      {I18n.t('negotiation_dashboard.artist.whoop')}
    </div>
    <div className="b-react-modal__body b-react-modal__text-block">
      {I18n.t('negotiation_dashboard.artist.your_final_price')}
    </div>
    <div className="b-react-modal__footer">
      <button onClick={onClose} className="b-button">
        {I18n.t('negotiation_dashboard.artist.close')}
      </button>
    </div>
  </Modal>
);

ArtistOfferSentModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

module.exports = ArtistOfferSentModal;
