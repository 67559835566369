const React = require('react');
const { formatDate } = require('../../../lib/datetime_formatters');
const { invoicesPath } = require('../../../resources/InvoiceResource');

class InvoicesTable extends React.Component {
  renderHeader() {
    return (
      <tr>
        <th>Prefix</th>
        <th>Sequence</th>
        <th>Type</th>
        <th>Artist</th>
        <th>Sent</th>
        <th>Cancelled</th>
        <th>Created</th>
        <th className="b-admin__actions">Actions</th>
      </tr>
    );
  }

  renderPdfActions(invoice_id, sent) {
    return (
      <span>
        <a href={`${invoicesPath('show', { id: invoice_id })}/pdf_show_or_create`} target="_blank">PDF</a>
        {!sent && <a href={`${invoicesPath('show', { id: invoice_id })}/pdf_recreate`} target="_blank">Recreate</a>}
      </span>
    )
  }

  renderRows() {
    const { invoices } = this.props;

    return invoices.map(invoice => this.renderRow(invoice));
  }

  renderRow(invoice) {
    const {
      id,
      type,
      number_sequence,
      number_prefix,
      sent,
      cancelled,
      offer_public_id,
      artist_name,
      created_at,
    } = invoice;

    return (
      <tr key={id}>
        <td>{number_prefix}</td>
        <td>{number_sequence}</td>
        <td>{type}</td>
        <td>{artist_name}</td>
        <td>{String(sent)}</td>
        <td>{String(cancelled)}</td>
        <td>{formatDate(created_at)}</td>
        <td className="b-admin__actions">
          {type === 'OfferInvoice' && this.renderPdfActions(id, sent)}
          <a href={invoicesPath('edit', { id })}>Edit</a>
          {offer_public_id && <a href={`/offers/${offer_public_id}`} target="_blank">Offer</a>}
        </td>
      </tr>
    );
  }

  render() {
    return (
      <table className="table table-striped">
        <thead>{this.renderHeader()}</thead>
        <tbody>{this.renderRows()}</tbody>
      </table>
    );
  }
}

module.exports = InvoicesTable;
