import React from 'react';

const SvgComponent = props => (
  <svg width={44} height={46} {...props}>
    <title>icn_generic_helpfindartist</title>
    <defs>
      <path id="icn_generic_helpfindartist_prefix__a" d="M0 0h44v46H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <mask id="icn_generic_helpfindartist_prefix__b" fill="#fff">
          <use xlinkHref="#icn_generic_helpfindartist_prefix__a" />
        </mask>
        <path
          d="M41.815 42.342l-1.665 1.535a1.3 1.3 0 01-.877.354.992.992 0 01-.734-.299l-7.716-8.572 3.476-3.145 7.69 8.544c.22.237.258.512.25.702a1.284 1.284 0 01-.424.881zM28.567 31.525a17.79 17.79 0 002.088-1.968l1.826 1.922-2.127 1.925-1.787-1.88zM1.76 17.693c0-8.78 7.106-15.924 15.84-15.924s15.84 7.143 15.84 15.924c0 8.78-7.106 15.923-15.84 15.923S1.76 26.472 1.76 17.693zM34.95 30.3a.879.879 0 00-.614-.29.905.905 0 00-.614.214l-1.946-2.05A17.675 17.675 0 0035.2 17.694C35.2 7.92 27.32 0 17.6 0 7.88 0 0 7.921 0 17.693c0 9.77 7.88 17.692 17.6 17.692 3.51 0 6.778-1.037 9.524-2.818l1.921 2.021-.154.14.01.011a.885.885 0 00.026 1.151l8.315 9.238a2.735 2.735 0 002.03.872c.76 0 1.494-.29 2.067-.819l1.665-1.534c.6-.553.963-1.325.994-2.117a2.716 2.716 0 00-.713-1.968l-8.335-9.261z"
          fill="#B9B9B9"
          mask="url(#icn_generic_helpfindartist_prefix__b)"
        />
      </g>
      <path
        d="M18 29.2c-6.212 0-11.267-5.249-11.267-11.7 0-6.452 5.055-11.7 11.267-11.7 6.213 0 11.267 5.248 11.267 11.7 0 6.451-5.054 11.7-11.267 11.7M18 4C10.82 4 5 10.044 5 17.5S10.82 31 18 31s13-6.044 13-13.5S25.18 4 18 4"
        fill="#B9B9B9"
      />
      <path
        d="M16.033 15.769c-.443 0-.697.237-.824.297-.127.118-.571.593-.254 1.186.254.534.761.89 1.459 1.365.444.297 1.015.712 1.523 1.187.57-.475 1.078-.89 1.522-1.187.698-.474 1.206-.83 1.46-1.365a1.042 1.042 0 00-.254-1.186c-.127-.12-.635-.534-1.333-.238l-1.015.416a.86.86 0 01-.761 0l-1.015-.416c-.19-.06-.381-.06-.508-.06M17.937 22c-.254 0-.571-.119-.698-.297-.634-.712-1.332-1.187-1.967-1.661-.825-.594-1.649-1.128-2.03-2.077-.507-1.069-.19-2.315.698-3.146.888-.83 2.284-1.068 3.426-.534l.634.297.634-.297c1.142-.475 2.474-.297 3.426.534.888.831 1.205 2.077.698 3.146-.444.949-1.206 1.543-2.03 2.077a9.563 9.563 0 00-1.967 1.661c-.254.178-.57.297-.824.297"
        fill="#B9B9B9"
      />
    </g>
  </svg>
);

export default SvgComponent;
