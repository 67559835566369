const React = require('react');
const PropTypes = require('prop-types');

const PromptMessage = ({ onClick }) => {
  return (
    <div className="b-artist-pricing__prompt">
      <p className="b-artist-pricing__prompt__message">
        <strong>{I18n.t('negotiation_dashboard.artist.extra_costs_prompt.title')}</strong><br />
        {I18n.t('negotiation_dashboard.artist.extra_costs_prompt.message')}
      </p>
      <button
        className="b-artist-pricing__prompt__button"
        onClick={onClick}
      >
        {I18n.t('negotiation_dashboard.artist.extra_costs_prompt.button')}
      </button>
    </div>
  );
};

PromptMessage.propTypes = {
  onClick: PropTypes.func.isRequired,
};

module.exports = PromptMessage;
