const React = require('react');
const OfferShowField = require('./show_field');

function ClientInfo({
  id,
  email,
  name,
  phone,
  companyName,
  client_path,
}) {
  return (
    <div className="basa-clientInfo">
      <h5><a href={client_path}>{`Client #${id}`}</a></h5>
      <OfferShowField
        fieldName={I18n.t('offer.client_name')}
        fieldValue={name}
      />
      <OfferShowField
        isEnabled={companyName}
        fieldName={I18n.t('offer.company_name')}
        fieldValue={companyName}
      />
      <OfferShowField
        fieldName={I18n.t('offer.client_email')}
        fieldValue={email}
        className="value-light"
      />
      <OfferShowField
        fieldName={I18n.t('offer.client_phone')}
        fieldValue={phone}
        className="value-light"
      />
    </div>
  );
}

module.exports = ClientInfo;
