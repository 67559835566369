import React from 'react';

const SvgComponent = () => (
  <svg width={42} height={37}>
    <title>icn_generic_booking</title>
    <path
      d="M37.333 8.345L41.3 12.36c.467.393.7 1.023.7 1.653v20.625C42 35.977 40.989 37 39.667 37H2.333C1.011 37 0 35.977 0 34.638V13.226c0-.63.233-1.181.7-1.654l3.967-4.015V0h32.666v8.345zm2.567 4.88l-2.567-2.597v4.015l2.567-1.417zM35.778 1.639H6.222v13.24l14.39 8.424a.7.7 0 00.777 0l14.389-7.872V1.638zM4.667 13.934V9.762l-2.645 2.676 2.645 1.496zm35 21.492c.466 0 .777-.315.777-.788V14.721l-18.355 9.92c-.7.393-1.556.393-2.256 0L1.556 13.933v20.704c0 .473.31.788.777.788h37.334zM26.444 7.086h7V8.66h-7V7.085zM8.556 10.233h7v1.575h-7v-1.575zm0 3.149h24.888v1.574H8.556v-1.574zM14 16.532h14v1.574H14v-1.574zM3.889 30.702h6.222v1.575H3.89v-1.575zM17.11 10.234h16.333v1.575H17.111v-1.575zM8.556 7.085h16.333V8.66H8.556V7.085z"
      fill="#B9B9B9"
      fillRule="nonzero"
    />
  </svg>
);

export default SvgComponent;
