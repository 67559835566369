var React = require('react')
var PropTypes = require('prop-types')
var PictureUploader = require('./picture_uploader')

class PictureFourWidget extends React.Component {
  constructor(props){
    super(props)
  }

  render () {
    return (
    <div className={"col-sm-8 col-sm-offset-2 picture-four-widget" + (this.props.errors ? " has-error" : "")}>
      <p>{I18n.t('artistic_services.add_photos_info')}</p>
      <PictureUploader imagesIds={this.props.imagesIds} onUpdate={this.props.onUpdate}/>
      {this.props.errors? <span className="error-block">{this.props.errors}</span>:null}
    </div>);
  }
}

module.exports = PictureFourWidget
