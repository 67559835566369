const React = require('react');
const lozad = require('lozad');
const PropTypes = require('prop-types');
const { ampEvent } = require('lib/amplitude');
const ArtisticServiceResultItem = require('./artistic_service_result_item');
const LocationAutocomplete = require('../LocationAutocomplete').default;
const FiltersMobile = require('./FiltersMobile');
const Filters = require('./Filters');

const labelize = (values_or_value, labels) => {
  if (!values_or_value) return null;

  if (Array.isArray(values_or_value)) {
    return values_or_value.map(value => ({ value, label: labels[value] }));
  }

  return { value: values_or_value, label: labels[values_or_value] };
};

class ArtisticServiceSearch extends React.Component {
  constructor(props) {
    super(props);
    const artisticServices = props.artistic_services || [];
    const resultsCount = props.totalResultsCount || 0;

    this.observer = lozad();

    this.state = {
      artisticServices,
      searchStatus: 'see_more',
      searchTags: props.search_tags,
      location: props.initialLocation || '',
      occasions: props.initialOccasions || [],
      category: props.initialCategory || '',
      masterTag: props.initialMasterTag.value || '',
      resultsCount,
      hasMoreResults: resultsCount - artisticServices.length > 0,
      currentPage: props.current_page || 1,
      showMobileFilters: false,
      currentSearch: props.initial_search || '',
    };

    this.search = this.search.bind(this);
    this.changeUrlQuery = this.changeUrlQuery.bind(this);
  }

  onOccasionChange = (occasions, submit) => {
    this.setState({ occasions });

    if (submit) this.handleSubmitForm();
  }

  onCategoryChange = (category, submit) => {
    this.setState({ masterTag: 'all', category });

    if (submit) this.handleSubmitForm();
  }

  onMasterTagChange = (masterTag, submit) => {
    this.setState({ masterTag });

    if (submit) this.handleSubmitForm();
  }

  onClearFilters = (event, submit) => {
    event.preventDefault();

    this.setState({
      occasions: [],
      category: null,
      masterTag: null,
    });

    if (submit) this.handleSubmitForm();
  }

  loadMoreResults = () => {
    this.setState(prevState => ({
      searchStatus: 'loading',
      artisticServices: this.state.artisticServices,
      currentPage: prevState.currentPage + 1,
    }), () => {
      this.search(this.state.currentSearch);
    });
  }

  handleSubmitForm = (event) => {
    if (event) {
      event.preventDefault();
    }

    this.setState({
      searchStatus: 'searching',
      artisticServices: [],
      hasMoreResults: false,
      currentPage: 1,
    }, () => {
      window.scrollTo(0, 0);
      $('.b-search-page input').blur();
      this.search(this.state.currentSearch);
      this.changeUrlQuery(this.state.currentSearch);

      // Send data to Analytics
      ampEvent('Search Page Submited', this.state);
      hj('tagRecording', ['search_page_submit']);
    });
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.handleSubmitForm(event);
    }
  }

  toggleFilters = (e) => {
    e.preventDefault();

    const { showMobileFilters } = this.state;

    this.setState({ showMobileFilters: !showMobileFilters });
  }

  occasionLabels = () => {
    const { occasions } = this.props;

    return occasions.reduce((memo, occasion) => {
      // eslint-disable-next-line no-param-reassign
      memo[occasion] = I18n.t(`search.occasions.${occasion}`);

      return memo;
    }, {});
  }

  categoryLabels = () => {
    const { categories } = this.props;

    return categories.reduce((memo, category) => {
      // eslint-disable-next-line no-param-reassign
      memo[category] = I18n.t(`search.categories.${category}`);

      return memo;
    }, {});
  }

  masterTagLabels = () => {
    const masterTagLabels = {
      music: {},
      performances: {},
      graffiti: {},
    };

    this.props.masterTags.forEach((elem) => {
      const sub = elem[0];
      const type = elem[1];
      masterTagLabels[type][sub] = I18n.t(`search.master_tags.${sub}`);
    });

    return masterTagLabels;
  }

  handleCloudClick(tagName) {
    this.setState({
      searchStatus: 'searching',
      artisticServices: [],
      hasMoreResults: false,
    }, () => {
      let {
        currentSearch,
      } = this.state;

      if (!currentSearch) {
        currentSearch = '';
      }

      if (currentSearch !== '' && currentSearch.slice(-1) !== ' ') {
        currentSearch += ' ';
      }

      currentSearch += tagName.toLowerCase();

      this.setState({
        currentSearch,
        currentPage: 1,
      }, () => {
        this.search(currentSearch);
        this.changeUrlQuery(currentSearch);
      });
    });
  }

  search(text) {
    const {
      currentPage,
      location,
      occasions,
      category,
      masterTag,
      artisticServices,
    } = this.state;

    $.get({
      url: '/search',
      data: {
        q: text,
        page: currentPage,
        locale: I18n.currentLocale(),
        master_tag: masterTag,
        location,
        occasions,
        category,
      },
    })
      .done((data) => {
        const searchTags = data.search_tags;

        const appendedServices = artisticServices.concat(data.artistic_services);
        const hasMoreResults = (data.results_count || 0) - appendedServices.length > 0;

        this.setState({
          artisticServices: appendedServices,
          searchStatus: 'load_more_or_blank_state',
          resultsCount: data.results_count,
          hasMoreResults,
          searchTags,
        }, () => {
          this.observer.observe();
        });
      });
  }

  changeUrlQuery(text) {
    const baseUrl = window.location.origin + window.location.pathname;

    const {
      location,
      occasions,
      category,
      masterTag,
    } = this.state;

    const queryParams = {
      q: text,
      master_tag: masterTag,
      location,
      occasions,
      category,
    };

    const queryParamsString = Object.keys(queryParams).map((key) => {
      const value = queryParams[key];

      if (value && value.length) {
        return `${key}=${encodeURIComponent(value)}`;
      }

      return null;
    }).filter(value => value !== null).join('&');

    const url = `${baseUrl}?${queryParamsString}`;

    window.history.pushState({
      search: text,
      location,
      category,
      masterTag,
      occasions,
      turbolinks: true,
      url,
    }, 'BASA', url);
  }

  renderFormTop() {
    return (
      <div className="b-search-page__top">
        <div className="b-search-page__top__group b-search-page__top__group--search">
          <input
            type="text"
            id="search-input"
            className="b-search-page__input b-search-page__input--with-magnet"
            placeholder={I18n.t('search.search_bar.placeholder')}
            value={this.state.currentSearch}
            onChange={event => this.setState({ currentSearch: event.target.value || '' })}
            onKeyPress={this.handleKeyPress}
          />
        </div>
        <div className="b-search-page__top__group b-search-page__top__group--location">
          <LocationAutocomplete
            google_maps_api_key={this.props.google_maps_api_key}
            placeholder="Location"
            fieldName="search[location]"
            onChange={location => this.setState({ location })}
            value={this.state.location}
            searchOptionTypes={['(regions)']}
            inputClassName="b-search-page__input"
            onSelect={(location) => {
              this.setState({ location }, () => {
                this.handleSubmitForm();
              });
            }}
          />
          <button
            onClick={this.toggleFilters}
            className="b-search-page__top__filters-toggle"
          >
            <span className="glyphicon glyphicon-filter" />
          </button>
        </div>
        <button
          type="submit"
          className="b-button b-button--primary b-button--wide"
        >
          {I18n.t('search.search_bar.button')}
        </button>
      </div>
    );
  }

  renderFormFilters() {
    const { showMobileFilters } = this.state;
    const occasionLabels = this.occasionLabels();
    const categoryLabels = this.categoryLabels();
    const masterTagLabels = this.masterTagLabels();

    const occasions = labelize(this.props.occasions, occasionLabels);
    const categories = labelize(this.props.categories, categoryLabels);

    const currentOccasions = labelize(this.state.occasions, occasionLabels);
    const currentCategory = labelize(this.state.category, categoryLabels);

    // <ew>
    const masterTags = this.props.masterTags.map((elem) => {
      const sub = elem[0];
      const type = elem[1];
      return { value: sub, label: masterTagLabels[type][sub], type };
    });

    const filteredMasterTags = masterTags.filter(o => o.type === this.state.category);

    const currentMasterTag = this.state.masterTag && this.state.category && this.state.category !== 'all' && {
      value: this.state.masterTag,
      label: masterTagLabels[this.state.category][this.state.masterTag],
      type: this.state.category,
    };
    // </ew>

    return (
      <React.Fragment>
        {showMobileFilters &&
          <FiltersMobile
            occasions={occasions}
            categories={categories}
            filteredMasterTags={filteredMasterTags}
            onOccasionChange={selectedOccasions => this.onOccasionChange(selectedOccasions, false)}
            onCategoryChange={selectedCategory => this.onCategoryChange(selectedCategory, false)}
            onMasterTagChange={selectedMasterTag => this.onMasterTagChange(selectedMasterTag, false)}
            currentOccasions={currentOccasions}
            currentCategory={currentCategory}
            currentMasterTag={currentMasterTag}
            toggleFilters={this.toggleFilters}
            onApply={(event) => {
              this.toggleFilters(event);
              this.handleSubmitForm(event);
            }}
            onClear={event => this.onClearFilters(event, false)}
          />
        }
        <Filters
          occasions={occasions}
          categories={categories}
          filteredMasterTags={filteredMasterTags}
          onOccasionChange={selectedOccasions => this.onOccasionChange(selectedOccasions, true)}
          onCategoryChange={selectedCategory => this.onCategoryChange(selectedCategory, true)}
          onMasterTagChange={selectedMasterTag => this.onMasterTagChange(selectedMasterTag, true)}
          currentOccasions={currentOccasions}
          currentCategory={currentCategory}
          currentMasterTag={currentMasterTag}
          onClear={event => this.onClearFilters(event, true)}
        />
      </React.Fragment>
    );
  }

  render() {
    const results = this.state.artisticServices.map((artistic_service) => {
      return (
        <div key={artistic_service.id} className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
          <ArtisticServiceResultItem artistic_service={artistic_service} />
        </div>
      );
    });

    const searchTags = this.state.searchTags.map((searchTag) => {
      return (
        <span
          className="search-tag"
          key={searchTag}
          onClick={() => { this.handleCloudClick(searchTag); }}
          onKeyDown={(e) => { if (e.key === 'Enter') { this.handleCloudClick(searchTag); } }}
          role="button"
          tabIndex={0}
        >
          {searchTag}
        </span>
      );
    });

    let searchLabelHtml;
    if (this.state.searchStatus === 'loading') {
      searchLabelHtml = <p>{I18n.t('search.loading')}</p>;
    } else if (this.state.searchStatus === 'searching') {
      searchLabelHtml = <p>{I18n.t('search.searching')}</p>;
    } else if (this.state.hasMoreResults) {
      searchLabelHtml = (
        <button
          className="green-round-button"
          onClick={() => { this.loadMoreResults(); }}
        >
          {I18n.t('search.load_more')}
        </button>
      );
    } else if (this.state.artisticServices.length > 20) {
      searchLabelHtml = (
        <div>
          <p className="search-result-title">
            {I18n.t('search.no_more_results_4')}
          </p>
          <p>
            {I18n.t('search.no_more_results_5')}
          </p>
          <button className="green-round-button inquiry-form-button">
            {I18n.t('shared.get_a_free_quote.contact_us')}
          </button>
        </div>
      );
    } else if (this.state.artisticServices.length === 0) {
      searchLabelHtml = (
        <div>
          <p className="search-result-title">
            {I18n.t('search.no_more_results_1')}
          </p>
          <p>
            {I18n.t('search.no_more_results_2')}
          </p>
          <button className="green-round-button inquiry-form-button">
            {I18n.t('shared.get_a_free_quote.contact_us')}
          </button>
        </div>
      );
    }

    return (
      <div>
        <div id="search-page" className="b-search-page">
          <section className="main">
            <form className="b-search-page__form" onSubmit={this.handleSubmitForm}>
              {this.renderFormTop()}
              {this.renderFormFilters()}
            </form>

            <div className="row b-search-page__tags">
              {searchTags}
            </div>

            <div className="row b-search-page__results-count">
              <h5>{I18n.t('search.results', { count: this.state.resultsCount })}</h5>
            </div>

            <div className="row b-services-grid">
              {results}
            </div>

            <div className="search-load text-center">
              {searchLabelHtml}
            </div>
          </section>
        </div>
      </div>
    );
  }
}

ArtisticServiceSearch.defaultProps = {
  artistic_services: [],
  initial_search: '',
  search_tags: [],
  initialLocation: '',
  initialOccasions: [],
  initialCategory: '',
  initialMasterTag: {
    value: '',
    type: '',
  },
  current_page: null,
  occasions: [],
  categories: [],
  masterTags: [],
};

ArtisticServiceSearch.propTypes = {
  artistic_services: PropTypes.array,
  initial_search: PropTypes.string,
  search_tags: PropTypes.array,
  initialLocation: PropTypes.string,
  initialOccasions: PropTypes.array,
  initialCategory: PropTypes.string,
  initialMasterTag: PropTypes.object,
  totalResultsCount: PropTypes.number.isRequired,
  current_page: PropTypes.number,
  occasions: PropTypes.array,
  categories: PropTypes.array,
  masterTags: PropTypes.array,
  google_maps_api_key: PropTypes.string.isRequired,
};

module.exports = ArtisticServiceSearch;
