const React = require("react");
const PropTypes = require("prop-types");
const OfferShowField = require("./show_field");
const { formatCurrency } = require("../../lib/currency");

function BookingAmountsFull({
  vat_percent_basa,
  total_vat_amount,
  total_gross_booking_value_with_extra_costs,
  currency,
}) {
  const totalGrossBookingValueWithExtraCosts = formatCurrency(
    I18n.locale,
    currency,
    total_gross_booking_value_with_extra_costs
  );
  const totalVatAmount = formatCurrency(
    I18n.locale,
    currency,
    total_vat_amount
  );
  return (
    <div className="basa-offersShow-totals">
      <OfferShowField
        className="value-dark name-dark"
        fieldName={I18n.t("offer.amount_due")}
        fieldValue={totalGrossBookingValueWithExtraCosts}
      />
      <OfferShowField
        className="value-light"
        fieldName={I18n.t("offer.vat", { vat: vat_percent_basa })}
        fieldValue={totalVatAmount}
      />
    </div>
  );
}

BookingAmountsFull.propTypes = {
  vat_percent_basa: PropTypes.number.isRequired,
  total_vat_amount: PropTypes.string.isRequired,
  total_gross_booking_value_with_extra_costs: PropTypes.string.isRequired,
};

module.exports = BookingAmountsFull;
