/* eslint-disable jsx-a11y/anchor-is-valid */
const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');

const Tab = (props) => {
  const {
    name,
    currentTab,
    text,
    onClick,
  } = props;

  const onClickTab = (event) => {
    event.preventDefault();
    onClick(name);
  };

  return (
    <li
      role="presentation"
      className={classNames({ active: currentTab === name })}
    >
      <a href="#" onClick={onClickTab}>{text}</a>
    </li>
  );
};

Tab.propTypes = {
  name: PropTypes.string.isRequired,
  currentTab: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

module.exports = Tab;
