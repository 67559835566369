const dateFormat = require('date-fns/format').default;
const utcToZonedTime = require('date-fns-tz/utcToZonedTime').default;
const differenceInDays = require('date-fns/differenceInDays').default;
const parseISO = require('date-fns/parseISO').default;
const Sentry = require('@sentry/browser');
const { dateFnsLocale } = require('./date-fns-locale');

const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

function parseDate(value, zone) {
  const userTimezone = zone || browserTimeZone;

  return utcToZonedTime(parseISO(value), userTimezone);
}

export function formatDate(value, format = 'd LLLL yyyy', zone) {
  try {
    const parsedDate = parseDate(value, zone);

    return dateFormat(parsedDate, format, { locale: dateFnsLocale() });
  } catch (e) {
    Sentry.captureException(`Invalid date: ${value}`);
  }
}

export function daysAgo(value) {
  const parsedDate = parseDate(value);
  const today = new Date();

  return differenceInDays(today, parsedDate);
}
